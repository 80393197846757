import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const resetHelper = () => {
  const dispatch = UseAppDispatch();
  const resetInitialValue = {
    password: "",
    confirmPassword:""
  };

  const goBackFunction = () => {
    
  };

  return {
    resetInitialValue,
    dispatch,
    goBackFunction,
  };
};

export default resetHelper;
