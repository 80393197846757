import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ContentDetail } from "../../../common/utils";
export const SelectedContentetail: ContentDetail = {
  detail: [],
};

const ContentDetailSlice = createSlice({
  name: "contentDetail",
  initialState: SelectedContentetail,
  reducers: {
    contentDetail: (state: ContentDetail, action: PayloadAction<any>) => {
      state.detail = action.payload.data;
    },
  },
});

export const { contentDetail } = ContentDetailSlice.actions;

export default ContentDetailSlice.reducer;
