const VERSION = "v1";
const SESSIONS = "sessions";
const ADMINS = "admins";
const ROLES = "roles";
const FAQS = "faq";
const USERS = "users";

const NOTIFICATIONS = "notification";

export const LOGIN = `auth/${VERSION}/accounts/login`;
export const LOGOUT = `auth/${VERSION}/sessions/logout`;

export const FORGOT_PASSWORD = "auth/v1/passwords";
export const CHANGE_PASSWORD = "admins/password";
export const REFRESH = `auth/${VERSION}/${SESSIONS}/refresh`;
export const SUB_ADMIN = `user/${VERSION}/${ADMINS}`;
export const ROLE = `user/${VERSION}/${ROLES}`;
export const NOTIFICATION = `${NOTIFICATIONS}/${VERSION}/notifications`;
export const NOTIFICATION_SEND = `${NOTIFICATIONS}/${VERSION}/notifications/resend`;
export const NOTIFICATION_DELETE = `${NOTIFICATIONS}/${VERSION}/notifications`;

export const FAQ = `content/${VERSION}/${FAQS}`;
export const SEARCH_CONTENT = `content/${VERSION}/cms/search`;

export const ABOUT_US_CONTENT = `content/${VERSION}/cms/about-us`;
export const TERMS_CONDTION_CONTENT = `content/${VERSION}/cms/terms-and-conditions`;
export const PRIVACY_POLICY_CONTENT = `content/${VERSION}/cms/privacy-policy`;
export const USER = `user/${VERSION}/${USERS}`;
export const USER_DETAIL = `user/${VERSION}/${USERS}`;

export const USER_BLOCK = `user/${VERSION}/${USERS}/block`;
export const USER_UNBLOCK = `user/${VERSION}/${USERS}/unblock`;






export const SUB_ADMIN_BLOCK_UNBLOCK = `user/${VERSION}/${ADMINS}/profile/action`;

export const ADMIN = "admins/profile";
export const PROFILE = `user/${VERSION}/admins/profile`;
export const ADMIN_PERMISSION = `auth/${VERSION}/accounts/permissions`;
export const PROFILE_IMAGE = `auth/${VERSION}/storage/upload`;

export const EDIT_PROFILE = "admins/profile";
export const DISABLE_AUTHENTICATOR = "admins";
export const PERMISSIONS_GROUP = "admins/permission-groups";
export const RESET_PASSWORD = "auth/v1/passwords";
export const VERIFY_REST_PASSWORD = "auth/v1/passwords/verify";
export const VERIFY_REST_MFA ="auth/v1/accounts/reset-mfa";
export const VALIDATE_TOKEN = (token: string) =>
  `admins/check-link?token=${token}`;
export const FEEDBACK = "feedback";
// export const NOTIFICATION = "notification";
export const DASHBOARD = "dashboard";
export const AUTHENTICATOR = "admins/2fa";
export const GET_QR_CODE_BEFORE_LOGIN = "admins/2fa-before-login";
export const GET_QR_CODE_AFTER_LOGIN = "admins/2fa-after-login";
export const ACCESS_TOKEN = "admins/access-token";
export const VERIFY_PASSWORD = "admins/verify-password";

// HOST API's //
export const HOST = "admins/host/list";
export const HOST_DELETE = (userId: string) => `admins/host/${userId}/delete`;
export const HOST_VERFIY = (userId: string) => `admins/host/${userId}/verify`;

export const HOST_BLOCK = (userId: string) => `admins/host/${userId}/block`;

export const HOST_UNBLOCK = (userId: string) => `admins/host/${userId}/unblock`;

// HOST API's //

// AMENITY API's //

export const AMENITY_LIST_GET = "admins/amenities/list";
export const AMENITY_ACTIVE_PUT = (id: string) =>
  `admins/amenities/${id}/active`;
export const AMENITY_INACTIVE_PUT = (id: string) =>
  `admins/amenities/${id}/inactive`;
export const AMENITY_DETAIL_GET = (id: string) =>
  `admins/amenities/${id}/details`;
export const AMENITY_ADD_POST = `admins/amenities/add`;
export const AMENITY_EDIT_PUT = `admins/amenities/update`;

// AMENITY API's //

// SUB-ADMIN API'S
export const ADD_NEW_SUB_ADMIN = "admins";
export const SUB_ADMIN_DELETE = (userId: string) => `admins/${userId}/delete`;
export const SUB_ADMIN_BLOCK = (userId: string) => `admins/${userId}`;
export const SUB_ADMIN_UNBLOCK = (userId: string) => `admins/${userId}`;
export const SUB_ADMIN_DETAIL = (userId: string) => `admins/${userId}`;
export const SUB_ADMIN_EDIT = (userId: string) => `admins/${userId}`;
export const SUB_ADMIN_2FA_RESET = (userId: string) => `admins/${userId}`;

// SUB-ADMIN API'S
export const ADD_NEW_ROLE = "admins";
export const ROLE_DELETE = (roleId: string) => `admins/${roleId}/delete`;
export const ROLE_BLOCK = (roleId: string) => `admins/${roleId}`;
export const ROLE_UNBLOCK = (roleId: string) => `admins/${roleId}`;
export const ROLE_DETAIL = (roleId: string) => `admins/${roleId}`;
export const ROLE_EDIT = (roleId: string) => `admins/${roleId}`;
export const ROLE_2FA_RESET = (roleId: string) => `admins/${roleId}`;

// ROLES API'S
export const SEARCH = `user/${VERSION}/permissions/search`;
export const ADD_ROLE = `user/${VERSION}/roles`;
export const ROLE_BLOCK_UNBLOCK = `user/${VERSION}/${ROLES}/action`;

// USER API'S
// export const USER = "admins/users";
export const ADD_NEW_USER = "admins";
export const USER_DELETE = (userId: string) => `admins/users/${userId}/delete`;
// export const USER_BLOCK = (userId: string) => `admins/users/${userId}`;
// export const USER_UNBLOCK = (userId: string) => `admins/users/${userId}`;
// export const USER_DETAIL = (userId: string) => `admins/users/${userId}`;
export const USER_EDIT = (userId: string) => `admins/users/${userId}`;
export const USER_2FA_RESET = (userId: string) => `admins/users/${userId}`;

// CATEGORY API's //

// export const CATEGORY_LIST_GET = "admins/category/list";
// export const INACTIVATE_CATGEORY = (categoryId: StringMap) =>
//   `admins/category/${categoryId}/inactive`;
// export const ACTIVATE_CATGEORY = (categoryId: StringMap) =>
//   `admins/category/${categoryId}/active`;
// export const CATEGORY_DETAIL_GET = (categoryId: StringMap) =>
//   `admins/category/${categoryId}/details`;

// export const CATEGORY_ADD_POST = "admins/category/add";
// export const CATEGORY_UPDATE_POST = "admins/category/update";

// CATEGORY API's //

// CONTENT API's //
export const GET_PAGES = "cms/pages";
export const UPDATE_PAGES = (id: string) => `cms/${id}`;
export const FAQ_LIST = "faq";
export const ADD_FAQ = "faq";
export const UPDATE_FAQ_STATUS = (id: string) => `faq/${id}`;
export const UPDATE_FAQ = (id: string) => `faq/${id}`;
export const DELETE_FAQ = (id: string) => `faq/${id}`;
export const FAQ_QUESTION = (id: string) => `faq/${id}`;

// CONTENT API's //

// NOTIFICATION API's //
export const GENERIC_NOTIFICATION_LIST = "admin/notification";
export const GENERIC_NOTIFICATION_STATUS_CHANGE = (id: string) =>
  `notification/${id}`;
export const DELETE_GENERIC_NOTIFICATION = (id: string) =>
  `admin/notification/${id}`;
export const GET_GENERIC_NOTIFICATION = (id: string) =>
  `admin/notification/${id}`;
export const SEND_NOTIFICATION = `notification/send-notification`;

// NOTIFICATION API's //
