import "./verification-code.scss";
import PasswordField from "../../../components/form-controls/text-password";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Formik, Form } from "formik";
import Stack from "@mui/material/Stack";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { onVerify } from "./services/action";
import verifyHelper from "./helper";
import Schema from "../../../common/constants/schema";
import { LOGIN } from "../../../constants/routes/routes-path";
import { setToken } from "../../../redux/global.slice";
import { clearStorage } from "../../../services/storage/storage-service";
import { IconButton, InputLabel, TextField } from "@mui/material";
import { COLORS } from "../../../common/constants/colors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { VALIDATION_CRITERIA } from "../../../constants/validation-criteria";
import { PATTERN } from "../../../constants/patterns";

export default function VerificationCode() {
  const navigation = useNavigate();
  const { verifyInitialValue, dispatch } = verifyHelper();
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {});

  const handleBack = () => {
    clearStorage();
    dispatch(setToken(""));
    navigation(LOGIN.fullUrl);
  };

  const onSubmit = (values: any) => {
    let verifyPayload: any = {
      otp: values?.otp,
    };
    dispatch(onVerify(verifyPayload, navigation));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="verification-form">
      <Card
        variant="outlined"
        sx={{
          minWidth: "500px",
          maxWidth: "500px",
          textAlign: "start",
          border: "none",
          background: "transparent",
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            color="textSecondary"
            mb={1}
            style={{ textAlign: "start" }}
          >
            Code verification
          </Typography>
          <div className="sub-head">
            Enter the verification code from the Google Authenticator App
          </div>

          <Formik
            validateOnMount={true}
            initialValues={verifyInitialValue}
            onSubmit={onSubmit}
            validationSchema={Schema.VerifySchema}
          >
            {({
              errors,
              touched,
              values,
              isValid,
              handleChange,
              handleBlur,
              dirty,
            }) => (
              <Form>
                <Stack spacing={2}>
                  <PasswordField
                    name="otp"
                    label="Verification code"
                    type="password"
                    showlabel={false}
                    otp={true}
                  ></PasswordField>
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="action-btn"
                  >
                    Verify
                  </button>
                  <button onClick={handleBack} className="link-btn">
                    Back to Login
                  </button>
                </Stack>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
}
