import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_PROFILE,
  GOOGLE_AUTHENTICATOR_DEVICE,
} from "../../constants/routes/routes-path";
import { ACCESS_TOKEN } from "../../constants/url";
import authFetch from "../../interceptors/auth.interceptor";
import { ISubAdminUser } from "../../models/common-models";
import {
  getUserId,
  setAccessToken,
} from "../../services/storage/storage-service";
import "./google-authenticator.scss";
import { SubmitHandler } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";

interface IGoogleAuthenticator {
  googleAuthenticator: string;
}


export default function GoogleAuthenticatorSetup() {
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const googleAuthText = `Google Authenticator`;

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const onSubmit: SubmitHandler<IGoogleAuthenticator> = async (
    data: IGoogleAuthenticator
  ) => {};

  const setupAuthenticatorLater = () => {
    const url = ACCESS_TOKEN;
    const userId = getUserId();
    const deviceId = "test";
    const device_token = "test";
    const body = {
      user_id: userId,
      device_id: deviceId,
      device_token: device_token,
    };
    authFetch
      .post(url, body)
      .then((response) => {
        const result = response.data.result as {
          profile: ISubAdminUser;
          token: string;
        };
        if (result) {
          loginSuccess(result);
        }
      })
      .catch((error) => {});
  };

  function loginSuccess(data: { profile: ISubAdminUser; token: string }) {
    setAccessToken(data.token);
    navigate(ADMIN_PROFILE.fullUrl);
  }

  const navigateTo = () => {
    navigate(GOOGLE_AUTHENTICATOR_DEVICE.path);
  };
  return (
    <>
      <div className="google-authenticator">
        <Card variant="outlined" sx={{ minWidth: "500px", maxWidth: "500px" }}>
          <CardContent>
            <form onSubmit={() => onSubmit}>
              <Typography
                variant="body2"
                color="textSecondary"
                mb={2}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                Mobile App Authentication
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                mb={3}
                style={{ textAlign: "center", fontSize: "1rem" }}
              >
                Secure your account with Two-factor authentication
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                mb={3}
                style={{ fontWeight: "600" }}
              >
                Setup Required
              </Typography>

              <RadioGroup
                aria-labelledby="demo-error-radios"
                name="quiz"
                value={value}
                onChange={handleRadioChange}
                className="google-auth-radio-group"
              >
                <img
                  src="../../../images/google-authenticator.svg"
                  alt=""
                  style={{ width: "40px" }}
                />
                <FormControlLabel
                  value="googleAuth"
                  className="google-auth-radio"
                  control={<Radio />}
                  label={googleAuthText}
                  labelPlacement="start"
                />
              </RadioGroup>
            </form>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                size="medium"
                type="button"
                color="primary"
                disabled={value !== "googleAuth"}
                onClick={navigateTo}
              >
                Setup 2-factor
              </Button>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={setupAuthenticatorLater}
              >
                Later
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
