import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { setLoginDetails } from "./login.slice";
import { showAlert } from "../../../../common/utils";
import { setLoading} from "../../../../redux/global.slice";
import { apiSuccessCode } from "../../../../common/constants/axios.instance";
import { postApiCall } from "../../../../common/constants/api";
import * as URLS from "../../../../constants/url";
import {
  GOOGLE_SCAN,
  VERIFY_CODE,
} from "../../../../constants/routes/routes-path";
import {
  set2FAToken,
  setAccessToken,
  setQRUrl,
} from "../../../../services/storage/storage-service";

export const onLogin =
  (values: any, setSubmitting: any, navigate: any) =>
  (dispatch: Dispatch<any>) => {
    const { email, password } = values;
    const dataToSend = {
      email,
      password,
    };
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    postApiCall(
      `${URLS.LOGIN}`,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;
        
        if (data?.statusCode === apiSuccessCode.success) {
          dispatch(
            setLoginDetails({
              qrCodeURL: data?.result?.qrUrl,
              mfaToken: data?.result?.mfa_token,
            })
          );
          setAccessToken(data?.result?.mfaToken);
          set2FAToken(data?.result?.mfaToken);
          setQRUrl(data?.result?.qrUrl);
          
        }
        dispatch(setLoading(false));
        setSubmitting(false);


        if (data?.result?.qrUrl) {
          navigate(GOOGLE_SCAN.fullUrl);
        } else {
          navigate(VERIFY_CODE.fullUrl);
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error.message);
      }
    );
  };
