import { IRoute } from "../../models/common-models";

export const ACCOUNT: IRoute = {
  path: "auth",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const LOGIN: IRoute = {
  path: "login",
  get fullUrl(): string {
    return `/`;
  },
};

export const ADMIN: IRoute = {
  path: "admin",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};



// ONBOARDING ROUTES

export const LINK_EXPIRED: IRoute = {
  path: "link-expired",
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};



export const VERIFY_CODE: IRoute = {
  path: "verify-code",
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const FORGOT_PASSWORD: IRoute = {
  path: "forgot-password",
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const RESET_PASSWORD: IRoute = {
  path: "reset-password",
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const UPDATE_PASSWORD: IRoute = {
  path: "update-password",
  get fullUrl(): string {
    return `${ACCOUNT.fullUrl}/${this.path}`;
  },
};

export const GOOGLE_AUTHENTICATOR: IRoute = {
  path: "google-authenticator",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const GOOGLE_SCAN: IRoute = {
  path: "google-scan",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const GOOGLE_VERIFY_CODE: IRoute = {
  path: "auth-verify-code",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};
export const GOOGLE_AUTHENTICATOR_SETUP: IRoute = {
  path: "setup",
  get fullUrl(): string {
    return `${GOOGLE_AUTHENTICATOR}/${this.path}`;
  },
};

export const GOOGLE_AUTHENTICATOR_DEVICE: IRoute = {
  path: "device",
  get fullUrl(): string {
    return `${GOOGLE_AUTHENTICATOR}/${this.path}`;
  },
};

export const GOOGLE_AUTHENTICATOR_SCAN: IRoute = {
  path: "google-scan",
  get fullUrl(): string {
    return `${GOOGLE_AUTHENTICATOR_SCAN}/${this.path}`;
  },
};

export const GOOGLE_AUTHENTICATOR_CODE: IRoute = {
  path: "code",
  get fullUrl(): string {
    return `${GOOGLE_AUTHENTICATOR}/${this.path}`;
  },
};

export const ADD_SUB_ADMIN: IRoute = {
  path: "add-user",
  get fullUrl(): string {
    return `${SUB_ADMIN.fullUrl}/${this.path}`;
  },
};

export const EDIT_SUB_ADMIN: IRoute = {
  path: `edit-user/:id`,
  get fullUrl(): string {
    return `${SUB_ADMIN.fullUrl}/${this.path}`;
  },
};
export const VIEW_SUB_ADMIN: IRoute = {
  path: `view/:id`,
  get fullUrl(): string {
    return `${SUB_ADMIN.fullUrl}/${this.path}`;
  },
};

export const SUB_ADMIN: IRoute = {
  path: "sub-admin",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const SUB_ADMIN_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${SUB_ADMIN.fullUrl}/${this.path}`;
  },
};

export const SUB_ADMIN_DETAIL: IRoute = {
  path: "detail",
  get fullUrl(): string {
    return `${SUB_ADMIN.fullUrl}/${this.path}`;
  },
};

// USER ROUTES

export const USER: IRoute = {
  path: "users",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const USER_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${USER.fullUrl}/${this.path}`;
  },
};

export const USER_DETAIL: IRoute = {
  path: "detail/:id",
  get fullUrl(): string {
    return `${USER.fullUrl}/${this.path}`;
  },
};

export const ADD_USER: IRoute = {
  path: "add-user",
  get fullUrl(): string {
    return `${USER.fullUrl}/${this.path}`;
  },
};
export const EDIT_USER: IRoute = {
  path: "edit-user",
  get fullUrl(): string {
    return `${USER.fullUrl}/${this.path}`;
  },
};

//static

export const STATIC_CONTENT: IRoute = {
  path: "static-content",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

export const ADD_FAQ: IRoute = {
  path: "add-faq",
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const EDIT_FAQ: IRoute = {
  path: `edit/:id`,
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

export const VIEW_FAQ: IRoute = {
  path: `view/:id`,
  get fullUrl(): string {
    return `${STATIC_CONTENT.fullUrl}/${this.path}`;
  },
};

// ROLES ROUTES

//list
export const ROLES: IRoute = {
  path: "roles",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};

//add
export const ADD_ROLES: IRoute = {
  path: "add-roles",
  get fullUrl(): string {
    return `${ROLES.fullUrl}/${this.path}`;
  },
};

export const EDIT_ROLES: IRoute = {
  path: `edit-roles/:id`,
  get fullUrl(): string {
    return `${ROLES.fullUrl}/${this.path}`;
  },
};

export const VIEW_ROLES: IRoute = {
  path: `view/:id`,
  get fullUrl(): string {
    return `${ROLES.fullUrl}/${this.path}`;
  },
};
// NOTIFICATION ROUTES

export const NOTIFICATION: IRoute = {
  path: "notification",
  get fullUrl(): string {
    return `/${this.path}`;
  },
};


export const ADD_NOTIFICATION: IRoute = {
  path: "add",
  get fullUrl(): string {
    return `${NOTIFICATION.fullUrl}/${this.path}`;
  },
};

export const EDIT_NOTIFICATION: IRoute = {
  path: "edit",
  get fullUrl(): string {
    return `${NOTIFICATION.fullUrl}/${this.path}`;
  },
};

export const VIEW_NOTIFICATION: IRoute = {
  path: `view/:id`,
  get fullUrl(): string {
    return `${NOTIFICATION.fullUrl}/${this.path}`;
  },
};





// FEEDBACK ROUTES

export const FEEDBACK: IRoute = {
  path: "feedback",
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  },
};

export const FEEDBACK_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${FEEDBACK.fullUrl}/${this.path}`;
  },
};

export const FEEDBACK_DETAIL: IRoute = {
  path: "detail",
  get fullUrl(): string {
    return `${FEEDBACK.fullUrl}/${this.path}`;
  },
};

// ADMIN SETTING ROUTES

export const SETTINGS: IRoute = {
  path: "settings",
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  },
};

export const ADMIN_PROFILE: IRoute = {
  path: "profile",
  get fullUrl(): string {
    return `${SETTINGS.fullUrl}/${this.path}`;
  },
};

export const CHANGE_PASSWORD: IRoute = {
  path: "change-password",
  get fullUrl(): string {
    return `${SETTINGS.fullUrl}/${this.path}`;
  },
};

export const EDIT_PROFILE: IRoute = {
  path: "edit-profile",
  get fullUrl(): string {
    return `${SETTINGS.fullUrl}/${this.path}`;
  },
};


// export const EDIT_ROLES: IRoute = {
//   path: `edit-roles/:roleId`,
//   get fullUrl(): string {
//     return `${ROLES.fullUrl}/${this.path}`;
//   },
// };




export const DASHBOARD: IRoute = {
  path: "dashboard",
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  },
};

// UNAUTHORIZED ACCESS ROUTES START

export const ACCESS_DENIED: IRoute = {
  path: "access-denied",
  get fullUrl(): string {
    return `${this.path}`;
  },
};

// UNAUTHORIZED ACCESS ROUTES END

// HOST ROUTES START //

export const HOST: IRoute = {
  path: "host",
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  },
};
export const HOST_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${HOST.fullUrl}/${this.path}`;
  },
};

// HOST ROUTES  END //

// AMENITY ROUTES //

export const AMENITY: IRoute = {
  path: "amenity",
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  },
};

export const AMENITY_LIST: IRoute = {
  path: "amenity-list",
  get fullUrl(): string {
    return `${AMENITY.fullUrl}/${this.path}`;
  },
};

export const AMENITY_ADD: IRoute = {
  path: "amenity-add",
  get fullUrl(): string {
    return `${AMENITY.fullUrl}/${this.path}`;
  },
};
export const AMENITY_EDIT = {
  path: "amenity-edit",
  fullUrl(id: string) {
    return `${AMENITY.fullUrl}/${id}/${this.path}`;
  },
};

export const AMENITY_DETAIL: IRoute = {
  path: "amenity-detail",
  get fullUrl(): string {
    return `${AMENITY.fullUrl}/${this.path}`;
  },
};

// AMENITY ROUTES //

// CATEGORY ROUTES //

export const CATEGORY: IRoute = {
  path: "category",
  get fullUrl(): string {
    return `${ADMIN.fullUrl}/${this.path}`;
  },
};
export const CATEGORY_LIST: IRoute = {
  path: "list",
  get fullUrl(): string {
    return `${CATEGORY.fullUrl}/${this.path}`;
  },
};

export const CATEGORY_DETAIL = {
  path: "detail",
  fullUrl(categoryId: string) {
    return `${CATEGORY.fullUrl}/${categoryId}/${this.path}`;
  },
};

// CATEGORY ROUTES //
