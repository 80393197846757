import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";

import Header from "../header/header";
import SideBarMenu from "../sidebar/sidebarMenu";

interface Props {
  children: React.ReactNode;
}
export default function PageContainer({ children }: Props) {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <SideBarMenu />
      <Header />
      <Box  sx={{ width:"100%", padding:"20px 20px"}}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
