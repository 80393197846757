import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../../common/utils";
import { setLoading } from "../../../redux/global.slice";
import {
  getApiCall,
  patchApiCall,
  postApiCall,
} from "../../../common/constants/api";
import * as URLS from "../../../constants/url";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { allRolesModule } from "./roleSlice";
import { roleDetail } from "./detailSlice";
import { search } from "./searchPermissionSlice";

export const getRolesModule = (params: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.ROLE}`,
    params,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(allRolesModule(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};
export const addRoles =(params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    postApiCall(
      `${URLS.ROLE}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
};

export const getRoleDetail = (params: any) => (dispatch: Dispatch<any>) => {
  const body = "";
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.ROLE + "/" + params}`,
    body,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(roleDetail(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};

export const updateRole =
  (roleId: any, params: any, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    patchApiCall(
      // `${URLS.SUB_ADMIN}`,
      `${URLS.ROLE + "/" + roleId}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          // dispatch(allAdmins(result.data));
          // dispatch(goBac )
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
export const roleBlockUnblock =
  (params: any, setSubmitting: any, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    patchApiCall(
      `${URLS.ROLE_BLOCK_UNBLOCK}/` + params.id,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode,} = response.data;
        if (statusCode === apiSuccessCode.success) {
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

export const getSearchPermssion = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.SEARCH}`,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(search(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};


