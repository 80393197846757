import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import React, { useCallback, useState } from "react";
import { SubAdmin } from "../../interface/sub-admin-interface";
import { STATIC_COLUMNS } from "../../enums/static-column.enum";
import Status from "../status/status";
import { MoreHorizOutlined as MoreIcon } from "@mui/icons-material";
import Plateform from "../plateform";
import { commonTableStyles } from "./styles";
import ShowImg from "../form-controls/showTableImage";
// import commonTableStyles from "./styles";

interface EnhancedTableProps {
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: keyof SubAdmin
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  tableColumns: any;
  numSelected: any;
  dataSource: any;
  orderBy: any;
}

function EnhancedTableHead(props: any) {
  const headCells = props.tableColumns;

  const { onRequestSort, orderBy, setOrderBy } = props;

  const createSortHandler = useCallback((property: any) => {
    onRequestSort(property, orderBy);
  },[orderBy])

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell key={headCell.dataKey}>
            {headCell?.isSortable ? (
              <TableSortLabel
                // active={orderBy === headCell.key}
                // direction={orderBy === headCell.key ? "asc" : "desc"}
                onClick={() => {
                  setOrderBy((prev:any)=>prev!=="asc"? "asc" : "desc")
                  createSortHandler(headCell.key)
                }}
              >
                <span>{headCell.name}</span>
              </TableSortLabel>
            ) : (
              <span>{headCell.name}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableComponent(props: any) {
  const {
    dataSource,
    tableColumns,
    actionClick,
    total,
    showEdit = true,
    showStatus = true,
    showDelete = false,
    showSend = false,
    showView = false,
    handleView = () => {},
    handleRequestSort = () => {},
  } = props;
  const dimensions = [{ width: "120px" }];
  const staticColumns = STATIC_COLUMNS;
  const tableHeaders = tableColumns;

  const [orderBy, setOrderBy] = useState<string>("asc");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [anchorEl, setAnchorEl] = React.useState<any>(false);

  const [selectedItem, setItem] = useState(null);

  // useEffect(() => {
  //   console.log("dataSource", dataSource);
  // }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    props.onRowsChange(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    props.onPageChange(newPage + 1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectMenu = (menu: string) => {
    if (selectedItem) {
      actionClick(menu, selectedItem);
      handleClose();
    }
  };
  const menuItems = [
    { label: "Edit", action: () => selectMenu("edit"), show: showEdit },
    {
      label:
        selectedItem && selectedItem["isActive"] ? "Deactivate" : "Activate",
      action: () =>
        selectMenu(
          selectedItem && selectedItem["isActive"] ? "deactivate" : "activate"
        ),
      show: showStatus,
    },
    {
      label: selectedItem && selectedItem["isSend"] ? "Resend" : "Send",
      action: () => selectMenu("send"),
      show: showSend,
    },
    { label: "Delete", action: () => selectMenu("delete"), show: showDelete },
    { label: "View", action: () => selectMenu("view"), show: showView },
  ];
  const MenuContain = () => {
    return (
      <Menu
        key={anchorEl}
        open={!!anchorEl ?? ""}
        anchorEl={anchorEl}
        PaperProps={{ sx: dimensions }}
        onClose={handleClose}
        style={{ margin: "0px" }}
      >
        {menuItems
          .filter((item) => item.show) // Filter out items based on the 'show' property
          .map((item, index) => (
            <MenuItem
              key={index}
              style={{ cursor: "pointer" }}
              onClick={item.action}
            >
              {item.label}
              {index < menuItems.length - 1 && <Divider sx={{ my: 0.5 }} />}
            </MenuItem>
          ))}
      </Menu>
    );
  };
  return (
    <div>
      {menuItems.filter((item) => item.show).length > 0 ? <MenuContain /> : ""}
      <TableContainer style={{ padding: "30px 0px", border: "none" }}>
        <Table>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            tableColumns={tableColumns}
            dataSource={dataSource}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
          <TableBody>
            {dataSource.map((row: any) => {
              return (
                <TableRow key={row._id} hover onClick={() => {}}>
                  {tableHeaders.map((column: any) => {
                    if (staticColumns.STATUS === column.key) {
                      return (
                        <TableCell key={row[column.key]}>
                          <Status status={row[column.key]} />
                        </TableCell>
                      );
                    } else if (staticColumns.PLATEFORM === column.key) {
                      return (
                        <TableCell key={row[column.key]}>
                          <Plateform
                            key={row[column.key]}
                            plateform={row[column.key]}
                          />
                        </TableCell>
                      );
                    } else if (column.showWithImage) {
                      return (
                        <TableCell key={row[column.key]}>
                          {/* {row[column.key]} */}
                          <ShowImg
                            name={row[column.key]}
                            img={row[column.imageKey]}
                          />
                        </TableCell>
                      );
                    } else if (staticColumns.ID === column.key) {
                      return (
                        <TableCell
                          onClick={() => {
                            handleView(row[column.dataKey]);
                          }}
                          key={row[column.key]}
                          style={commonTableStyles.id}
                        >
                          {row[column.key]}
                        </TableCell>
                      );
                    } else if (staticColumns.ACTION === column.key) {
                      return (
                        <TableCell
                          key={row._id ?? ""}
                          onClick={(event: React.MouseEvent<any>) => {
                            setAnchorEl(event.currentTarget);
                            setItem(row);
                          }}
                          style={{ margin: "15%", cursor: "pointer" }}
                        >
                          <MoreIcon />
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={row[column.key]}>
                          {row[column.key]}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* </Paper> */}
    </div>
  );
}
