import {createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FaqDetail } from "../../../common/utils";
export const SelectedFaqDetail: FaqDetail = {
  detail: {},
};

const FaqDetailSlice = createSlice({
  name: "faqDetail",
  initialState: SelectedFaqDetail,
  reducers: {
    faqDetail: (state: FaqDetail, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
  },
});

export const { faqDetail } = FaqDetailSlice.actions;

export default FaqDetailSlice.reducer;
