import React, { useRef } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { formats } from "./editor-toolbar";
import "react-quill/dist/quill.snow.css";
import "./style.scss";

export const Editor: React.FC<any> = ({ value = "", setValue = () => {} }) => {
  const quillRef = useRef<any>(null);

  const handleChange = (Editorvalue: any) => {
    console.log("value", value);
    setValue(Editorvalue);
  };

  const handleUndo = () => {
    console.log("undo");
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      if (quill && quill.history) {
        quill.history.undo();
      }
    }
  };

  const handleRedo = () => {
    const quill = quillRef.current.getEditor();
    if (quill && quill.history) {
      quill.history.redo();
    }
  };
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  return (
    <div className="text-editor">
      <EditorToolbar handleRedo={handleRedo} handleUndo={handleUndo} />
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={value}
        onChange={handleChange}
        placeholder={"Write something awesome..."}
        modules={modules}
        formats={formats}
      />
  
    </div>
  );
};

export default Editor;
