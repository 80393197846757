import { Padding } from "@mui/icons-material";

const styles = {
  container: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "0px",
    cursor: "pointer",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "start",
    gap: "20px",
    margin: "20px",
  },
  datePickerContainer: {
    paddingRight: "400px",
  },

  filterContainer: {
    margin: "10px 2px",
    display: "flex",
    justifyContent: "start",
    "flex-direction":"column"

  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px",
  },
};

export default styles;
