import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../../common/utils";
import { setLoading } from "../../../redux/global.slice";
import {
  deleteApiCall,
  getApiCall,
  patchApiCall,
  postApiCall,
} from "../../../common/constants/api";
import * as URLS from "../../../constants/url";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { FaqModule } from "./faqSlice";
import { faqDetail } from "./detailSlice";
import { search } from "./searchPermissionSlice";
import { contentDetail } from "./contentDetailSlice";

export const getFaqsModule = (params: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.FAQ}`,
    params,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(FaqModule(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};

export const deleteSelectedFaq =
  (faqId: any, params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    deleteApiCall(
      `${URLS.FAQ + "/" + faqId}`,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          showAlert(1, response?.data?.message);
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
export const addFaq =(params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    postApiCall(
      `${URLS.FAQ}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
};

export const getFaqDetail = (params: any) => (dispatch: Dispatch<any>) => {
  const body = "";
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.FAQ + "/" + params}`,
    body,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(faqDetail(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};

export const updateFaq =
  (roleId: any, params: any, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    patchApiCall(
      // `${URLS.SUB_ADMIN}`,
      `${URLS.FAQ + "/" + roleId}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          // dispatch(allAdmins(result.data));
          // dispatch(goBac )
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
//content

export const getContentDetail = () => (dispatch: Dispatch<any>) => {
  const body = "";
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.SEARCH_CONTENT}`,
    body,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(contentDetail(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};

export const updateAboutUs =
  (params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    patchApiCall(
      `${URLS.ABOUT_US_CONTENT}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(getContentDetail());
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };


export const updateTermsCondition =
  (params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    patchApiCall(
      `${URLS.TERMS_CONDTION_CONTENT}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
           dispatch(getContentDetail());
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };


export const updatePrivacyPolicy =
  (params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    patchApiCall(
      `${URLS.PRIVACY_POLICY_CONTENT}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
           dispatch(getContentDetail());
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };


