import { COLORS } from "../../common/constants/colors";

export const styles = {
  Activated: {
    color: COLORS.statusGreen,
    backgroundColor: COLORS.bgStatusGreen,
    display: "flex",
    padding: "5px 15px",
    fontSize: "13px",
    justifyContent: "center",
    borderRadius: "30px",
    maxWidth: "100px",
    width: "100%",
  },

  Deactivated: {
    color: COLORS.statusRed,
    backgroundColor: COLORS.bgStatusRed,
    display: "flex",
    justifyContent: "center",
    borderRadius: "30px",
    fontSize: "13px",
    padding: "5px 15px",
    maxWidth: "100px",
    width: "100%",
  },
};
