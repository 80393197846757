import {createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RoleResult } from "../../../common/utils/model";
export const roleInitialState: RoleResult = {
  data: [],
  page: 0,
  limit: 0,
  count: 0,
};

const RoleModuleSlice = createSlice({
  name: "roleModule",
  initialState: roleInitialState,
  reducers: {
    allRolesModule: (state: RoleResult, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.count = action.payload.total;
    },
  },
});

export const { allRolesModule } = RoleModuleSlice.actions;

export default RoleModuleSlice.reducer;
