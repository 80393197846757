import { setLoginDetails } from "./services/login.slice";
import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const loginHelper = () => {
  const dispatch = UseAppDispatch();
  const loginInitialValue = {
    mfaToken: "",
    qrCodeURL: "",
  };

  const goBackFunction = () => {
    dispatch(
      setLoginDetails({
        mfaToken: "",
        qrCodeURL: ""
      })
    );
  };

  return {
    loginInitialValue,
    dispatch,
    goBackFunction,
  };
};

export default loginHelper;
