import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SubAdminResult } from "../../../common/utils/model";

export const userInitialState: SubAdminResult = {
  data: [],
  page: 0,
  limit: 0,
  count: 0,
};

const UserModuleSlice = createSlice({
  name: "users",
  initialState: userInitialState,
  reducers: {
    allUsers: (state: SubAdminResult, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.count = action.payload.total;
    },
  },
});



export const { allUsers } = UserModuleSlice.actions;
export default UserModuleSlice.reducer;
