import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import TableComponent from "../../../components/tableList";
import { TABLES } from "../../../constants/table-columns";

import { UseAppDispatch } from "../../../hooks/useAppDispatch";
import { getFaqsModule, deleteSelectedFaq } from "../services/action";
import { useAppSelector } from "../../../hooks/useAppSelector";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import Menus from "../../../components/form-controls/menu";
import {
  GLOBAL_DATE_FILTER_DATA,
  STATUS_DATA,
} from "../../../constants/filter.data";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { DateRangeIcon } from "@mui/x-date-pickers-pro";
import { useNavigate } from "react-router-dom";
import { ADD_FAQ, ADD_ROLES } from "../../../constants/routes/routes-path";
import ConfirmDialog from "../../../shared/dialogs/confirm-dialog";
import { Faq } from "../../../interface/faq-interface";
import "../../../common/common-table.scss";
import FormButton from "../../../components/form-controls/button/form-button";
export default function FaqList() {
  const navigation = useNavigate();
  const deleteRef = useRef();

  function createData(
    _id: string,
    content: string,
    createdAt: string,
    title: string,
    updatedAt: string
  ) {
    return {
      _id,
      content,
      createdAt,
      title,
      updatedAt,
    };
  }

  const dispatch = UseAppDispatch();
  const queryParamData = {};
  const sortData = {};
  const [faqs, setList] = useState<Faq[]>();
  const { documentList, totalList } = useAppSelector((state) => state.faqs);
  const [selectedFaq, setSelectedFaq] = useState<any>();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { permissionData } = useAppSelector(
    (state) => state.userPermissionInfo
  );

  // useEffect(() => {
  //   fetchFaq();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRefresh = () => {
    fetchFaq();
  };

  const fetchFaq = (reset: boolean = false, sort = "", sortBy = "") => {
    let obj: any = {
      limit: rowsPerPage,
      page: page,
    };
    if (sort && sortBy) {
      obj["sort"] = sort;
      obj["sortBy"] = sortBy;
    }
    const queryParams = {
      ...queryParamData,
      ...obj,
      ...sortData,
    };

    function objToQueryString(obj: any) {
      var k = Object.keys(obj);
      var s = "?";
      for (var i = 0; i < k.length; i++) {
        s += k[i] + "=" + obj[k[i]];
        if (i !== k.length - 1) s += "&";
      }
      return s;
    }

    const params = objToQueryString(queryParams);
    // setAnchorEl(null);
    dispatch(getFaqsModule(params));
  };

  useEffect(() => {
    fetchFaq();
  }, [rowsPerPage, page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documentList) {
      let faqs = documentList.map((item: any) => {
        let createdDate = moment(new Date(item?.createdAt)).format("LLL");
        let updatedDate = moment(new Date(item?.updatedAt)).format("LLL");

        let data = createData(
          item._id,
          item.content,
          createdDate,
          item.title,
          updatedDate
        );
        return data;
      });
      setList(faqs);
    }
  }, [documentList]);

  const onCreate = () => {
    navigation(ADD_FAQ.fullUrl);
  };

  const openDeleteDialog = () => {
    (deleteRef.current as any).openDialog();
  };

  const deleteFaq = (confirm: boolean) => {
    if (confirm && selectedFaq?._id) {
      dispatch(deleteSelectedFaq(selectedFaq?._id, "", onRefresh));
    }
  };

  const actionClick = (action: any, item: any) => {
    setSelectedFaq(item);
    if (action === "edit") {
      navigation("/static-content/edit/" + item._id);
    } else if (action === "delete") {
      openDeleteDialog();
    } else if (action === "view") {
      navigation("/static-content/view/" + item?._id);
    }
  };

  const handleView = (id: string) => {
    navigation(`/static-content/view/${id}`);
  };
  const handleRequestSort = (sortingKey: string, orderBy: string) => {
    fetchFaq(false, orderBy, sortingKey);
  };
  return (
    <div className="table-wrapper">
      <div className="table-content">
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          {
            //@ts-ignore
            permissionData["STATIC_CONTENT"]?.includes("ADD") ? (
              <FormButton
                showCancelBtn={false}
                actionBtnClick={onCreate}
                actionBtnLabel="Create"
              />
            ) : (
              ""
            )
          }
        </div>

        {faqs && (
          <TableComponent
            dataSource={faqs}
            tableColumns={TABLES.FAQ}
            actionClick={actionClick}
            handleRequestSort={handleRequestSort}
            onRowsChange={(value: number) => {
              setRowsPerPage(value);
            }}
            onPageChange={(value: number) => {
              setPage(value);
            }}
            total={totalList}
            handleView={handleView}
            showEdit={true}
            showStatus={false}
            showDelete={
              //@ts-ignore
              permissionData["STATIC_CONTENT"]?.includes("DELETE")
            }
            showSend={false}
            showView={true}
          />
        )}

        <ConfirmDialog
          title={"Delete Faq"}
          message={"Are you sure you want to delete Faq?"}
          cancelButtonText={"Cancel"}
          confirmButtonText={"Confirm"}
          logoutUser={deleteFaq}
          ref={deleteRef}
        />
      </div>
    </div>
  );
}
