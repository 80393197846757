import * as React from "react";
import ButtonControl from "./form-controls/button/basic-button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PATTERN } from "../constants/patterns";
import { PATTERN_ERRORS } from "../constants/error";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import PasswordField from "./form-controls/text-password";
import InputTextField from "./form-controls/text";
import CommonCheckbox from "./form-controls/checkobox";
import { Typography } from "@mui/material";
import CommonSelect from "./form-controls/select";
import DatePicker from "./form-controls/datePicker/datePicker";

interface MyFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  bio: string;
  termsCondition: boolean;
  country: string | string[];
}

export default function SamplePage() {
  const [date, setDate] = React.useState(new Date());

  let min = new Date(new Date().setDate(11));

  let max = new Date(new Date().setDate(29));

  const handleSubmit = (values: MyFormValues) => {
    // handle form submission
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(PATTERN.password, {
        message: PATTERN_ERRORS(PATTERN.password, "password"),
      }),
    bio: Yup.string().required("Bio is required"),
    termsCondition: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
    country: Yup.string().required("Country is required."),
  });

  const initialValues = {
    firstName: "dd",
    lastName: "",
    email: "",
    password: "",
    bio: "",
    termsCondition: false,
    country: "",
  };

  const options = [
    { key: "USA", value: "usa" },
    { key: "Canada", value: "canada" },
    { key: "Mexico", value: "mexico" },
  ];

  return (
    <>
      {/* <h1>Common Buttons</h1>
      <div style={{ display: "flex", padding: "2rem" }}>
        <ButtonControl
          variant="contained"
          label="Button"
          disabled={false}
          color={"success"}
          onClick={onButtonClick}
          type="button"
          size={"medium"}
          startIcon={<DeleteIcon />}
        />
        <IconButtonControl
          disabled={false}
          color={"error"}
          onClick={onButtonClick}
          size={"medium"}
          icon={<DeleteIcon />}
        ></IconButtonControl>
      </div> */}
      <div className="form-controls-example" style={{ padding: "3rem" }}>
        <h1>Common Form Input</h1>
        <Box>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={validationSchema}
          >
            <Form>
              <Stack spacing={2}>
                <InputTextField name="firstName" label="First Name" />
                <InputTextField name="lastName" label="Last Name" />
                <InputTextField name="email" label="Email" type="email" />
                <PasswordField
                  name="password"
                  label="Password"
                  type="password"
                ></PasswordField>
                <CommonSelect
                  name="country"
                  label="Country"
                  options={options}
                  searchable={true}
                  multiple={true}
                  onSelectionChange={(selected, searchValue) => {
                    // Handle the selected values and search text in your parent component
                  }}
                />
                <InputTextField
                  multiline={true}
                  rows={4}
                  name="bio"
                  label="Bio"
                />
                <Typography
                  component={"span"}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <CommonCheckbox
                    name="termsCondition"
                    label="I accept the terms and conditions"
                  />
                </Typography>
                <ButtonControl
                  variant="contained"
                  label="Submit"
                  disabled={false}
                  color={"info"}
                  type="submit"
                  sx={{ width: "max-content" }}
                />
              </Stack>
            </Form>
          </Formik>
          <DatePicker
            readOnly={false}
            date={null}
            updateDate={setDate}
            required={true}
            disabled={false}
            disableFuture={false}
            disablePast={false}
            minDate={min}
            maxDate={max}
          />
        </Box>
      </div>
    </>
  );
}
