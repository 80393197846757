import "./google-authenticator.scss";
import { Formik, Form } from "formik";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import verifyHelper from "../onboarding/verification-code/helper";

import { onVerify } from "../onboarding/verification-code/services/action";
import PasswordField from "../../components/form-controls/text-password";
import Schema from "../../common/constants/schema";
import "./google-authenticator-scan.scss";
export default function GoogleAuthenticatorCode() {
  const navigation = useNavigate();
  const { verifyInitialValue, dispatch } = verifyHelper();
  useEffect(() => {});

  const handleBack = () => {
    navigation("/google-scan");
  };

  const onSubmit = (values: any) => {
    let verifyPayload: any = {
      otp: values?.otp,
      platform: "1",
    };
    dispatch(onVerify(verifyPayload, navigation));
  };
  return (
    <div className="scanner-wrapper">
      <div className="scanner-container">
        <img alt="logo" className="logo" src="../../../images/logo.svg" />
        <h4>Google App Authentication</h4>
        <p>Secure your account with TOTP two-factor authentication</p>
        <div className="scanner-dis">
          <span>Enter the verfication code*</span>
        </div>
        <Formik
          initialValues={verifyInitialValue}
          onSubmit={onSubmit}
          validationSchema={Schema.VerifySchema}
        >
          {({ isValid }) => (
            <Form className="verify-pass">
              <Stack spacing={2}>
                <PasswordField
                  name="otp"
                  label="Verification code"
                  type="password"
                  showlabel={false}
                  otp={true}
                ></PasswordField>
                <button
                  type="submit"
                  disabled={!isValid}
                  className="action-btn"
                >
                  Verify
                </button>
                <button onClick={handleBack} className="link-btn">
                  Back
                </button>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
