import { useNavigate, useParams } from "react-router-dom";
import { SUB_ADMIN } from "../../../constants/routes/routes-path";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { Formik, Form } from "formik";
import Schema from "../../../common/constants/schema";
import { ChangeEvent, useEffect, useState } from "react";
import adminHelper from "./helper";
import {
  addSubAdmin,
  directaddSubAdmin,
  getRoles,
  getSubAdminDetail,
  updateSubAdmin,
  updateSubAdminImg,
} from "../services/action";
import { Avatar, Divider, IconButton, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { COUNTRIES } from "../../../common/constants/countries";
import InputTextField from "../../../components/form-controls/text";
import CommonSelect from "../../../components/form-controls/select";
import {
  ArrowForwardIosIcon,
  InfoIcon,
  uploadIcon,
} from "../../../constants/images";
import { Delete } from "@mui/icons-material";
import { IMAGE_EXT, IMAGE_FOLDER } from "../../../enums/uplaod_image.enum";
import { showAlert } from "../../../common/utils";
import ImageCropper from "../../admin/profile/image-cropper";
import "../profile.scss";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
import FormButton from "../../../components/form-controls/button/form-button";

export default function EditSubAdmin() {
  const { detail } = useAppSelector((state) => state.adminDetail);
  const { initialValue, dispatch } = adminHelper(true, detail);
  const { data } = useAppSelector((state) => state.roles);
  const [roles, setRoles] = useState<any>();
  const [countryList, setCountryList] = useState<any>();
  const [FormInitialValue, setFormInitialValue] = useState<any>(initialValue);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [chooseImage, setChooseImage] = useState<boolean>(false);
  const [cropImage, setCropImage] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [extension, setExtension] = useState<any>("");
  const [initialImage, setInitialImage] = useState<any>("");
  const navigation = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    const rolelist = data.map((item) => {
      return {
        value: item._id,
        key: item.title,
      };
    });
    setRoles(rolelist);
  }, [data]);

  const updateInitialValue = () => {
    setFormInitialValue((prev: any) => ({
      ...prev,
      email: detail?.email || "",
      firstName: detail?.name?.first || "",
      lastName: detail?.name?.last || "",
      phone: detail?.phoneNumber || "",
      role: detail?.role?._id || "",
      countryCode: detail?.countryCode || "",
    }));
    if (detail?.avatar) {
      let ImagePath = detail?.avatar.split("?")?.[0].split("Accelerator")?.[1];
      setInitialImage(ImagePath || uploadIcon);
      setSelectedImage(detail?.avatar);
    }
  };

  useEffect(() => {
    if (detail && roles?.length) {
      updateInitialValue();
    }
  }, [detail, roles, countryList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let countries = COUNTRIES.map((country) => {
      return {
        value: country.code,
        key: country.code + " " + country.name + " " + country.flag,
      };
    });
    setCountryList(countries);
    dispatch(getRoles());
    getSubAdminData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSubAdminData = () => {
    dispatch(getSubAdminDetail(id));
  };

  const onBack = () => {
    navigation(SUB_ADMIN.fullUrl);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChooseImage(true);
    const file: any = event.target.files?.[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (IMAGE_EXT.includes(fileExtension)) {
      setExtension(fileExtension);
      setFile(file);
      if (file) {
        setShowModal(true);
        setCropImage(URL.createObjectURL(file));
      }
    } else {
      showAlert(2, "Please upload image in given formates");
    }
  };

  const deleteImage = () => {
    setFile("");
    setSelectedImage("");
  };

  const onSubmit = async (values: any) => {
    let profilePayload: any = {
      avatar: initialImage || "",
      email: values.email,
      name: {
        first: values.firstName,
        last: values.lastName,
      },
      phoneNumber: values.phone.toString(),
      role: values?.role,
      countryCode: values.countryCode,
    };
    let imagePayload: any = {
      action: IMAGE_FOLDER.ADMIN,
      extension: extension,
    };
    if (chooseImage) {
      dispatch(
        updateSubAdminImg(id, profilePayload, imagePayload, file, onBack)
      );
    } else {
      dispatch(updateSubAdmin(id, profilePayload, onBack));
    }
  };
  return (
    <>
      <Box className="detail-wrapper" sx={{ width: "100%" }}>
        <div className="detail-container">
          <BreadCrumb
            showMultipleLink={true}
            mainLink={SUB_ADMIN.fullUrl}
            mainLabel={"Sub Admin Management"}
            secondaryLabel={"Edit"}
          />
        </div>
        <Box
          sx={{
            display: "grid",
            boxShadow: "none",
            padding: 5,
            borderRadius: 2,
            background: "#ffffff",
          }}
        >
          <div className="sub-title">Sub Admin Details</div>
          <Divider className="role-divider" sx={{ margin: "20px 0px" }} />
          <hr />

          <div className="upload-image">
            <input
              accept="image/*"
              id="image-input"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
            <div className="avatar-container">
              {/* <div className="image-upload"> */}
              <label className={"avatar-label"} htmlFor="image-input">
                <Avatar
                  alt="Avatar"
                  src={selectedImage || uploadIcon}
                  className="avatar"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    backgroundColor: "#199ab0",
                    color: "#ffffff",
                    marginBottom: "10px",
                  }}
                ></Avatar>
              </label>
              {/* </div> */}

              <div className="pop-title">
                <InfoIcon />
                <span>
                  JPEG,PNG,JPG format are allowed with maximum size of 1 MB
                </span>
              </div>
              {selectedImage && (
                <IconButton onClick={deleteImage} className="cancel-btn">
                  <Delete />
                </IconButton>
              )}
            </div>
            {/* )} */}
          </div>
          <Formik
            validateOnMount={true}
            enableReinitialize={true}
            initialValues={FormInitialValue}
            onSubmit={onSubmit}
            validationSchema={Schema.AdminSchema}
          >
            {({ errors, touched, values, dirty, isValid }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <InputLabel className="input-label-title">
                      Phone Number
                    </InputLabel>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3} sm={3}>
                        <CommonSelect
                          name="countryCode"
                          label="Country Code"
                          options={countryList}
                          searchable={true}
                          multiple={false}
                          onSelectionChange={(selected, searchValue) => {}}
                          value={values?.countryCode}
                          style={{ width: "100%", gap: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={9} sm={9}>
                        <InputTextField
                          name="phone"
                          label="Phone number"
                          required={false}
                          showlabel={false}
                          variant="outlined"
                          type="number"
                          numberOnly={true}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="email"
                      label="Email"
                      variant="outlined"
                      type="email"
                      fullWidth
                    />
                  </Grid>

                  <Grid xs={12} sm={12} item>
                    <InputLabel className="input-label-title">
                      Role and Access*
                    </InputLabel>
                    <div>
                      <CommonSelect
                        name="role"
                        label="Role"
                        options={roles}
                        searchable={true}
                        multiple={false}
                        onSelectionChange={(selected) => {}}
                        value={values?.role}
                        style={{ width: "40%" }}
                      />
                    </div>
                  </Grid>

                  <Grid xs={12} sm={12} item>
                    <FormButton
                      cancelBtnClick={onBack}
                      actBtnDisabled={!isValid || !dirty}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <ImageCropper
        setCropImage={setCropImage}
        cropImage={cropImage}
        setShowModal={setShowModal}
        showModal={showModal}
        setSelectedImage={setSelectedImage}
        file={file}
        setFile={setFile}
      />
    </>
  );
}
