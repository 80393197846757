export const TABLES = {
  SUB_ADMIN: [
    // getColumnConfig("_id", "id", "_id"),
    getColumnConfig("id", "Sub Admin ID", "_id"),
    getColumnConfig("name", "Name", "name", true, "avatar", false),
    getColumnConfig("email", "Email ID", "email"),
    getColumnConfig("role", "Role & Access", "role"),
    getColumnConfig("createdAt", "Created On", "createdAt", false, "", true),
    getColumnConfig("isActive", "Status", "isActive"),
    getColumnConfig("action", "Action", ""),
  ],
  USERS: [
    getColumnConfig("id", "User ID", "_id"),
    getColumnConfig("name", "Name", "name", true, "profilePictureUrl", false),
    getColumnConfig("email", "Email ID", "email"),
    getColumnConfig("phone", "Phone", "phone"),
    getColumnConfig("createdAt", "Created On", "createdAt", false, "", true),
    getColumnConfig(
      "updatedDate",
      "Updated On",
      "updatedDate",
      false,
      "",
      true
    ),

    getColumnConfig("isActive", "Status", "isActive"),
    getColumnConfig("action", "Action", ""),
  ],

  ROLE: [
    // getColumnConfig("_id", "id", "_id"),
    getColumnConfig("id", "Role ID", "_id"),
    getColumnConfig("title", "Role Name", "title", false, "pictureUrl", true),
    getColumnConfig("createdAt", "Created On", "createdAt", false, "", true),
    getColumnConfig("isActive", "Status", "isActive"),
    getColumnConfig("action", "Action", ""),
  ],

  FAQ: [
    // getColumnConfig("_id", "id", "_id"),
    getColumnConfig("title", "Questions", "_id"),
    getColumnConfig("updatedAt", "Last updated", "title", false, "", false),
    getColumnConfig("createdAt", "Created On", "createdAt", false, "", true),
    getColumnConfig("action", "Action", ""),
  ],

  NOTIFICATION: [
    // getColumnConfig("_id", "id", "_id"),
    getColumnConfig("id", "Notification ID", "_id"),
    getColumnConfig("title", "Title", "title", false, "pictureUrl", false),
    getColumnConfig("platform", "Recipient", ""),

    getColumnConfig("createdAt", "Added On", "createdAt", false, "", true),
    getColumnConfig("action", "Action", ""),
  ],
};

export function getColumnConfig(
  key: string,
  name: string,
  dataKey: string,
  showWithImage: boolean = false,
  imageKey: string = "",
  isSortable = false,
  showWithImageRating: string = ""
) {
  return {
    key,
    name,
    dataKey,
    showWithImage,
    imageKey,
    isSortable,
    showWithImageRating,
  };
}
