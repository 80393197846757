//@ts-ignore
import { makeStyles } from '@mui/styles'; 

const passwordStyles = makeStyles((theme:any) => ({
  container: {
    display: "flex",
    alignItems: "center",
    },
    errorBox: {
        marginTop:"10px"
    },
  icon: {
    // marginTop: "10px",
    fontSize: "1rem",
    color: "gray",
    paddingRight: "5px",
    // Adjust the size as needed
  },
  iconColor: {
    color: "green",
  },
  newPassword: {
    width: "20%",
    // height: "30px",
  },
}));

export default passwordStyles;
