import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { VerifyModel } from "../../../../common/utils";

const initialState: VerifyModel = {
  authToken: "",
  refreshToken: "",
};

const verifySlice = createSlice({
  name: "verifyCode",
  initialState,
  reducers: {
    setVerifyDetails: (
      state: VerifyModel,
      action: PayloadAction<VerifyModel>
    ) => {
      state.refreshToken = action.payload.refreshToken;
      state.authToken = action.payload.authToken;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVerifyDetails } = verifySlice.actions;

export default verifySlice.reducer;
