import { COLORS } from "../../common/constants/colors";
import { FontFamily } from "../../common/utils";

export const commonTableStyles = {
  headerRow: { border: `1px solid ${COLORS.border}` },
  id: {
    color: "#1ca2b7",
    cursor:"pointer",
    textDecoration: 'underline',

  },
  
  tableHeader: {
    "& th": {
      padding: "20px",
      textAlign: "center",
      font: "normal normal 500 14px/18px SofiaPro",
      letterSpacing: "0.08px",
      color: COLORS.tableHeader,
      whiteSpace: "nowrap",
    },
    "& th:nth-last-of-type(1)": { textAlign: "center" },
  },
  table: { fontFamily: "SofiaPro" },
  row: {
    "& td": {
      textAlign: "center",
      padding: "14px 20px",
      font: FontFamily(500, 13, 18.2),
      letterSpacing: "-0.3px",
      color: "#767676",
    },
    "& td:nth-last-of-type(1)": { textAlign: "center" },
  },
  sortIcon: { paddingLeft: "3px", height: "11px", cursor: "pointer" },
  actionTitle: {
    font: "normal normal 600 14px/14px SofiaPro",
    padding: "10px",
    color: COLORS.black,
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minWidth: "125px",
    cursor: "pointer",
    backgroundColor: COLORS.white,
  },
  divider: { width: "100%", backgroundColor: COLORS.actionPopupBorder },
  pointer: { cursor: "pointer" },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "20px 0 35px",
  },
  yesCancelGrayBtn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "130px",
    padding: "13px",
    marginRight: "20px",
  },
  yesCancelthemeBtn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "130px",
    padding: "13px",
  },
  noDataCell: {
    font: "normal normal 500 16px/24px SofiaPro",
    textAlign: "center",
    padding: "16px",
  },
  link: { color: COLORS.primary, cursor: "pointer" },
  noData: { display: "flex", justifyContent: "center" },
  statusBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "30px",
    borderRadius: "30px",
    flex: "none",
    order: 5,
    flexGrow: 0,
    font: FontFamily(500, 14, 18),
    textAlign: "center",
    margin: "0 auto",
    textTransform: "capitalize",
  },
  successBox: { background: "rgba(1, 188, 130, 0.1)", color: "#01BC82" },
  errorBox: { color: "#E71515", background: "rgba(246, 36, 36, 0.1)" },
  avatar: {
    height: "30px",
    width: "30px",
    marginRight: "5px",
    backgroundColor: COLORS.paginationGrey,
  },
  avatarInitials: {
    backgroundColor: COLORS.paginationGrey,
    height: "30px",
    width: "30px",
    marginRight: "5px",
    font: FontFamily(600, 14, 14),
  },
};

export const blogManagementStyles = {
  tableHeader: {
    "& th:nth-of-type(2)": {
      textAlign: "left",
    },
    "& th:nth-of-type(3)": {
      textAlign: "left",
    },
  },
  row: {
    "& td:nth-of-type(2)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "150px",
      textAlign: "left",
    },
    "& td:nth-of-type(3)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "150px",
      textAlign: "left",
    },
  },
};

export const categoryManagementStyles = {
  tableHeader: {
    "& th:nth-of-type(2)": { textAlign: "left" },
  },
  row: {
    "& td:nth-of-type(2)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "150px",
      textAlign: "left",
    },
    "& td:nth-of-type(3)": { font: "normal normal 500 14px/18px SofiaPro" },
  },
};

export const faqStyles = {
  tableHeader: { "& th:nth-of-type(2)": { textAlign: "left" } },
  row: {
    "& td:nth-of-type(2)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "150px",
      textAlign: "left",
    },
    "& td:nth-of-type(3)": {
      font: "normal normal 500 14px/18px SofiaPro",
    },
  },
  innerContainer: {
    borderRadius: "6px",
    height: "100%",
    marginTop: "15px",
    minHeight: "65vh",
    backgroundColor: COLORS.white,
    paddingTop: "20px",
    boxShadow: "0px 1px 0px #dadbe4",
  },
};

export const userStyles = {
  tableHeader: {
    "& th:nth-of-type(2)": { textAlign: "left" },
    "& th:nth-of-type(3)": { textAlign: "left" },
  },
  row: {
    "& td:nth-of-type(2)": { alignItems: "center", display: "flex" },
    "& td:nth-of-type(3)": { textAlign: "left" },
    "& td:nth-of-type(5)": { alignItems: "center" },
  },
  viewLink: {
    alignItems: "center",
    display: "flex",
    color: COLORS.primary,
    cursor: "pointer",
  },
};

export const brandStyles = {
  tableHeader: { "& th:nth-of-type(3)": { textAlign: "left" } },
  row: { "& td:nth-of-type(3)": { textAlign: "left" } },
};

export const productStyles = {
  tableHeader: {
    "& th:nth-of-type(2)": { textAlign: "left" },
    "& th:nth-of-type(3)": { textAlign: "left" },
  },
  row: {
    "& td:nth-of-type(2)": { textAlign: "left" },
    "& td:nth-of-type(3)": { textAlign: "left" },
  },
};

export const bookingStyles = {
  tableHeader: {
    "& th:nth-of-type(2)": { textAlign: "left" },
    "& th:nth-of-type(3)": { textAlign: "left" },
  },
  row: {
    "& td:nth-of-type(2)": { textAlign: "left", textTransform: "capitalize" },
    "& td:nth-of-type(3)": { textAlign: "left", textTransform: "capitalize" },
  },
  closeIcon: {
    cursor: "pointer",
    right: "20px",
    top: "20px",
    position: "absolute",
  },
  innerContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  logo: { height: "80px", width: "80px", marginBottom: "20px" },
  detail: { font: FontFamily(500, 18, 22.5) },
  selectComp: { width: "25%", marginRight: "20px" },
};

export const cusDocuments = {
  tableHeader: {
    "& th:nth-of-type(2)": { textAlign: "left" },
    "& th:nth-of-type(5)": { textAlign: "center" },
  },
  row: {
    "& td:nth-of-type(2)": { textAlign: "left" },
    "& td:nth-of-type(4)": { textTransform: "capitalize" },
    "& td:nth-of-type(5)": { textAlign: "center" },
  },
  mainContainer: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    backgroundColor: "#ffffff",
    padding: "6% 3% 6%",
    minWidth: "35%",
    maxWidth: "35%",
  },
  innerContainer: { display: "flex", flexDirection: "column", alignItems: "center" },
};

export const modelStyles = {
  tableHeader: {
    "& th:nth-of-type(2)": { textAlign: "left" },
    "& th:nth-of-type(5)": { textAlign: "center" },
  },
  row: {
    "& td:nth-of-type(2)": { textAlign: "left" },
    "& td:nth-of-type(3)": { textAlign: "left" },
    "& td:nth-of-type(5)": { textAlign: "left" },
  },
};

export const referralStyle = {
  tableHeader: { "& th:nth-of-type(2)": { textAlign: "left" } },
  row: { "& td:nth-of-type(2)": { textAlign: "left", textTransform: "capitalize" } },
  name: { display: "flex", alignItems: "center" },
};

export const refBookingStyle = {
  tableHeader: { "& th:nth-of-type(3)": { textAlign: "left" } },
  row: { "& td:nth-of-type(3)": { textAlign: "left" } },
  name: { display: "flex", alignItems: "center" },
};
