import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { setVerifyDetails } from "./verify.slice";
import { showAlert } from "../../../../common/utils";
import { setLoading, setToken } from "../../../../redux/global.slice";
import { apiSuccessCode } from "../../../../common/constants/axios.instance";
import { putApiCall } from "../../../../common/constants/api";
import * as URLS from "../../../../constants/url";
import { SUB_ADMIN } from "../../../../constants/routes/routes-path";
import { setAccessToken } from "../../../../services/storage/storage-service";

export const onVerify =
  (param: any, navigate: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    putApiCall(
      `${URLS.LOGIN}`,
      param,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;
        if (data?.statusCode === apiSuccessCode.success) {
          dispatch(
            setVerifyDetails({
              authToken: data?.result?.authToken,
              refreshToken: data?.result?.refreshToken,
            })
          );
        }
        dispatch(setLoading(false));
        setAccessToken(data?.result?.authToken);
        dispatch(setToken(data?.result?.authToken));
        navigate(SUB_ADMIN.fullUrl);
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
