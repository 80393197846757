import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../../constants/routes/routes-path";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { Formik, Form } from "formik";
import InputTextField from "../../../components/form-controls/text";
import { Stack } from "@mui/material";
import PasswordField from "../../../components/form-controls/text-password";
import Schema from "../../../common/constants/schema";
import loginHelper from "./helper";
import { onLogin } from "./services/action";

export default function Login() {
  const { loginInitialValue, dispatch } = loginHelper();
  const navigation = useNavigate();
  const forgotPassword = () => {
    navigation(FORGOT_PASSWORD.fullUrl);
  };

  return (
    <>
      <div className="login-form">
        <Card
          variant="outlined"
          sx={{
            minWidth: "500px",
            maxWidth: "500px",
            border: "none",
            background: "transparent",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              color="textSecondary"
              mb={1}
              style={{ textAlign: "start" }}
            >
              Welcome to Accelerator Admin Panel.
            </Typography>
            <div className="login-sub-head">Login to access your account</div>

            <Formik
              validateOnMount={true}
              initialValues={loginInitialValue}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(onLogin(values, setSubmitting, navigation));
              }}
              validationSchema={Schema.LoginSchema}
            >
              {({ isValid }) => (
                <Form>
                  <Stack spacing={2}>
                    <InputTextField
                      name="email"
                      label="Email Address"
                      type="email"
                    />
                    <PasswordField
                      name="password"
                      label="Password"
                      type="password"
                    ></PasswordField>

                    <ButtonControl
                      label="Forgot Password?"
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        color: "#199ab0",
                        cursor: "pointer",
                      }}
                      onClick={forgotPassword}
                    />

                    <button
                      type="submit"
                      disabled={!isValid}
                      className="action-btn"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Login
                    </button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
