import { removeSession } from "../utils";
import { apiErrorCode, $axios, API_URL, $axios1 } from "./axios.instance";

const StatusCode = apiErrorCode;

export const checkUserValidation = (data: any) => {
  if (data && data.statusCode) {
    const { statusCode: stc } = data;
    return (
      stc === StatusCode.SESSION_EXPIRED ||
      stc === StatusCode.INVALID_TOKEN ||
      stc === StatusCode.ACCOUNT_BLOCKED
    );
  }
  return false;
};

const getApiCall = (
  endPoint: string,
  params: string,
  successCallback: Function,
  errorCallback: Function
) => {
  $axios
    .get(API_URL + endPoint + params, {})
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {

        errorCallback({
          statusCode: "",
          message: err.message,
        });
      } else if (
        err.response.status === apiErrorCode.NOT_AUTHORIZED &&
        err.response.data.statusCode === apiErrorCode.NOT_AUTHORIZED
      ) {
        window.location.replace("/access-denied");
      } else if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err?.response?.data)) {
        removeSession();
        errorCallback(err?.response?.data);
      } else if (err?.response?.data && err?.response?.data.statusCode === apiErrorCode.USER_BLOCKED) {
        localStorage.clear();
        window.location.replace("/");
        errorCallback(err?.response?.data);
      } else if (err.response) {
        errorCallback(err.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

const postApiCall = (
  endPoint: string,
  dataToSend: { [key: string]: any },
  successCallback: Function,
  errorCallback: Function
) => {


  $axios
    .post(API_URL + endPoint, dataToSend)
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        errorCallback({
          statusCode: "",
          message: err.message,
        });
      } else if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response?.data)) {
        removeSession();
        errorCallback(err.response?.data);
      } else if (
        err.response?.data &&
        err.response?.data.statusCode === apiErrorCode.USER_BLOCKED
      ) {
        localStorage.clear();
        // if (
        //   window.location.pathname === Paths.AUTHENTICATION ||
        //   window.location.pathname === Paths.AUTHENTICATIONVERIFY
        // ) {
        //   window.location.replace("/");
        // }
        errorCallback(err.response?.data);
      } else if (err.response) {
        errorCallback(err.response?.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

const putApiCall = (
  endPoint: string,
  params: { [key: string]: any },
  successCallback: Function,
  errorCallback: Function
) => {

  $axios
    .put(API_URL + endPoint, params)
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => { 
      if (err.code === "ERR_NETWORK") {

        errorCallback({
          statusCode: "",
          message: err.message,
        });
      } else if (err.code === "ECONNABORTED") {
        
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err?.response?.data)) {
        
        removeSession();
        errorCallback(err?.response?.data);
      } else if (err?.response?.data && err?.response?.data.statusCode === apiErrorCode.USER_BLOCKED) {
        localStorage.clear();
        errorCallback(err?.response?.data);
      } else if (err.response) {
        
        errorCallback(err?.response?.data);
      } else if (!err.response) {
        
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

const patchApiCall = (
  endPoint: string,
  params: { [key: string]: any } | string,
  successCallback: Function,
  errorCallback: Function
) => {
  $axios
    .patch(API_URL + endPoint, params)
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        errorCallback({
          statusCode: "",
          message: err.message,
        });
      } else if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err?.response?.data)) {
        removeSession();
        errorCallback(err.response.data);
      } else if (err?.response?.data && err?.response?.data.statusCode === apiErrorCode.USER_BLOCKED) {
        localStorage.clear();
        // if (
        //   window.location.pathname === Paths.AUTHENTICATION ||
        //   window.location.pathname === Paths.AUTHENTICATIONVERIFY
        // ) {
        //   window.location.replace("/");
        // }
        errorCallback(err.response.data);
      } else if (err?.response) {
        errorCallback(err?.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

const deleteApiCall = (
  endPoint: string,
  successCallback: Function,
  errorCallback: Function
) => {
  $axios
    .delete(API_URL + endPoint, {})
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        errorCallback({
          statusCode: "",
          message: err.message,
        });
      } else if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response.data)) {
        removeSession();
        errorCallback(err.response.data);
      } else if (err.response.data && err.response.data.statusCode === apiErrorCode.USER_BLOCKED) {
        localStorage.clear();
        // if (
        //   window.location.pathname === Paths.AUTHENTICATION ||
        //   window.location.pathname === Paths.AUTHENTICATIONVERIFY
        // ) {
        //   window.location.replace("/");
        // }
        errorCallback(err.response.data);
      } else if (err.response) {
        errorCallback(err.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

const putS3ApiCall = (
  endPoint: string,
  params: { [key: string]: any },
  successCallback: Function,
  errorCallback: Function
) => {
  $axios1
    .put(endPoint, params, { headers: { "Content-Type": params.type } })
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        errorCallback({
          statusCode: "",
          message: err.message,
        });
      } else if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response.data)) {
        removeSession();
        errorCallback(err.response.data);
      } else if (err.response.data && err.response.data.statusCode === apiErrorCode.USER_BLOCKED) {
        localStorage.clear();
        // if (
        //   window.location.pathname === Paths.AUTHENTICATION ||
        //   window.location.pathname === Paths.AUTHENTICATIONVERIFY
        // ) {
        //   window.location.replace("/");
        // }
        errorCallback(err.response.data);
      } else if (err.response) {
        errorCallback(err.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

const getApiCallAsyncAwait = (endPoint: string, params: any) => {
  return $axios.get(API_URL + endPoint, { params });
};

const postApiCallAsyncAwait = (endPoint: string, data: any, params: any) => {
  return $axios.post(API_URL + endPoint, data, { params });
};

const patchApiCallAsyncAwait = (endPoint: string, data: any, params: any) => {
  return $axios.patch(API_URL + endPoint, data, { params });
};

const putApiCallAsyncAwait = (endPoint: string, data: any, params: any) => {
  return $axios.put(API_URL + endPoint, data, { params });
};

export {
  getApiCall,
  postApiCall,
  putApiCall,
  patchApiCall,
  deleteApiCall,
  putS3ApiCall,
  getApiCallAsyncAwait,
  postApiCallAsyncAwait,
  patchApiCallAsyncAwait,
  putApiCallAsyncAwait,
};
