import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const roleHelper = (isEdit: any, detail: any) => {
  const dispatch = UseAppDispatch();
  const initialValue = {
    title: "",
    description: "",
  };

  const permissionActions = [
    { name: "view", value: false, show: false, selectAll: false },
    { name: "add", value: false, show: false, selectAll: false },
    { name: "edit", value: false, show: false, selectAll: false },
    { name: "delete", value: false, show: false, selectAll: false },
    { name: "status", value: false, show: false, selectAll: false },
  ];

  const goBackFunction = () => {};

  return {
    initialValue,
    permissionActions,
    dispatch,
    goBackFunction,
  };
};

export default roleHelper;
