export const PATTERN = {
  email:
    /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@!%&*#])[A-Za-z\d@!%&*#]{8,}$/,
  name: /^\s*[-a-zA-Z\s]+([-a-zA-Z]+)*\s*$/,
  phone: /^[0-9]+$/,
  numberOnly: /^[0-9]+$/,
  price: /(^[0][1-9]+)|([1-9]\d*)+$/,
  otp: /^\d{1,6}$/,
};
