import SwipeableViews from "react-swipeable-views";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import FaqList from "./faq-list";
import TabPanel from "./tabPanel";
import { Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AboutUs from "./about-us";
import contentHelper from "./helper";
import { getContentDetail } from "./services/action";
import TermAndCondittion from "./tems-conditon";
import PrivacyPolicy from "./privacy-policy";
import "../../common/common-table.scss";
import BreadCrumb from "../../components/form-controls/breadCrumb";
import { STATIC_CONTENT } from "../../constants/routes/routes-path";
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StaticTab() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tab = params.get("tab");
  const tabValue = parseInt(tab || "0", 10);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const { dispatch } = contentHelper();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation();
    setValue(newValue);
  };

  const getContentData = () => {
    dispatch(getContentDetail());
  };

  useEffect(() => {
    getContentData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue(tabValue);
  }, [tabValue]);

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  return (
    <div className="table-wrapper">
      <div className="heading-wrapper">
        {/* <div className="table-heading">Static Content Management</div> */}
        <BreadCrumb
          showMultipleLink={false}
          mainLink={STATIC_CONTENT.fullUrl}
          mainLabel={"Static Content Management"}
        />
      </div>
      <div className="table-content">
        <Tabs
          value={value}
          onChange={handleChange}
          // style={{ borderBottom: "2px solid #199ab0" }}
          // TabIndicatorProps={{ style: { borderBottom: "2px solid #199ab0", color:"#199ab0" } }}
          TabIndicatorProps={{
            style: {
              border: "2px solid #199ab0",
            },
          }}
          aria-label="basic tabs example"
        >
          <Tab
            label="About Us"
            {...a11yProps(0)}
            component={NavLink}
            to="/static-content?tab=0"
          />
          <Tab
            label="Terms of Use"
            {...a11yProps(1)}
            component={NavLink}
            to="/static-content?tab=1"
          />
          <Tab
            label="Privacy Policy"
            {...a11yProps(1)}
            component={NavLink}
            to="/static-content?tab=2"
          />
          <Tab
            label="FAQ"
            {...a11yProps(2)}
            component={NavLink}
            to={`/static-content?tab=3`}
          />
        </Tabs>

        <Divider />
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={(e) => handleChangeIndex}
        >
          <TabPanel value={value} index={0}>
            <AboutUs />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <TermAndCondittion />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <PrivacyPolicy />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <FaqList />
          </TabPanel>
        </SwipeableViews>
        {/* </Box> */}
      </div>
    </div>
  );
}
