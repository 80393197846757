export const COUNTRIES = [
    {
      id: 'AF',
      iso3: 'AFG',
      name: 'Afghanistan',
      flag: '🇦🇫',
      code: '+93',
      currencySymbol: '؋',
      distanceUnit: 'km',
      weightUnit: 'kg',
      priceRoundOff: false,
      cardTimer: 30,
      errandCardTimer: 30,
      dateFormat: 'DD/MM/YY',
      timeFormat: 'HH:mm:ss',
      sos: '123',
    },
    {
      id: 'AX',
      iso3: 'ALA',
      name: 'Aland Islands',
      flag: '🇦🇽',
      code: '+358-18',
      currencySymbol: '€',
      distanceUnit: 'km',
      weightUnit: 'kg',
      priceRoundOff: true,
      cardTimer: 56,
      dateFormat: 'MMMM d, YYYY',
      timeFormat: 'HH:mm XM',
    },
    {
      id: 'AL',
      iso3: 'ALB',
      name: 'Albania',
      flag: '🇦🇱',
      code: '+355',
      currencySymbol: 'Lek',
      distanceUnit: 'km',
      weightUnit: 'kg',
      priceRoundOff: true,
      cardTimer: 18,
      errandCardTimer: 30,
      dateFormat: 'MMM DD, YYYY',
      timeFormat: 'HH:mm:ss',
    },
    {
      id: 'DZ',
      iso3: 'DZA',
      name: 'Algeria',
      flag: '🇩🇿',
      code: '+213',
      currencySymbol: 'دج',
      distanceUnit: 'km',
      weightUnit: 'kg',
      priceRoundOff: true,
      cardTimer: 24,
      dateFormat: 'MMMM d, YYYY',
      timeFormat: 'HH:mm',
    },
    {
      id: 'AS',
      iso3: 'ASM',
      name: 'American Samoa',
      flag: '🇦🇸',
      code: '+1-684',
      currencySymbol: '$',
    },
    {
      id: 'AD',
      iso3: 'AND',
      name: 'Andorra',
      flag: '🇦🇩',
      code: '+376',
      currencySymbol: '€',
      distanceUnit: 'km',
      weightUnit: 'kg',
      priceRoundOff: true,
      cardTimer: 20,
      errandCardTimer: 30,
      dateFormat: 'DD/MM/YY',
      timeFormat: 'HH:mm:ss',
      sos: '911',
    },
    {
      id: 'AO',
      iso3: 'AGO',
      name: 'Angola',
      flag: '🇦🇴',
      code: '+244',
      currencySymbol: 'Kz',
    },
    {
      id: 'AI',
      iso3: 'AIA',
      name: 'Anguilla',
      flag: '🇦🇮',
      code: '+1-264',
      currencySymbol: '$',
    },
    {
      id: 'AQ',
      iso3: 'ATA',
      name: 'Antarctica',
      flag: '🇦🇶',
      code: '+672',
      currencySymbol: '$',
    },
    {
      id: 'AG',
      iso3: 'ATG',
      name: 'Antigua And Barbuda',
      flag: '🇦🇬',
      code: '+1-268',
      currencySymbol: '$',
    },
    {
      id: 'AR',
      iso3: 'ARG',
      name: 'Argentina',
      flag: '🇦🇷',
      code: '+54',
      currencySymbol: '$',
    },
    {
      id: 'AM',
      iso3: 'ARM',
      name: 'Armenia',
      flag: '🇦🇲',
      code: '+374',
      currencySymbol: '֏',
    },
    {
      id: 'AW',
      iso3: 'ABW',
      name: 'Aruba',
      flag: '🇦🇼',
      code: '+297',
      currencySymbol: 'ƒ',
    },
    {
      id: 'AU',
      iso3: 'AUS',
      name: 'Australia',
      flag: '🇦🇺',
      code: '+61',
      currencySymbol: '$',
    },
    {
      id: 'AT',
      iso3: 'AUT',
      name: 'Austria',
      flag: '🇦🇹',
      code: '+43',
      currencySymbol: '€',
    },
    {
      id: 'AZ',
      iso3: 'AZE',
      name: 'Azerbaijan',
      flag: '🇦🇿',
      code: '+994',
      currencySymbol: 'm',
    },
    {
      id: 'BH',
      iso3: 'BHR',
      name: 'Bahrain',
      flag: '🇧🇭',
      code: '+973',
      currencySymbol: '.د.ب',
    },
    {
      id: 'BD',
      iso3: 'BGD',
      name: 'Bangladesh',
      flag: '🇧🇩',
      code: '+880',
      currencySymbol: '৳',
    },
    {
      id: 'BB',
      iso3: 'BRB',
      name: 'Barbados',
      flag: '🇧🇧',
      code: '+1-246',
      currencySymbol: 'Bds$',
    },
    {
      id: 'BY',
      iso3: 'BLR',
      name: 'Belarus',
      flag: '🇧🇾',
      code: '+375',
      currencySymbol: 'Br',
    },
    {
      id: 'BE',
      iso3: 'BEL',
      name: 'Belgium',
      flag: '🇧🇪',
      code: '+32',
      currencySymbol: '€',
    },
    {
      id: 'BZ',
      iso3: 'BLZ',
      name: 'Belize',
      flag: '🇧🇿',
      code: '+501',
      currencySymbol: '$',
    },
    {
      id: 'BJ',
      iso3: 'BEN',
      name: 'Benin',
      flag: '🇧🇯',
      code: '+229',
      currencySymbol: 'CFA',
    },
    {
      id: 'BM',
      iso3: 'BMU',
      name: 'Bermuda',
      flag: '🇧🇲',
      code: '+1-441',
      currencySymbol: '$',
    },
    {
      id: 'BT',
      iso3: 'BTN',
      name: 'Bhutan',
      flag: '🇧🇹',
      code: '+975',
      currencySymbol: 'Nu.',
    },
    {
      id: 'BO',
      iso3: 'BOL',
      name: 'Bolivia',
      flag: '🇧🇴',
      code: '+591',
      currencySymbol: 'Bs.',
    },
    {
      id: 'BQ',
      iso3: 'BES',
      name: 'Bonaire, Sint Eustatius and Saba',
      flag: '🇧🇶',
      code: '+599',
      currencySymbol: '$',
    },
    {
      id: 'BA',
      iso3: 'BIH',
      name: 'Bosnia and Herzegovina',
      flag: '🇧🇦',
      code: '+387',
      currencySymbol: 'KM',
    },
    {
      id: 'BW',
      iso3: 'BWA',
      name: 'Botswana',
      flag: '🇧🇼',
      code: '+267',
      currencySymbol: 'P',
    },
    {
      id: 'BV',
      iso3: 'BVT',
      name: 'Bouvet Island',
      flag: '🇧🇻',
      code: '+0055',
      currencySymbol: 'kr',
    },
    {
      id: 'BR',
      iso3: 'BRA',
      name: 'Brazil',
      flag: '🇧🇷',
      code: '+55',
      currencySymbol: 'R$',
    },
    {
      id: 'IO',
      iso3: 'IOT',
      name: 'British Indian Ocean Territory',
      flag: '🇮🇴',
      code: '+246',
      currencySymbol: '$',
    },
    {
      id: 'BN',
      iso3: 'BRN',
      name: 'Brunei',
      flag: '🇧🇳',
      code: '+673',
      currencySymbol: 'B$',
    },
    {
      id: 'BG',
      iso3: 'BGR',
      name: 'Bulgaria',
      flag: '🇧🇬',
      code: '+359',
      currencySymbol: 'Лв.',
    },
    {
      id: 'BF',
      iso3: 'BFA',
      name: 'Burkina Faso',
      flag: '🇧🇫',
      code: '+226',
      currencySymbol: 'CFA',
    },
    {
      id: 'BI',
      iso3: 'BDI',
      name: 'Burundi',
      flag: '🇧🇮',
      code: '+257',
      currencySymbol: 'FBu',
    },
    {
      id: 'KH',
      iso3: 'KHM',
      name: 'Cambodia',
      flag: '🇰🇭',
      code: '+855',
      currencySymbol: 'KHR',
    },
    {
      id: 'CM',
      iso3: 'CMR',
      name: 'Cameroon',
      flag: '🇨🇲',
      code: '+237',
      currencySymbol: 'FCFA',
    },
    {
      id: 'CA',
      iso3: 'CAN',
      name: 'Canada',
      flag: '🇨🇦',
      code: '+1',
      currencySymbol: '$',
    },
    {
      id: 'CV',
      iso3: 'CPV',
      name: 'Cape Verde',
      flag: '🇨🇻',
      code: '+238',
      currencySymbol: '$',
    },
    {
      id: 'KY',
      iso3: 'CYM',
      name: 'Cayman Islands',
      flag: '🇰🇾',
      code: '+1-345',
      currencySymbol: '$',
    },
    {
      id: 'CF',
      iso3: 'CAF',
      name: 'Central African Republic',
      flag: '🇨🇫',
      code: '+236',
      currencySymbol: 'FCFA',
    },
    {
      id: 'TD',
      iso3: 'TCD',
      name: 'Chad',
      flag: '🇹🇩',
      code: '+235',
      currencySymbol: 'FCFA',
    },
    {
      id: 'CL',
      iso3: 'CHL',
      name: 'Chile',
      flag: '🇨🇱',
      code: '+56',
      currencySymbol: '$',
    },
    {
      id: 'CN',
      iso3: 'CHN',
      name: 'China',
      flag: '🇨🇳',
      code: '+86',
      currencySymbol: '¥',
    },
    {
      id: 'CX',
      iso3: 'CXR',
      name: 'Christmas Island',
      flag: '🇨🇽',
      code: '+61',
      currencySymbol: '$',
    },
    {
      id: 'CC',
      iso3: 'CCK',
      name: 'Cocos (Keeling) Islands',
      flag: '🇨🇨',
      code: '+61',
      currencySymbol: '$',
    },
    {
      id: 'CO',
      iso3: 'COL',
      name: 'Colombia',
      flag: '🇨🇴',
      code: '+57',
      currencySymbol: '$',
    },
    {
      id: 'KM',
      iso3: 'COM',
      name: 'Comoros',
      flag: '🇰🇲',
      code: '+269',
      currencySymbol: 'CF',
    },
    {
      id: 'CG',
      iso3: 'COG',
      name: 'Congo',
      flag: '🇨🇬',
      code: '+242',
      currencySymbol: 'FC',
    },
    {
      id: 'CK',
      iso3: 'COK',
      name: 'Cook Islands',
      flag: '🇨🇰',
      code: '+682',
      currencySymbol: '$',
    },
    {
      id: 'CR',
      iso3: 'CRI',
      name: 'Costa Rica',
      flag: '🇨🇷',
      code: '+506',
      currencySymbol: '₡',
    },
    {
      id: 'CI',
      iso3: 'CIV',
      name: "Cote D'Ivoire (Ivory Coast)",
      flag: '🇨🇮',
      code: '+225',
      currencySymbol: 'CFA',
    },
    {
      id: 'HR',
      iso3: 'HRV',
      name: 'Croatia',
      flag: '🇭🇷',
      code: '+385',
      currencySymbol: 'kn',
    },
    {
      id: 'CU',
      iso3: 'CUB',
      name: 'Cuba',
      flag: '🇨🇺',
      code: '+53',
      currencySymbol: '$',
    },
    {
      id: 'CW',
      iso3: 'CUW',
      name: 'Curaçao',
      flag: '🇨🇼',
      code: '+599',
      currencySymbol: 'ƒ',
    },
    {
      id: 'CY',
      iso3: 'CYP',
      name: 'Cyprus',
      flag: '🇨🇾',
      code: '+357',
      currencySymbol: '€',
    },
    {
      id: 'CZ',
      iso3: 'CZE',
      name: 'Czech Republic',
      flag: '🇨🇿',
      code: '+420',
      currencySymbol: 'Kč',
    },
    {
      id: 'CD',
      iso3: 'COD',
      name: 'Democratic Republic of the Congo',
      flag: '🇨🇩',
      code: '+243',
      currencySymbol: 'FC',
    },
    {
      id: 'DK',
      iso3: 'DNK',
      name: 'Denmark',
      flag: '🇩🇰',
      code: '+45',
      currencySymbol: 'Kr.',
    },
    {
      id: 'DJ',
      iso3: 'DJI',
      name: 'Djibouti',
      flag: '🇩🇯',
      code: '+253',
      currencySymbol: 'Fdj',
    },
    {
      id: 'DM',
      iso3: 'DMA',
      name: 'Dominica',
      flag: '🇩🇲',
      code: '+1-767',
      currencySymbol: '$',
    },
    {
      id: 'DO',
      iso3: 'DOM',
      name: 'Dominican Republic',
      flag: '🇩🇴',
      code: '+1-829',
      currencySymbol: '$',
    },
    {
      id: 'TL',
      iso3: 'TLS',
      name: 'East Timor',
      flag: '🇹🇱',
      code: '+670',
      currencySymbol: '$',
    },
    {
      id: 'EC',
      iso3: 'ECU',
      name: 'Ecuador',
      flag: '🇪🇨',
      code: '+593',
      currencySymbol: '$',
    },
    {
      id: 'EG',
      iso3: 'EGY',
      name: 'Egypt',
      flag: '🇪🇬',
      code: '+20',
      currencySymbol: 'ج.م',
    },
    {
      id: 'SV',
      iso3: 'SLV',
      name: 'El Salvador',
      flag: '🇸🇻',
      code: '+503',
      currencySymbol: '$',
    },
    {
      id: 'GQ',
      iso3: 'GNQ',
      name: 'Equatorial Guinea',
      flag: '🇬🇶',
      code: '+240',
      currencySymbol: 'FCFA',
    },
    {
      id: 'ER',
      iso3: 'ERI',
      name: 'Eritrea',
      flag: '🇪🇷',
      code: '+291',
      currencySymbol: 'Nfk',
    },
    {
      id: 'EE',
      iso3: 'EST',
      name: 'Estonia',
      flag: '🇪🇪',
      code: '+372',
      currencySymbol: '€',
    },
    {
      id: 'ET',
      iso3: 'ETH',
      name: 'Ethiopia',
      flag: '🇪🇹',
      code: '+251',
      currencySymbol: 'Nkf',
    },
    {
      id: 'FK',
      iso3: 'FLK',
      name: 'Falkland Islands',
      flag: '🇫🇰',
      code: '+500',
      currencySymbol: '£',
    },
    {
      id: 'FO',
      iso3: 'FRO',
      name: 'Faroe Islands',
      flag: '🇫🇴',
      code: '+298',
      currencySymbol: 'Kr.',
    },
    {
      id: 'FJ',
      iso3: 'FJI',
      name: 'Fiji Islands',
      flag: '🇫🇯',
      code: '+679',
      currencySymbol: 'FJ$',
    },
    {
      id: 'FI',
      iso3: 'FIN',
      name: 'Finland',
      flag: '🇫🇮',
      code: '+358',
      currencySymbol: '€',
    },
    {
      id: 'FR',
      iso3: 'FRA',
      name: 'France',
      flag: '🇫🇷',
      code: '+33',
      currencySymbol: '€',
    },
    {
      id: 'GF',
      iso3: 'GUF',
      name: 'French Guiana',
      flag: '🇬🇫',
      code: '+594',
      currencySymbol: '€',
    },
    {
      id: 'PF',
      iso3: 'PYF',
      name: 'French Polynesia',
      flag: '🇵🇫',
      code: '+689',
      currencySymbol: '₣',
    },
    {
      id: 'TF',
      iso3: 'ATF',
      name: 'French Southern Territories',
      flag: '🇹🇫',
      code: '+262',
      currencySymbol: '€',
    },
    {
      id: 'GA',
      iso3: 'GAB',
      name: 'Gabon',
      flag: '🇬🇦',
      code: '+241',
      currencySymbol: 'FCFA',
    },
    {
      id: 'GM',
      iso3: 'GMB',
      name: 'Gambia The',
      flag: '🇬🇲',
      code: '+220',
      currencySymbol: 'D',
    },
    {
      id: 'GE',
      iso3: 'GEO',
      name: 'Georgia',
      flag: '🇬🇪',
      code: '+995',
      currencySymbol: 'ლ',
    },
    {
      id: 'DE',
      iso3: 'DEU',
      name: 'Germany',
      flag: '🇩🇪',
      code: '+49',
      currencySymbol: '€',
    },
    {
      id: 'GH',
      iso3: 'GHA',
      name: 'Ghana',
      flag: '🇬🇭',
      code: '+233',
      currencySymbol: 'GH₵',
    },
    {
      id: 'GI',
      iso3: 'GIB',
      name: 'Gibraltar',
      flag: '🇬🇮',
      code: '+350',
      currencySymbol: '£',
    },
    {
      id: 'GR',
      iso3: 'GRC',
      name: 'Greece',
      flag: '🇬🇷',
      code: '+30',
      currencySymbol: '€',
    },
    {
      id: 'GL',
      iso3: 'GRL',
      name: 'Greenland',
      flag: '🇬🇱',
      code: '+299',
      currencySymbol: 'Kr.',
    },
    {
      id: 'GD',
      iso3: 'GRD',
      name: 'Grenada',
      flag: '🇬🇩',
      code: '+1-473',
      currencySymbol: '$',
    },
    {
      id: 'GP',
      iso3: 'GLP',
      name: 'Guadeloupe',
      flag: '🇬🇵',
      code: '+590',
      currencySymbol: '€',
    },
    {
      id: 'GU',
      iso3: 'GUM',
      name: 'Guam',
      flag: '🇬🇺',
      code: '+1-671',
      currencySymbol: '$',
    },
    {
      id: 'GT',
      iso3: 'GTM',
      name: 'Guatemala',
      flag: '🇬🇹',
      code: '+502',
      currencySymbol: 'Q',
    },
    {
      id: 'GG',
      iso3: 'GGY',
      name: 'Guernsey and Alderney',
      flag: '🇬🇬',
      code: '+44-1481',
      currencySymbol: '£',
    },
    {
      id: 'GN',
      iso3: 'GIN',
      name: 'Guinea',
      flag: '🇬🇳',
      code: '+224',
      currencySymbol: 'FG',
    },
    {
      id: 'GW',
      iso3: 'GNB',
      name: 'Guinea-Bissau',
      flag: '🇬🇼',
      code: '+245',
      currencySymbol: 'CFA',
    },
    {
      id: 'GY',
      iso3: 'GUY',
      name: 'Guyana',
      flag: '🇬🇾',
      code: '+592',
      currencySymbol: '$',
    },
    {
      id: 'HT',
      iso3: 'HTI',
      name: 'Haiti',
      flag: '🇭🇹',
      code: '+509',
      currencySymbol: 'G',
    },
    {
      id: 'HM',
      iso3: 'HMD',
      name: 'Heard Island and McDonald Islands',
      flag: '🇭🇲',
      code: '+672',
      currencySymbol: '$',
    },
    {
      id: 'HN',
      iso3: 'HND',
      name: 'Honduras',
      flag: '🇭🇳',
      code: '+504',
      currencySymbol: 'L',
    },
    {
      id: 'HK',
      iso3: 'HKG',
      name: 'Hong Kong S.A.R.',
      flag: '🇭🇰',
      code: '+852',
      currencySymbol: '$',
    },
    {
      id: 'HU',
      iso3: 'HUN',
      name: 'Hungary',
      flag: '🇭🇺',
      code: '+36',
      currencySymbol: 'Ft',
    },
    {
      id: 'IS',
      iso3: 'ISL',
      name: 'Iceland',
      flag: '🇮🇸',
      code: '+354',
      currencySymbol: 'kr',
    },
    {
      id: 'IN',
      iso3: 'IND',
      name: 'India',
      flag: '🇮🇳',
      code: '+91',
      currencySymbol: '₹',
      distanceUnit: 'km',
      weightUnit: 'kg',
      priceRoundOff: true,
      cardTimer: 30,
      errandCardTimer: 30,
      dateFormat: 'MM/DD/YY',
      timeFormat: 'HH:mm a',
      sos: '100',
    },
    {
      id: 'ID',
      iso3: 'IDN',
      name: 'Indonesia',
      flag: '🇮🇩',
      code: '+62',
      currencySymbol: 'Rp',
    },
    {
      id: 'IR',
      iso3: 'IRN',
      name: 'Iran',
      flag: '🇮🇷',
      code: '+98',
      currencySymbol: '﷼',
    },
    {
      id: 'IQ',
      iso3: 'IRQ',
      name: 'Iraq',
      flag: '🇮🇶',
      code: '+964',
      currencySymbol: 'د.ع',
    },
    {
      id: 'IE',
      iso3: 'IRL',
      name: 'Ireland',
      flag: '🇮🇪',
      code: '+353',
      currencySymbol: '€',
    },
    {
      id: 'IL',
      iso3: 'ISR',
      name: 'Israel',
      flag: '🇮🇱',
      code: '+972',
      currencySymbol: '₪',
    },
    {
      id: 'IT',
      iso3: 'ITA',
      name: 'Italy',
      flag: '🇮🇹',
      code: '+39',
      currencySymbol: '€',
    },
    {
      id: 'JM',
      iso3: 'JAM',
      name: 'Jamaica',
      flag: '🇯🇲',
      code: '+1-876',
      currencySymbol: 'J$',
    },
    {
      id: 'JP',
      iso3: 'JPN',
      name: 'Japan',
      flag: '🇯🇵',
      code: '+81',
      currencySymbol: '¥',
    },
    {
      id: 'JE',
      iso3: 'JEY',
      name: 'Jersey',
      flag: '🇯🇪',
      code: '+44-1534',
      currencySymbol: '£',
    },
    {
      id: 'JO',
      iso3: 'JOR',
      name: 'Jordan',
      flag: '🇯🇴',
      code: '+962',
      currencySymbol: 'ا.د',
    },
    {
      id: 'KZ',
      iso3: 'KAZ',
      name: 'Kazakhstan',
      flag: '🇰🇿',
      code: '+7',
      currencySymbol: 'лв',
    },
    {
      id: 'KE',
      iso3: 'KEN',
      name: 'Kenya',
      flag: '🇰🇪',
      code: '+254',
      currencySymbol: 'KSh',
    },
    {
      id: 'KI',
      iso3: 'KIR',
      name: 'Kiribati',
      flag: '🇰🇮',
      code: '+686',
      currencySymbol: '$',
    },
    {
      id: 'XK',
      iso3: 'XKX',
      name: 'Kosovo',
      flag: '🇽🇰',
      code: '+383',
      currencySymbol: '€',
    },
    {
      id: 'KW',
      iso3: 'KWT',
      name: 'Kuwait',
      flag: '🇰🇼',
      code: '+965',
      currencySymbol: 'ك.د',
    },
    {
      id: 'KG',
      iso3: 'KGZ',
      name: 'Kyrgyzstan',
      flag: '🇰🇬',
      code: '+996',
      currencySymbol: 'лв',
    },
    {
      id: 'LA',
      iso3: 'LAO',
      name: 'Laos',
      flag: '🇱🇦',
      code: '+856',
      currencySymbol: '₭',
    },
    {
      id: 'LV',
      iso3: 'LVA',
      name: 'Latvia',
      flag: '🇱🇻',
      code: '+371',
      currencySymbol: '€',
    },
    {
      id: 'LB',
      iso3: 'LBN',
      name: 'Lebanon',
      flag: '🇱🇧',
      code: '+961',
      currencySymbol: '£',
    },
    {
      id: 'LS',
      iso3: 'LSO',
      name: 'Lesotho',
      flag: '🇱🇸',
      code: '+266',
      currencySymbol: 'L',
    },
    {
      id: 'LR',
      iso3: 'LBR',
      name: 'Liberia',
      flag: '🇱🇷',
      code: '+231',
      currencySymbol: '$',
    },
    {
      id: 'LY',
      iso3: 'LBY',
      name: 'Libya',
      flag: '🇱🇾',
      code: '+218',
      currencySymbol: 'د.ل',
    },
    {
      id: 'LI',
      iso3: 'LIE',
      name: 'Liechtenstein',
      flag: '🇱🇮',
      code: '+423',
      currencySymbol: 'CHf',
    },
    {
      id: 'LT',
      iso3: 'LTU',
      name: 'Lithuania',
      flag: '🇱🇹',
      code: '+370',
      currencySymbol: '€',
    },
    {
      id: 'LU',
      iso3: 'LUX',
      name: 'Luxembourg',
      flag: '🇱🇺',
      code: '+352',
      currencySymbol: '€',
    },
    {
      id: 'MO',
      iso3: 'MAC',
      name: 'Macau S.A.R.',
      flag: '🇲🇴',
      code: '+853',
      currencySymbol: '$',
    },
    {
      id: 'MG',
      iso3: 'MDG',
      name: 'Madagascar',
      flag: '🇲🇬',
      code: '+261',
      currencySymbol: 'Ar',
    },
    {
      id: 'MW',
      iso3: 'MWI',
      name: 'Malawi',
      flag: '🇲🇼',
      code: '+265',
      currencySymbol: 'MK',
    },
    {
      id: 'MY',
      iso3: 'MYS',
      name: 'Malaysia',
      flag: '🇲🇾',
      code: '+60',
      currencySymbol: 'RM',
    },
    {
      id: 'MV',
      iso3: 'MDV',
      name: 'Maldives',
      flag: '🇲🇻',
      code: '+960',
      currencySymbol: 'Rf',
    },
    {
      id: 'ML',
      iso3: 'MLI',
      name: 'Mali',
      flag: '🇲🇱',
      code: '+223',
      currencySymbol: 'CFA',
    },
    {
      id: 'MT',
      iso3: 'MLT',
      name: 'Malta',
      flag: '🇲🇹',
      code: '+356',
      currencySymbol: '€',
    },
    {
      id: 'IM',
      iso3: 'IMN',
      name: 'Man (Isle of)',
      flag: '🇮🇲',
      code: '+44-1624',
      currencySymbol: '£',
    },
    {
      id: 'MH',
      iso3: 'MHL',
      name: 'Marshall Islands',
      flag: '🇲🇭',
      code: '+692',
      currencySymbol: '$',
    },
    {
      id: 'MQ',
      iso3: 'MTQ',
      name: 'Martinique',
      flag: '🇲🇶',
      code: '+596',
      currencySymbol: '€',
    },
    {
      id: 'MR',
      iso3: 'MRT',
      name: 'Mauritania',
      flag: '🇲🇷',
      code: '+222',
      currencySymbol: 'MRU',
    },
    {
      id: 'MU',
      iso3: 'MUS',
      name: 'Mauritius',
      flag: '🇲🇺',
      code: '+230',
      currencySymbol: '₨',
    },
    {
      id: 'YT',
      iso3: 'MYT',
      name: 'Mayotte',
      flag: '🇾🇹',
      code: '+262',
      currencySymbol: '€',
    },
    {
      id: 'MX',
      iso3: 'MEX',
      name: 'Mexico',
      flag: '🇲🇽',
      code: '+52',
      currencySymbol: '$',
    },
    {
      id: 'FM',
      iso3: 'FSM',
      name: 'Micronesia',
      flag: '🇫🇲',
      code: '+691',
      currencySymbol: '$',
    },
    {
      id: 'MD',
      iso3: 'MDA',
      name: 'Moldova',
      flag: '🇲🇩',
      code: '+373',
      currencySymbol: 'L',
    },
    {
      id: 'MC',
      iso3: 'MCO',
      name: 'Monaco',
      flag: '🇲🇨',
      code: '+377',
      currencySymbol: '€',
    },
    {
      id: 'MN',
      iso3: 'MNG',
      name: 'Mongolia',
      flag: '🇲🇳',
      code: '+976',
      currencySymbol: '₮',
    },
    {
      id: 'ME',
      iso3: 'MNE',
      name: 'Montenegro',
      flag: '🇲🇪',
      code: '+382',
      currencySymbol: '€',
    },
    {
      id: 'MS',
      iso3: 'MSR',
      name: 'Montserrat',
      flag: '🇲🇸',
      code: '+1-664',
      currencySymbol: '$',
    },
    {
      id: 'MA',
      iso3: 'MAR',
      name: 'Morocco',
      flag: '🇲🇦',
      code: '+212',
      currencySymbol: 'DH',
    },
    {
      id: 'MZ',
      iso3: 'MOZ',
      name: 'Mozambique',
      flag: '🇲🇿',
      code: '+258',
      currencySymbol: 'MT',
    },
    {
      id: 'MM',
      iso3: 'MMR',
      name: 'Myanmar',
      flag: '🇲🇲',
      code: '+95',
      currencySymbol: 'K',
    },
    {
      id: 'NA',
      iso3: 'NAM',
      name: 'Namibia',
      flag: '🇳🇦',
      code: '+264',
      currencySymbol: '$',
    },
    {
      id: 'NR',
      iso3: 'NRU',
      name: 'Nauru',
      flag: '🇳🇷',
      code: '+674',
      currencySymbol: '$',
    },
    {
      id: 'NP',
      iso3: 'NPL',
      name: 'Nepal',
      flag: '🇳🇵',
      code: '+977',
      currencySymbol: '₨',
      distanceUnit: 'km',
      weightUnit: 'kg',
      priceRoundOff: true,
      cardTimer: 44,
      dateFormat: 'DD/MM/YY',
      timeFormat: 'HH:mm:ss XM',
    },
    {
      id: 'NL',
      iso3: 'NLD',
      name: 'Netherlands',
      flag: '🇳🇱',
      code: '+31',
      currencySymbol: '€',
    },
    {
      id: 'NC',
      iso3: 'NCL',
      name: 'New Caledonia',
      flag: '🇳🇨',
      code: '+687',
      currencySymbol: '₣',
    },
    {
      id: 'NZ',
      iso3: 'NZL',
      name: 'New Zealand',
      flag: '🇳🇿',
      code: '+64',
      currencySymbol: '$',
    },
    {
      id: 'NI',
      iso3: 'NIC',
      name: 'Nicaragua',
      flag: '🇳🇮',
      code: '+505',
      currencySymbol: 'C$',
    },
    {
      id: 'NE',
      iso3: 'NER',
      name: 'Niger',
      flag: '🇳🇪',
      code: '+227',
      currencySymbol: 'CFA',
    },
    {
      id: 'NG',
      iso3: 'NGA',
      name: 'Nigeria',
      flag: '🇳🇬',
      code: '+234',
      currencySymbol: '₦',
    },
    {
      id: 'NU',
      iso3: 'NIU',
      name: 'Niue',
      flag: '🇳🇺',
      code: '+683',
      currencySymbol: '$',
    },
    {
      id: 'NF',
      iso3: 'NFK',
      name: 'Norfolk Island',
      flag: '🇳🇫',
      code: '+672',
      currencySymbol: '$',
    },
    {
      id: 'KP',
      iso3: 'PRK',
      name: 'North Korea',
      flag: '🇰🇵',
      code: '+850',
      currencySymbol: '₩',
    },
    {
      id: 'MK',
      iso3: 'MKD',
      name: 'North Macedonia',
      flag: '🇲🇰',
      code: '+389',
      currencySymbol: 'ден',
    },
    {
      id: 'MP',
      iso3: 'MNP',
      name: 'Northern Mariana Islands',
      flag: '🇲🇵',
      code: '+1-670',
      currencySymbol: '$',
    },
    {
      id: 'NO',
      iso3: 'NOR',
      name: 'Norway',
      flag: '🇳🇴',
      code: '+47',
      currencySymbol: 'kr',
    },
    {
      id: 'OM',
      iso3: 'OMN',
      name: 'Oman',
      flag: '🇴🇲',
      code: '+968',
      currencySymbol: '.ع.ر',
    },
    {
      id: 'PK',
      iso3: 'PAK',
      name: 'Pakistan',
      flag: '🇵🇰',
      code: '+92',
      currencySymbol: '₨',
    },
    {
      id: 'PW',
      iso3: 'PLW',
      name: 'Palau',
      flag: '🇵🇼',
      code: '+680',
      currencySymbol: '$',
    },
    {
      id: 'PS',
      iso3: 'PSE',
      name: 'Palestinian Territory Occupied',
      flag: '🇵🇸',
      code: '+970',
      currencySymbol: '₪',
    },
    {
      id: 'PA',
      iso3: 'PAN',
      name: 'Panama',
      flag: '🇵🇦',
      code: '+507',
      currencySymbol: 'B/.',
    },
    {
      id: 'PG',
      iso3: 'PNG',
      name: 'Papua new Guinea',
      flag: '🇵🇬',
      code: '+675',
      currencySymbol: 'K',
    },
    {
      id: 'PY',
      iso3: 'PRY',
      name: 'Paraguay',
      flag: '🇵🇾',
      code: '+595',
      currencySymbol: '₲',
    },
    {
      id: 'PE',
      iso3: 'PER',
      name: 'Peru',
      flag: '🇵🇪',
      code: '+51',
      currencySymbol: 'S/.',
    },
    {
      id: 'PH',
      iso3: 'PHL',
      name: 'Philippines',
      flag: '🇵🇭',
      code: '+63',
      currencySymbol: '₱',
    },
    {
      id: 'PN',
      iso3: 'PCN',
      name: 'Pitcairn Island',
      flag: '🇵🇳',
      code: '+870',
      currencySymbol: '$',
    },
    {
      id: 'PL',
      iso3: 'POL',
      name: 'Poland',
      flag: '🇵🇱',
      code: '+48',
      currencySymbol: 'zł',
    },
    {
      id: 'PT',
      iso3: 'PRT',
      name: 'Portugal',
      flag: '🇵🇹',
      code: '+351',
      currencySymbol: '€',
    },
    {
      id: 'PR',
      iso3: 'PRI',
      name: 'Puerto Rico',
      flag: '🇵🇷',
      code: '+1',
      currencySymbol: '$',
    },
    {
      id: 'QA',
      iso3: 'QAT',
      name: 'Qatar',
      flag: '🇶🇦',
      code: '+974',
      currencySymbol: 'ق.ر',
    },
    {
      id: 'RE',
      iso3: 'REU',
      name: 'Reunion',
      flag: '🇷🇪',
      code: '+262',
      currencySymbol: '€',
    },
    {
      id: 'RO',
      iso3: 'ROU',
      name: 'Romania',
      flag: '🇷🇴',
      code: '+40',
      currencySymbol: 'lei',
    },
    {
      id: 'RU',
      iso3: 'RUS',
      name: 'Russia',
      flag: '🇷🇺',
      code: '+7',
      currencySymbol: '₽',
    },
    {
      id: 'RW',
      iso3: 'RWA',
      name: 'Rwanda',
      flag: '🇷🇼',
      code: '+250',
      currencySymbol: 'FRw',
    },
    {
      id: 'SH',
      iso3: 'SHN',
      name: 'Saint Helena',
      flag: '🇸🇭',
      code: '+290',
      currencySymbol: '£',
    },
    {
      id: 'KN',
      iso3: 'KNA',
      name: 'Saint Kitts And Nevis',
      flag: '🇰🇳',
      code: '+1-869',
      currencySymbol: '$',
    },
    {
      id: 'LC',
      iso3: 'LCA',
      name: 'Saint Lucia',
      flag: '🇱🇨',
      code: '+1-758',
      currencySymbol: '$',
    },
    {
      id: 'PM',
      iso3: 'SPM',
      name: 'Saint Pierre and Miquelon',
      flag: '🇵🇲',
      code: '+508',
      currencySymbol: '€',
    },
    {
      id: 'VC',
      iso3: 'VCT',
      name: 'Saint Vincent And The Grenadines',
      flag: '🇻🇨',
      code: '+1-784',
      currencySymbol: '$',
    },
    {
      id: 'BL',
      iso3: 'BLM',
      name: 'Saint-Barthelemy',
      flag: '🇧🇱',
      code: '+590',
      currencySymbol: '€',
    },
    {
      id: 'MF',
      iso3: 'MAF',
      name: 'Saint-Martin (French part)',
      flag: '🇲🇫',
      code: '+590',
      currencySymbol: '€',
    },
    {
      id: 'WS',
      iso3: 'WSM',
      name: 'Samoa',
      flag: '🇼🇸',
      code: '+685',
      currencySymbol: 'SAT',
    },
    {
      id: 'SM',
      iso3: 'SMR',
      name: 'San Marino',
      flag: '🇸🇲',
      code: '+378',
      currencySymbol: '€',
    },
    {
      id: 'ST',
      iso3: 'STP',
      name: 'Sao Tome and Principe',
      flag: '🇸🇹',
      code: '+239',
      currencySymbol: 'Db',
    },
    {
      id: 'SA',
      iso3: 'SAU',
      name: 'Saudi Arabia',
      flag: '🇸🇦',
      code: '+966',
      currencySymbol: '﷼',
    },
    {
      id: 'SN',
      iso3: 'SEN',
      name: 'Senegal',
      flag: '🇸🇳',
      code: '+221',
      currencySymbol: 'CFA',
    },
    {
      id: 'RS',
      iso3: 'SRB',
      name: 'Serbia',
      flag: '🇷🇸',
      code: '+381',
      currencySymbol: 'din',
    },
    {
      id: 'SC',
      iso3: 'SYC',
      name: 'Seychelles',
      flag: '🇸🇨',
      code: '+248',
      currencySymbol: 'SRe',
    },
    {
      id: 'SL',
      iso3: 'SLE',
      name: 'Sierra Leone',
      flag: '🇸🇱',
      code: '+232',
      currencySymbol: 'Le',
    },
    {
      id: 'SG',
      iso3: 'SGP',
      name: 'Singapore',
      flag: '🇸🇬',
      code: '+65',
      currencySymbol: '$',
    },
    {
      id: 'SX',
      iso3: 'SXM',
      name: 'Sint Maarten (Dutch part)',
      flag: '🇸🇽',
      code: '+1721',
      currencySymbol: 'ƒ',
    },
    {
      id: 'SK',
      iso3: 'SVK',
      name: 'Slovakia',
      flag: '🇸🇰',
      code: '+421',
      currencySymbol: '€',
    },
    {
      id: 'SI',
      iso3: 'SVN',
      name: 'Slovenia',
      flag: '🇸🇮',
      code: '+386',
      currencySymbol: '€',
    },
    {
      id: 'SB',
      iso3: 'SLB',
      name: 'Solomon Islands',
      flag: '🇸🇧',
      code: '+677',
      currencySymbol: 'Si$',
    },
    {
      id: 'SO',
      iso3: 'SOM',
      name: 'Somalia',
      flag: '🇸🇴',
      code: '+252',
      currencySymbol: 'Sh.so.',
    },
    {
      id: 'ZA',
      iso3: 'ZAF',
      name: 'South Africa',
      flag: '🇿🇦',
      code: '+27',
      currencySymbol: 'R',
    },
    {
      id: 'GS',
      iso3: 'SGS',
      name: 'South Georgia',
      flag: '🇬🇸',
      code: '+500',
      currencySymbol: '£',
    },
    {
      id: 'KR',
      iso3: 'KOR',
      name: 'South Korea',
      flag: '🇰🇷',
      code: '+82',
      currencySymbol: '₩',
      distanceUnit: 'km',
      weightUnit: 'lb',
      priceRoundOff: false,
      cardTimer: 30,
      errandCardTimer: 40,
      dateFormat: 'MM/DD/YY',
      timeFormat: 'HH:mm:ss',
      sos: '999',
    },
    {
      id: 'SS',
      iso3: 'SSD',
      name: 'South Sudan',
      flag: '🇸🇸',
      code: '+211',
      currencySymbol: '£',
    },
    {
      id: 'ES',
      iso3: 'ESP',
      name: 'Spain',
      flag: '🇪🇸',
      code: '+34',
      currencySymbol: '€',
    },
    {
      id: 'LK',
      iso3: 'LKA',
      name: 'Sri Lanka',
      flag: '🇱🇰',
      code: '+94',
      currencySymbol: 'Rs',
    },
    {
      id: 'SD',
      iso3: 'SDN',
      name: 'Sudan',
      flag: '🇸🇩',
      code: '+249',
      currencySymbol: '.س.ج',
    },
    {
      id: 'SR',
      iso3: 'SUR',
      name: 'Suriname',
      flag: '🇸🇷',
      code: '+597',
      currencySymbol: '$',
    },
    {
      id: 'SJ',
      iso3: 'SJM',
      name: 'Svalbard And Jan Mayen Islands',
      flag: '🇸🇯',
      code: '+47',
      currencySymbol: 'kr',
    },
    {
      id: 'SZ',
      iso3: 'SWZ',
      name: 'Swaziland',
      flag: '🇸🇿',
      code: '+268',
      currencySymbol: 'E',
    },
    {
      id: 'SE',
      iso3: 'SWE',
      name: 'Sweden',
      flag: '🇸🇪',
      code: '+46',
      currencySymbol: 'kr',
    },
    {
      id: 'CH',
      iso3: 'CHE',
      name: 'Switzerland',
      flag: '🇨🇭',
      code: '+41',
      currencySymbol: 'CHf',
    },
    {
      id: 'SY',
      iso3: 'SYR',
      name: 'Syria',
      flag: '🇸🇾',
      code: '+963',
      currencySymbol: 'LS',
    },
    {
      id: 'TW',
      iso3: 'TWN',
      name: 'Taiwan',
      flag: '🇹🇼',
      code: '+886',
      currencySymbol: '$',
    },
    {
      id: 'TJ',
      iso3: 'TJK',
      name: 'Tajikistan',
      flag: '🇹🇯',
      code: '+992',
      currencySymbol: 'SM',
    },
    {
      id: 'TZ',
      iso3: 'TZA',
      name: 'Tanzania',
      flag: '🇹🇿',
      code: '+255',
      currencySymbol: 'TSh',
    },
    {
      id: 'TH',
      iso3: 'THA',
      name: 'Thailand',
      flag: '🇹🇭',
      code: '+66',
      currencySymbol: '฿',
    },
    {
      id: 'BS',
      iso3: 'BHS',
      name: 'The Bahamas',
      flag: '🇧🇸',
      code: '+1-242',
      currencySymbol: 'B$',
    },
    {
      id: 'TG',
      iso3: 'TGO',
      name: 'Togo',
      flag: '🇹🇬',
      code: '+228',
      currencySymbol: 'CFA',
    },
    {
      id: 'TK',
      iso3: 'TKL',
      name: 'Tokelau',
      flag: '🇹🇰',
      code: '+690',
      currencySymbol: '$',
    },
    {
      id: 'TO',
      iso3: 'TON',
      name: 'Tonga',
      flag: '🇹🇴',
      code: '+676',
      currencySymbol: '$',
    },
    {
      id: 'TT',
      iso3: 'TTO',
      name: 'Trinidad And Tobago',
      flag: '🇹🇹',
      code: '+1-868',
      currencySymbol: '$',
    },
    {
      id: 'TN',
      iso3: 'TUN',
      name: 'Tunisia',
      flag: '🇹🇳',
      code: '+216',
      currencySymbol: 'ت.د',
    },
    {
      id: 'TR',
      iso3: 'TUR',
      name: 'Turkey',
      flag: '🇹🇷',
      code: '+90',
      currencySymbol: '₺',
    },
    {
      id: 'TM',
      iso3: 'TKM',
      name: 'Turkmenistan',
      flag: '🇹🇲',
      code: '+993',
      currencySymbol: 'T',
    },
    {
      id: 'TC',
      iso3: 'TCA',
      name: 'Turks And Caicos Islands',
      flag: '🇹🇨',
      code: '+1-649',
      currencySymbol: '$',
    },
    {
      id: 'TV',
      iso3: 'TUV',
      name: 'Tuvalu',
      flag: '🇹🇻',
      code: '+688',
      currencySymbol: '$',
    },
    {
      id: 'UG',
      iso3: 'UGA',
      name: 'Uganda',
      flag: '🇺🇬',
      code: '+256',
      currencySymbol: 'USh',
    },
    {
      id: 'UA',
      iso3: 'UKR',
      name: 'Ukraine',
      flag: '🇺🇦',
      code: '+380',
      currencySymbol: '₴',
    },
    {
      id: 'AE',
      iso3: 'ARE',
      name: 'United Arab Emirates',
      flag: '🇦🇪',
      code: '+971',
      currencySymbol: 'إ.د',
    },
    {
      id: 'GB',
      iso3: 'GBR',
      name: 'United Kingdom',
      flag: '🇬🇧',
      code: '+44',
      currencySymbol: '£',
      distanceUnit: 'km',
      weightUnit: 'lb',
      priceRoundOff: true,
      cardTimer: 35,
      dateFormat: 'DD/MM/YY',
      timeFormat: 'HH:mm:ss',
    },
    {
      id: 'US',
      iso3: 'USA',
      name: 'United States',
      flag: '🇺🇸',
      code: '+1',
      currencySymbol: '$',
      distanceUnit: 'mi',
      weightUnit: 'lb',
      priceRoundOff: true,
      cardTimer: 50,
      dateFormat: 'd MMMM, YYYY',
      timeFormat: 'HH:mm:ss',
    },
    {
      id: 'UM',
      iso3: 'UMI',
      name: 'United States Minor Outlying Islands',
      flag: '🇺🇲',
      code: '+1',
      currencySymbol: '$',
    },
    {
      id: 'UY',
      iso3: 'URY',
      name: 'Uruguay',
      flag: '🇺🇾',
      code: '+598',
      currencySymbol: '$',
    },
    {
      id: 'UZ',
      iso3: 'UZB',
      name: 'Uzbekistan',
      flag: '🇺🇿',
      code: '+998',
      currencySymbol: 'лв',
    },
    {
      id: 'VU',
      iso3: 'VUT',
      name: 'Vanuatu',
      flag: '🇻🇺',
      code: '+678',
      currencySymbol: 'VT',
    },
    {
      id: 'VA',
      iso3: 'VAT',
      name: 'Vatican City State (Holy See)',
      flag: '🇻🇦',
      code: '+379',
      currencySymbol: '€',
    },
    {
      id: 'VE',
      iso3: 'VEN',
      name: 'Venezuela',
      flag: '🇻🇪',
      code: '+58',
      currencySymbol: 'Bs',
    },
    {
      id: 'VN',
      iso3: 'VNM',
      name: 'Vietnam',
      flag: '🇻🇳',
      code: '+84',
      currencySymbol: '₫',
    },
    {
      id: 'VG',
      iso3: 'VGB',
      name: 'Virgin Islands (British)',
      flag: '🇻🇬',
      code: '+1-284',
      currencySymbol: '$',
    },
    {
      id: 'VI',
      iso3: 'VIR',
      name: 'Virgin Islands (US)',
      flag: '🇻🇮',
      code: '+1-340',
      currencySymbol: '$',
    },
    {
      id: 'WF',
      iso3: 'WLF',
      name: 'Wallis And Futuna Islands',
      flag: '🇼🇫',
      code: '+681',
      currencySymbol: '₣',
      distanceUnit: 'km',
      weightUnit: 'kg',
      priceRoundOff: true,
      cardTimer: 23,
      errandCardTimer: 30,
      dateFormat: 'DD/MM/YY',
      timeFormat: 'HH:mm:ss',
      sos: '100',
    },
    {
      id: 'EH',
      iso3: 'ESH',
      name: 'Western Sahara',
      flag: '🇪🇭',
      code: '+212',
      currencySymbol: 'MAD',
    },
    {
      id: 'YE',
      iso3: 'YEM',
      name: 'Yemen',
      flag: '🇾🇪',
      code: '+967',
      currencySymbol: '﷼',
    },
    {
      id: 'ZM',
      iso3: 'ZMB',
      name: 'Zambia',
      flag: '🇿🇲',
      code: '+260',
      currencySymbol: 'ZK',
    },
    {
      id: 'ZW',
      iso3: 'ZWE',
      name: 'Zimbabwe',
      flag: '🇿🇼',
      code: '+263',
      currencySymbol: '$',
    },
  ];
  