import React, { ChangeEvent } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useField, ErrorMessage } from "formik";
import { CheckboxProps as MuiCheckboxProps } from "@mui/material/Checkbox";

interface CheckboxProps extends MuiCheckboxProps {
  name: string;
  label: string;
}

const CommonCheckbox: React.FC<CheckboxProps> = ({ name, label, ...rest }) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    helpers.setValue(checked);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            {...rest}
            checked={field.value}
            onChange={handleChange}
          />
        }
        label={label}
      />
      <ErrorMessage name={name} className="error" component="small" />
    </>
  );
};

export default CommonCheckbox;
