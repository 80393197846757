import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { styles } from "./styles";
import TableComponent from "../../../components/tableList";
import { TABLES } from "../../../constants/table-columns";
import { SubAdmin } from "../../../interface/sub-admin-interface";
import { UseAppDispatch } from "../../../hooks/useAppDispatch";
import { getRolesModule, roleBlockUnblock } from "../services/action";
import { useAppSelector } from "../../../hooks/useAppSelector";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import Menus from "../../../components/form-controls/menu";
import {
  GLOBAL_DATE_FILTER_DATA,
  STATUS_DATA,
} from "../../../constants/filter.data";
import { DateRangeIcon } from "@mui/x-date-pickers-pro";
import { useNavigate } from "react-router-dom";
import { ADD_ROLES, ROLES } from "../../../constants/routes/routes-path";
import ConfirmDialog from "../../../shared/dialogs/confirm-dialog";
import { Roles } from "../../../interface/role-interface ";
import "../../../common/common-table.scss";
import { ClearIcon } from "../../../constants/images";
import { SearchBar } from "../../../components/form-controls/searchBar";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
export default function RoleList() {
  const navigation = useNavigate();
  const childRef = useRef();

  const { permissionData } = useAppSelector(
    (state) => state.userPermissionInfo
  );

  function createData(
    _id: string,
    id: number | string,
    title: string,
    createdAt: string,
    isActive: boolean,
    action: string
  ) {
    return {
      _id,
      id,
      title,
      createdAt,
      isActive,
      action,
    };
  }

  const defaultDimensions = { width: "400px", height: "270px" };
  const dateDimensions = { width: "160px", height: "250px" };

  const filterData = GLOBAL_DATE_FILTER_DATA;
  const statusData = STATUS_DATA;
  const dispatch = UseAppDispatch();
  const queryParamData = {};
  const sortData = {};
  const [roles, setList] = useState<Roles[]>();
  const { data, count } = useAppSelector((state) => state.rolesModule);
  const [searchText, setSearch] = useState("");
  const [toDate, setToDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>();
  const [selectedFilter, setFilter] = useState("all");
  const [isDate, setIsDate] = useState(false);
  const [dimensions, setDimensions] = useState(defaultDimensions);
  const [isCustomMenu, setCustomMenu] = useState(false);
  const [selectedDateType, setDateType] = useState<string>();
  const [selectedUser, setSelectedUser] = useState<SubAdmin>();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let from = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
    let to = moment(new Date()).format("YYYY-MM-DD");

    let dateType = filterData.find((data) => data.key === "lastWeek");
    setDateType(dateType?.name);
    setFromDate(from);
    setToDate(to);
    fetchRoles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRefresh = () => {
    fetchRoles();
  };

  const fetchRoles = (reset: boolean = false, sort = "", sortBy = "") => {
    let obj: any = {
      limit: rowsPerPage,
      page: page,
      search: "",
      toDate: "",
      fromDate: "",
    };

    if (selectedFilter !== "all" && !reset) {
      obj["isActive"] = selectedFilter === "active" ? true : false;
    }
    if (sort && sortBy) {
      obj["sort"] = sort;
      obj["sortBy"] = sortBy;
    }

    if (searchText && searchText.trim() !== "") {
      obj["search"] = searchText.toLowerCase();
    }
    if (fromDate && toDate) {
      obj["fromDate"] = fromDate;
      obj["toDate"] = toDate;
    }
    const queryParams = {
      ...queryParamData,
      ...obj,
      ...sortData,
    };

    function objToQueryString(obj: any) {
      var k = Object.keys(obj);
      var s = "?";
      for (var i = 0; i < k.length; i++) {
        s += k[i] + "=" + obj[k[i]];
        if (i !== k.length - 1) s += "&";
      }
      return s;
    }

    const params = objToQueryString(queryParams);
    setAnchorEl(null);

    if (obj["fromDate"] !== "" && obj["toDate"] !== "") {
      dispatch(getRolesModule(params));
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [fromDate, toDate, rowsPerPage, page, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      let roles = data.map((item: any) => {
        let createdDate = moment(new Date(item?.createdAt)).format("LLL");
        let data = createData(
          item._id,
          item.id,
          item?.title,
          createdDate,
          item.isActive,
          ""
        );
        return data;
      });
      setList(roles);
    }
  }, [data]);

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsDate(false);
    setDimensions(defaultDimensions);
    setAnchorEl(event.currentTarget);
    setCustomMenu(false);
  };
  const onDate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsDate(true);
    setCustomMenu(false);
    setAnchorEl(event.currentTarget);
    setDimensions(dateDimensions);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectMenu = (menu: any, index: number) => {
    if (!isDate) {
      setFilter(menu.value);
    } else if (menu?.key === "custom") {
      const dimensions = { width: "800px", height: "700px" };
      setDimensions(dimensions);
      setCustomMenu(true);
    } else {
      let filterDateType = filterData[index]["key"];

      let fromDate = new Date();
      let toDate = new Date();
      let from = "";
      let to = "";

      // Today
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[0]["key"]) {
        from = moment(fromDate).format("YYYY-MM-DD");
        to = moment(fromDate).format("YYYY-MM-DD");
        setFromDate(from);
        setToDate(to);

        setDateType(GLOBAL_DATE_FILTER_DATA[0]["name"]);
      }
      // yesterday
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[1]["key"]) {
        from = moment(fromDate).subtract(1, "days").format("YYYY-MM-DD");
        to = moment(toDate).subtract(1, "days").format("YYYY-MM-DD");
        setDateType(GLOBAL_DATE_FILTER_DATA[1]["name"]);
        setFromDate(from);
        setToDate(to);
      }

      // last week
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[2]["key"]) {
        from = moment(fromDate).subtract(7, "days").format("YYYY-MM-DD");
        to = moment(toDate).format("YYYY-MM-DD");
        setDateType(GLOBAL_DATE_FILTER_DATA[2]["name"]);
        setFromDate(from);
        setToDate(to);
      }
      // last month
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[3]["key"]) {
        from = moment(fromDate)
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");

        to = moment(toDate)
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");

        setFromDate(from);
        setToDate(to);
        setDateType(GLOBAL_DATE_FILTER_DATA[3]["name"]);
      }

      // last year
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[4]["key"]) {
        let date = new Date();
        // first day of year
        from = moment(new Date(date.getFullYear() - 1, 0, 1)).format(
          "YYYY-MM-DD"
        );

        // last day of year
        to = moment(new Date(date.getFullYear() - 1, 11, 31)).format(
          "YYYY-MM-DD"
        );
        // from = new Date()
        setDateType(GLOBAL_DATE_FILTER_DATA[4]["name"]);
        setFromDate(from);
        setToDate(to);
      }
    }
  };

  const onReset = () => {
    setFilter("all");
    fetchRoles(true);
  };

  const onSubmit = () => {
    fetchRoles();
  };

  const onCreate = () => {
    navigation(ADD_ROLES.fullUrl);
  };

  const onApplyFilter = () => {};

  const onCancel = () => {
    setAnchorEl(null);
  };

  const setCalenderValue = (from: any, to: any) => {
    let fromDate = moment(new Date(from)).format("YYYY-MM-DD");
    let toDate = moment(new Date(to)).format("YYYY-MM-DD");
    setFromDate(fromDate);
    setToDate(toDate);

    let fromDateDisplay = moment(new Date(from)).format("ll");
    let toDateDisplay = moment(new Date(to)).format("ll");

    setDateType(fromDateDisplay + "-" + toDateDisplay);
  };

  const openDialog = () => {
    (childRef.current as any).openDialog();
  };

  const onBlockUnBlock = (confirm: boolean) => {
    if (confirm) {
      if (selectedUser) {
        let body = {
          blockUnblockReason: "",
          isActive: selectedUser.isActive ? false : true,
          id: selectedUser._id,
        };
        dispatch(roleBlockUnblock(body, "", onRefresh));
      }
    }
  };

  const actionClick = (action: any, item: any) => {
    setSelectedUser(item);
    if (action === "edit") {
      navigation("/roles/edit-roles/" + item._id);
    } else if (action === "deactivate" || action === "activate") {
      openDialog();
    }
  };

  const handleView = (id: string) => {
    navigation(`/roles/view/${id}`);
  };
  const handleRequestSort = (sortingKey: string, orderBy: string) => {
    fetchRoles(false, orderBy, sortingKey);
  };

  return (
    <div className="table-wrapper">
      <div className="heading-wrapper">
        {/* <div className="table-heading">Roles Management</div> */}
        <BreadCrumb
          showMultipleLink={false}
          mainLink={ROLES.fullUrl}
          mainLabel={"Roles Management"}
        />
        <div className="table-heading-btn">
          <Box
            sx={styles.search}
            onClick={(event: any) => {
              onDate(event);
            }}
          >
            <Box
              component="input"
              placeholder={selectedDateType}
              readOnly
              sx={styles.input}
            />
            <DateRangeIcon sx={styles.icon} />
          </Box>

          {
            //@ts-ignore
            permissionData["ROLES"]?.includes("ADD") ? (
              <button className="action-click" onClick={onCreate}>
                Create
              </button>
            ) : (
              ""
            )
          }
        </div>
      </div>
      <div className="table-content">
        <Box sx={styles.searchAndFilter}>
          <SearchBar
            setSearch={setSearch}
            searchText={searchText}
            placeholderText="Search by Role Name, Role ID"
          />

          <Box sx={styles.buttonStyle}>
            {selectedFilter !== "all" ? <Box sx={styles.dot} /> : null}
            <IconButton
              disableRipple
              id="icon-button"
              onClick={handleClickFilter}
              aria-describedby={"simple-popover"}
            >
              <TuneIcon sx={styles.iconColor} />
            </IconButton>
            <Menus
              menus={isDate ? filterData : statusData}
              value={selectedFilter}
              onClose={() => {}}
              anchorOrigin={() => {}}
              id={"simple-popover"}
              key={anchorEl ?? ""}
              anchorEl={anchorEl}
              handleClose={handleClose}
              selectMenu={selectMenu}
              onReset={onReset}
              onSubmit={onSubmit}
              onApplyFilter={onApplyFilter}
              onCancel={onCancel}
              setCalenderValue={setCalenderValue}
              label={!isDate ? "Sub Admin Status" : ""}
              showButton={!isDate ? true : false}
              isDropDown={isDate ? false : true}
              showList={isDate ? true : false}
              customMenu={isCustomMenu}
              dimensions={dimensions}
              selectedDateType={selectedDateType}
              menuWidth="500px"
            />
          </Box>
        </Box>
        {roles && (
          <TableComponent
            dataSource={roles}
            tableColumns={TABLES.ROLE}
            actionClick={actionClick}
            handleRequestSort={handleRequestSort}
            onRowsChange={(value: number) => {
              setRowsPerPage(value);
            }}
            onPageChange={(value: number) => {
              setPage(value);
            }}
            total={count}
            handleView={handleView}
            //@ts-ignore
            showEdit={permissionData["ROLES"]?.includes("EDIT")}
            //@ts-ignore
            showStatus={permissionData["ROLES"]?.includes("STATUS")}
          />
        )}
        <ConfirmDialog
          title={
            selectedUser?.isActive
              ? "Deactivate Sub Admin"
              : "Activate Sub Admin"
          }
          message={
            selectedUser?.isActive
              ? "Are you sure you want to deactivate this sub admin?"
              : "Are you sure you want to activate this sub admin?"
          }
          cancelButtonText={"Cancel"}
          confirmButtonText={"Confirm"}
          logoutUser={onBlockUnBlock}
          ref={childRef}
        />
      </div>
    </div>
  );
}
