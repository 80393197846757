
import {
  ROLES,
  SUB_ADMIN,
  NOTIFICATION,
  STATIC_CONTENT,
  ADD_SUB_ADMIN,
  EDIT_SUB_ADMIN,
  ADD_NOTIFICATION,
  EDIT_NOTIFICATION,
  ADD_ROLES,
  EDIT_ROLES,
  ADD_FAQ,
  EDIT_FAQ,
  VIEW_SUB_ADMIN,
  VIEW_ROLES,
  VIEW_FAQ,
  VIEW_NOTIFICATION,
  USER_LIST,
  USER_DETAIL,
  USER,
  ADD_USER,
  EDIT_USER
} from "../../constants/routes/routes-path";


type MenuItem = {
  text: string;
  path: string;
  module:string;
  ADD: string;
  EDIT: string;
  VIEW: string;
  VIEW_DETAIL:string;
};

type SideBar = {
  MENU_LIST: MenuItem[];
};
export const SIDE_BAR: SideBar = {
  MENU_LIST: [
    { text: "Sub Admin Management", path: SUB_ADMIN.fullUrl, module:'SUB_ADMINS' ,ADD:ADD_SUB_ADMIN.fullUrl,EDIT:EDIT_SUB_ADMIN.fullUrl,VIEW: SUB_ADMIN.fullUrl,VIEW_DETAIL:VIEW_SUB_ADMIN.fullUrl },
    { text: "Roles Management", path: ROLES.fullUrl, module:'ROLES' ,ADD:ADD_ROLES.fullUrl ,EDIT:EDIT_ROLES.fullUrl,VIEW: ROLES.fullUrl,VIEW_DETAIL:VIEW_ROLES.fullUrl },
    { text: "Static Content Management", path: STATIC_CONTENT.fullUrl, module:'STATIC_CONTENT',ADD:ADD_FAQ.fullUrl,EDIT:EDIT_FAQ.fullUrl,VIEW: STATIC_CONTENT.fullUrl,VIEW_DETAIL:VIEW_FAQ.fullUrl   },
    { text: "Notification Management", path: NOTIFICATION.fullUrl, module:'NOTIFICATION',ADD:ADD_NOTIFICATION.fullUrl ,EDIT:EDIT_NOTIFICATION.fullUrl,VIEW: NOTIFICATION.fullUrl,VIEW_DETAIL:VIEW_NOTIFICATION.fullUrl  },
    { text: "User Management", path: USER.fullUrl, module:'USER',ADD:ADD_USER.fullUrl ,EDIT:EDIT_USER.fullUrl,VIEW: USER.fullUrl,VIEW_DETAIL:USER_DETAIL.fullUrl  },
  ],
};

