import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../../common/utils";
import { setLoading } from "../../../redux/global.slice";
import {
  getApiCall,
  deleteApiCall,
  postApiCall,
} from "../../../common/constants/api";
import * as URLS from "../../../constants/url";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { notification } from "./listSlice";
import { notificationDetail } from "./detailSlice";
// import NotificationDetail from "../notification-detail";

export const getNotification = (params: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.NOTIFICATION}`,
    params,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(notification(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};

export const addNotification =
  (params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    postApiCall(
      `${URLS.NOTIFICATION}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          showAlert(1, response.data.message);
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

export const getNotificationDetail =
  (notificationId: any) => (dispatch: Dispatch<any>) => {
    const body = "";
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    getApiCall(
      `${URLS.NOTIFICATION + "/" + notificationId}`,
      body,
      (response: AxiosResponse<any>) => {
        const { statusCode, result } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(notificationDetail(result));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, dispatch(error.message));
      }
    );
  };

export const deleteUserNotification =
  (notificationId: any, params: any, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    deleteApiCall(
      `${URLS.NOTIFICATION + "/" + notificationId}`,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          showAlert(1, response?.data?.message);
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

export const sendUserNotification =
  (params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    postApiCall(
      `${URLS.NOTIFICATION_SEND}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          showAlert(1, response?.data?.message);
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// export const getSearchPermssion = () => (dispatch: Dispatch<any>) => {
//   if (!navigator.onLine) {
//     showAlert(2, "No internet connection!");
//     return;
//   }
//   dispatch(setLoading(true));
//   getApiCall(
//     `${URLS.SEARCH}`,
//     "",
//     (response: AxiosResponse<any>) => {
//       const { statusCode, result } = response.data;
//       if (statusCode === apiSuccessCode.success) {
//         dispatch(search(result));
//       }
//       dispatch(setLoading(false));
//     },
//     (error: any) => {
//       dispatch(setLoading(false));
//       showAlert(2, error.message);
//     }
//   );
// };
