import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SubAdminDetail } from "../../../common/utils/model";
export const SubAdminState: SubAdminDetail  = {
  detail: {},
};

const AdminDetailSlice = createSlice({
  name: "detail",
  initialState: SubAdminState,
  reducers: {
    getDetail: (state: SubAdminDetail, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
  },
});

export const { getDetail } = AdminDetailSlice.actions;

export default AdminDetailSlice.reducer;
