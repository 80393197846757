import { UseAppDispatch } from "../../hooks/useAppDispatch";

// import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const changePasswordHelper = () => {
  const dispatch = UseAppDispatch();
  const passwordInitialValue = {
    password: "",
    confirmPassword:""
  };

  const goBackFunction = () => {
    
  };

  return {
    passwordInitialValue,
    dispatch,
    goBackFunction,
  };
};

export default changePasswordHelper;
