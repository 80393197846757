import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import "./profile.scss";
import { useAppSelector } from "../../../hooks/useAppSelector";
import moment from "moment";
import { getSubAdminDetail } from "../services/action";
import { UseAppDispatch } from "../../../hooks/useAppDispatch";
import { ArrowForwardIosIcon } from "../../../constants/images";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
import { SUB_ADMIN } from "../../../constants/routes/routes-path";
import FormButton from "../../../components/form-controls/button/form-button";
export default function AdminDetail() {
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = UseAppDispatch();
  const { detail } = useAppSelector((state) => state.adminDetail);
  const [userDetail, setUserDetail] = useState<any>({});

  const editAdmin = () => {
    navigate(`/sub-admin/edit-user/${id}`);
  };

  useEffect(() => {
    if (detail) {
      setUserDetail(detail);
    }
  }, [detail]);

  const getSubAdminData = () => {
    dispatch(getSubAdminDetail(id));
  };

  useEffect(() => {
    getSubAdminData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Box className="detail-wrapper" sx={{ width: "100%" }}>
        <div className="detail-container">
          <BreadCrumb
            showMultipleLink={true}
            mainLink={SUB_ADMIN.fullUrl}
            mainLabel={"Sub Admin Management"}
            secondaryLabel={"Sub Admin Details"}
          />

          {/* <button type="button" onClick={editAdmin} className="action-btn">
            Edit
          </button> */}
          <FormButton
            showCancelBtn={false}
            actionBtnClick={editAdmin}
            actionBtnLabel="Edit"
          />
        </div>

        <Box
          className={"profile-detail"}
          sx={{
            boxShadow: "none",
            padding: 5,
            borderRadius: 2,
            background: "#ffffff",
          }}
        >
          <div className="sub-title">Admin Deatils</div>
          <Divider className="role-divider" sx={{ margin: "20px 0px" }} />
          <Grid container spacing={4}>
            <Grid xs={3} sm={3} item>
              <Avatar
                alt="Avatar"
                src={userDetail?.avatar || ""}
                className=""
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  backgroundColor: "#199ab0",
                  color: "#ffffff",
                }}
              />
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Admin ID</Typography>
              <p>{userDetail?.id}</p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Name</Typography>
              <p>
                <span>{userDetail?.name?.first}</span>
                <span> {userDetail?.name?.last}</span>
              </p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Phone Number</Typography>
              <p>
                {userDetail?.phoneNumber
                  ? `${userDetail?.countryCode} ${" "}${
                      userDetail?.phoneNumber
                    }`
                  : "N/A"}
              </p>
            </Grid>
            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Email ID</Typography>
              <p>{userDetail?.email}</p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Created On</Typography>
              <p>{moment(new Date(userDetail?.createdAt)).format("LL")}</p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Role & Access</Typography>
              <p>{userDetail?.role?.title || "N/A"}</p>
            </Grid>
            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Last login</Typography>
              <p>
                {userDetail?.lastLogin
                  ? moment(new Date(userDetail?.lastLogin)).format("LL")
                  : "N/A"}
              </p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Created By</Typography>
              <p>
                <span>{userDetail?.createdBy?.name?.first}</span>
                <span> {userDetail?.createdBy?.name?.last}</span>
              </p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Status</Typography>
              <p>{userDetail?.isActive ? "Activated" : "Deactivated"}</p>
            </Grid>
            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">
                Authentication Status
              </Typography>
              <p>{userDetail?.isMFASetup ? "Configured" : "Not Configured"}</p>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
