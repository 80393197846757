import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RoleResult, SubAdminResult } from "../../../common/utils/model";

export const adminInitialState: SubAdminResult = {
  data: [],
  page: 0,
  limit: 0,
  count: 0,
};
export const roleInitialState: RoleResult = {
  data: [],
  page: 0,
  limit: 0,
  count: 0,
};

const SubAdminModuleSlice = createSlice({
  name: "admins",
  initialState: adminInitialState,
  reducers: {
    allAdmins: (state: SubAdminResult, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.count = action.payload.total;
    },
  },
});

// const RoleSlice = createSlice({
//   name: "roles",
//   initialState: roleInitialState,
//   reducers: {
//     allRoles: (state: RoleResult, action: PayloadAction<any>) => {
//       state.data = action.payload;
//     },
//   },
// });

// const rootReduce = combineReducers({
//   admins: SubAdminModuleSlice.reducer,
//   roles: RoleSlice.reducer,
// });

export const { allAdmins } = SubAdminModuleSlice.actions;
// export const { allRoles } = RoleSlice.actions;

export default SubAdminModuleSlice.reducer;
