import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import moment from "moment";
import {
  getNotificationDetail,
  sendUserNotification,
} from "../services/action";
import { UseAppDispatch } from "../../../hooks/useAppDispatch";
import Plateform from "../../../components/plateform";
import { ArrowForwardIosIcon } from "../../../constants/images";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
import { NOTIFICATION } from "../../../constants/routes/routes-path";
export default function NotificationDetail() {
  const { detail } = useAppSelector((state) => state.notificationDetails);
  const [notification, setNotification] = useState<any>({});
  const { id } = useParams();
  const dispatch = UseAppDispatch();

  useEffect(() => {
    dispatch(getNotificationDetail(id));
  }, [dispatch, id]);

  useEffect(() => {
    setNotification(detail);
  }, [detail]);

  const sendNotification = (notification: any) => {
    let sendPayload = {
      id: id,
    };
    dispatch(sendUserNotification(sendPayload, ""));
  };
  return (
    <>
      <Box className="detail-wrapper" sx={{ width: "100%" }}>
        <div className="detail-container">
          <BreadCrumb
            mainLink={NOTIFICATION.fullUrl}
            mainLabel={"Notification Management"}
            secondaryLabel={"Notification Details"}
            showMultipleLink={true}
          />

          <button
            type="button"
            onClick={sendNotification}
            className="action-btn"
          >
            Send
          </button>
        </div>
        <Divider className="role-divider" />

        <Box
          sx={{
            boxShadow: "none",
            padding: 5,
            borderRadius: 2,
            background: "#ffffff",
          }}
        >
          <Grid container spacing={4}>
            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Notification ID</Typography>
              <p>{notification?.id}</p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">URL</Typography>
              <p>{notification?.url || "N/A"}</p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Platform</Typography>
              <Plateform plateform={notification?.platform} />
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Created On</Typography>
              <p>{moment(new Date(notification?.createdAt)).format("LL")}</p>
            </Grid>
            <Grid xs={3} sm={12} item>
              <Typography className="detail-title">Created By</Typography>
              <p>
                {notification?.createdBy?.name}{" "}
                {notification?.createdBy?.adminId
                  ? `(${notification?.createdBy?.adminId})`
                  : null}
              </p>
            </Grid>

            <Grid xs={3} sm={12} item>
              <Typography className="detail-title">Title</Typography>
              <p>{notification?.title}</p>
            </Grid>

            <Grid xs={3} sm={12} item>
              <Typography className="detail-title">Description</Typography>
              <p>{notification?.content}</p>
            </Grid>
          </Grid>
          {/* <ConfirmPopUp setShowModal={setShowModal} showModal={showModal} /> */}
        </Box>
      </Box>
    </>
  );
}
