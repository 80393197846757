import { useEffect, useState } from "react";
import { PLATEFORM } from "../../enums/plateform-type.enum";

const Plateform = (props: any) => {
  const { plateform } = props;
  const [recipient, setRecipient] = useState<any>("");

  useEffect(() => {
    if (plateform) {
      const allowPlateform = Object.keys(plateform)
        .filter((key) => plateform[key])
        .map((key) => PLATEFORM[key]);

      if (allowPlateform.length > 0) {
        setRecipient(allowPlateform.join(", "));
      }
    }
  }, [plateform]);

  return <p>{recipient}</p>;
};

export default Plateform;
