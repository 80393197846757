import { UseAppDispatch } from "../../hooks/useAppDispatch";

const contentHelper = () => {
  const dispatch = UseAppDispatch();
  const initialValue = {
    title: "",
    description: "",
  };

  return {
    initialValue,
    dispatch,
  };
};

export default contentHelper;
