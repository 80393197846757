import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ForgotModel } from "../../../../common/utils";

const initialState: ForgotModel = {
  email: "",
};

const forgetSlice = createSlice({
  name: "forgot",
  initialState,
  reducers: {
    setForgetDetails: (state: ForgotModel, action: PayloadAction<ForgotModel>) => {
      state.email = action.payload.email
    },
  },
});

// Action creators are generated for each case reducer function
export const { setForgetDetails } = forgetSlice.actions;

export default forgetSlice.reducer;
