import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../../../common/utils";
import { setLoading } from "../../../../redux/global.slice";
import { apiSuccessCode } from "../../../../common/constants/axios.instance";
import { postApiCall } from "../../../../common/constants/api";
import * as URLS from "../../../../constants/url";
import { toast } from "react-toastify";
import { POPUP_MESSAGES } from "../../../../constants/messages";

export const onForgot =
  (values: any, setSubmitting: any, navigate: any) =>
  (dispatch: Dispatch<any>) => {
    const { email,} = values;
    const dataToSend = {
      email,
    };
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    postApiCall(
      `${URLS.FORGOT_PASSWORD}`,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;

        if (data?.statusCode === apiSuccessCode.success) {
          dispatch(setLoading(false));
          setSubmitting(false);
          toast.success(POPUP_MESSAGES.passwordResetLink, {
            theme: "colored",
          });
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error.message);
      }
    );
  };
