import { useEffect, useState } from "react";
import "./google-authenticator-scan.scss";
import { getQRUrl } from "../../services/storage/storage-service";
import { useNavigate } from "react-router-dom";
import { GOOGLE_VERIFY_CODE, LOGIN } from "../../constants/routes/routes-path";
import { AuthImg } from "../../constants/images";

export default function GoogleAuthenticatorScan() {
  const [qrCode,] = useState(getQRUrl());
  const navigate = useNavigate();

  useEffect(() => {}, [qrCode]);

  const onBack = () => {
    navigate(LOGIN.fullUrl);
  };
  const onNext = () => {
    navigate(GOOGLE_VERIFY_CODE.fullUrl);
  };
  return (
    <div className="scanner-wrapper">
      <div className="scanner-container">
        <img alt="logo" className="logo" src="../../../images/logo.svg" />
        <h4>Google App Authentication</h4>
        <p>Secure your account with TOTP two-factor authentication</p>
        <div className="scanner-dis">
          <span>Setup</span>
          <img alt="auth" src={AuthImg} />
          <span>Google Authenticator</span>
        </div>
        <div className="scanner-box">
          <p className="scanner-text">
            Launch Google Authenticator, tab on '+' icon then select "Scan
            Barcode"
          </p>
          {qrCode ? (
            <img
              src={qrCode}
              style={{ width: "25%", height: "100%" }}
              alt="QR Code"
            />
          ) : null}
        </div>
        <div className="button-wrapper">
          <button className="action-btn" onClick={onNext}>
            Next
          </button>
          <button className="link-btn" onClick={onBack}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
