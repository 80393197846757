import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { userDetail } from "../../../common/utils/model";

export const userDetailState: userDetail = {
  detail: {},
};

const UserDetailSlice = createSlice({
  name: "userInfo",
  initialState: userDetailState,
  reducers: {
    userInfo: (userDetailState: userDetail, action: PayloadAction<any>) => {
      userDetailState.detail = action.payload;
    },
  },
});

export const { userInfo } = UserDetailSlice.actions;
export default UserDetailSlice.reducer;
