import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../utils";
import { setLoading, setToken } from "../../../redux/global.slice";
import { deleteApiCall } from "../../constants/api";
import * as URLS from "../../../constants/url";
import { clearStorage } from "../../../services/storage/storage-service";
import { LOGIN } from "../../../constants/routes/routes-path";

export const onLogout = (navigate: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No/sub-admin internet connection!");
    return;
  }
  dispatch(setLoading(true));
  deleteApiCall(
    `${URLS.LOGOUT}`,
    (respData: AxiosResponse<any>) => {
      clearStorage();

      dispatch(setToken(""));
      dispatch(setLoading(false));
      navigate(LOGIN.fullUrl);
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};
