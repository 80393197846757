import { useEffect, useState } from "react";
import Editor from "../../../components/form-controls/editor/editor";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { CONTENT_TYPE } from "../../../constants/constants";
import "../style.scss";
import contentHelper from "../helper";
import { useNavigate } from "react-router-dom";
import {updateTermsCondition } from "../services/action";
export default function TermAndCondittion() {
  const navigation = useNavigate();
  const [value, setValue] = useState<any>("");
  const { detail } = useAppSelector((state) => state.contentDetail);
  const { dispatch } = contentHelper();
  useEffect(() => {
    if (detail) {
      updateInitialValue();
    }
  }, [detail]);

  const updateInitialValue = () => {
    console.log("detail", detail);
    detail.forEach((item: any) => {
      if (item?.id === CONTENT_TYPE.TERMS_AND_CONDITION) {
        setValue(item?.content);
      }
    });
  };

  const onBack = () => {
    navigation("/static-content?tab=1");
  };
  const updateContent = () => {
    let aboutusPayload = {
      title: CONTENT_TYPE.TERMS_AND_CONDITION,
      content: value,
    };
    dispatch(updateTermsCondition(aboutusPayload, onBack));
  };
  return (
    <>
      <Editor value={value} setValue={setValue} />
      <div className="about-action-btn">
        <button
          className="action-btn"
          disabled={!value.length}
          onClick={updateContent}
        >
          Submit
        </button>
      </div>
    </>
  );
}
