import {createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FaqResult } from "../../../common/utils/model";
export const faqInitialState: FaqResult = {
  documentList: [],
  totalList:0
};

const FaqModuleSlice = createSlice({
  name: "roleModule",
  initialState: faqInitialState,
  reducers: {
    FaqModule: (state: FaqResult, action: PayloadAction<any>) => {
      state.documentList = action.payload.documentList;
      state.totalList = action.payload.totalList;
    },
  },
});

export const { FaqModule } = FaqModuleSlice.actions;

export default FaqModuleSlice.reducer;
