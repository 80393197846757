import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserPermissions } from "../../../common/utils/model";

export const userPermissionState: UserPermissions = {
  permissionData: [],
};

const UserPermissionSlice = createSlice({
  name: "userPermissionInfo",
  initialState: userPermissionState,
  reducers: {
    userPermissionInfo: (userPermissionState: UserPermissions, action: PayloadAction<any>) => {
      userPermissionState.permissionData = action.payload;
    },
  },
});

export const { userPermissionInfo } = UserPermissionSlice.actions;
export default UserPermissionSlice.reducer;
