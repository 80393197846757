import {
  UPDATE_PASSWORD,
  ADMIN_PROFILE,
} from "../../constants/routes/routes-path";
import { PROFILE_ACTION } from "../../enums/profile-actions.enum";

export type ProfileMenu = {
  title: string;
  path: string;
  titleCode: string;
};

export type ProfileMenuList = ProfileMenu[];
export const PROFILE_MENU: ProfileMenuList = [
  {
    title: "View Profile",
    path: ADMIN_PROFILE.fullUrl,
    titleCode: PROFILE_ACTION.PROFILE,
  },
  {
    title: "Change Password",
    path: UPDATE_PASSWORD.fullUrl,
    titleCode: PROFILE_ACTION.CHANGE_PASSWORD,
  },
  {
    title: "Logout",
    path: "/staticcontent",
    titleCode: PROFILE_ACTION.LOGOUT,
  },
];
