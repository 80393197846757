import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ADMIN_PROFILE, EDIT_PROFILE } from "../../../constants/routes/routes-path";
import { useState, useEffect } from "react";
import "./profile.scss";
import ConfirmPopUp from "./confirm-pop-up";
import { useAppSelector } from "../../../hooks/useAppSelector";
import moment from "moment";
import { ArrowForwardIosIcon } from "../../../constants/images";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
export default function AdminProfile({ edit=false}:any) {
  const navigate = useNavigate();
  const { detail } = useAppSelector((state) => state.userInfo);
   const [userDetail, setUserDetail] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const editProfile = () => {
    navigate(EDIT_PROFILE.fullUrl);
  };

    useEffect(() => {
      if (detail) {
        setUserDetail(detail);
      }
    }, [detail]);
  return (
    <>
      <Box className="detail-wrapper" sx={{ width: "100%" }}>
        <div className="detail-container">
          <BreadCrumb
            mainLink={ADMIN_PROFILE.fullUrl}
            mainLabel={"Admin Profile"}
            showMultipleLink={false}
          />

          <button type="button" onClick={editProfile} className="action-btn">
            Edit Profile
          </button>
        </div>
        <Divider className="role-divider" />

        <Box
          className={"profile-detail"}
          sx={{
            boxShadow: "none",
            padding: 5,
            borderRadius: 2,
            background: "#ffffff",
          }}
        >
          <Grid container spacing={4}>
            <Grid xs={3} sm={3} item>
              <Avatar
                alt="Avatar"
                src={userDetail?.avatar || ""}
                className=""
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  backgroundColor: "#199ab0",
                  color: "#ffffff",
                }}
              />
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Admin ID</Typography>
              <p >{userDetail?.id}</p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Name</Typography>
              <p>
                <span>{userDetail?.name?.first}</span>
                <span> {userDetail?.name?.last}</span>
              </p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Phone Number</Typography>
              <p>
                {userDetail?.countryCode} {userDetail?.phoneNumber}
              </p>
            </Grid>
            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Email ID</Typography>
              <p>{userDetail?.email}</p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">
                Authentication Status
              </Typography>
              <div className="reset">
                <span>Configured</span>
                <span
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Reset
                </span>
              </div>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">Created On</Typography>
              <p>{moment(new Date(userDetail?.createdAt)).format("LL")}</p>
            </Grid>

            <Grid xs={3} sm={3} item>
              <Typography className="detail-title">
                Role & Permission
              </Typography>
              <p>{userDetail?.role?.title}</p>
            </Grid>
          </Grid>
          <ConfirmPopUp setShowModal={setShowModal} showModal={showModal} />
        </Box>
      </Box>
    </>
  );
}
