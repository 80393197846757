import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../../common/utils";
import { setLoading, setToken } from "../../../redux/global.slice";
import {
  patchApiCall,
} from "../../../common/constants/api";
import * as URLS from "../../../constants/url";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { clearStorage } from "../../../services/storage/storage-service";
import { LOGIN } from "../../../constants/routes/routes-path";


export const updatePassword =
  (params: any, navigation: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    patchApiCall(
      `${URLS.RESET_PASSWORD}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          clearStorage();

          dispatch(setToken(""));
          dispatch(setLoading(false));
          navigation(LOGIN.fullUrl);
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };




