import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useAppSelector } from "../../hooks/useAppSelector";
import { SIDE_BAR } from "./helper";
const drawerWidth = 300;

export default function SideBarMenu() {
  const location = useLocation();
  const { permissionData } = useAppSelector((state) => state.userPermissionInfo);


//@ts-ignore
  const filteredKeys = Object.keys(permissionData).filter((key:any) => permissionData[key]?.length > 0);

  //@ts-ignore
 const result:[]= SIDE_BAR.MENU_LIST.filter(item => filteredKeys.includes(item.module));

 console.log(result,"result")
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {result.map((item:any, index:any) => {
            return (
              <ListItem style={{color:"#767676"}} key={item.text} disablePadding>
                <ListItemButton
                  component={NavLink}
                  to={item.path}
                  selected={location.pathname.includes(item.path)}
                >
                  <ListItemIcon>
                    {index === 0 ? (
                      <PersonAddIcon
                        style={
                          location.pathname.includes(item.path)
                            ? { color: "#1ca2b7" }
                            : {}
                        }
                      />
                    ) : index === 1 ? (
                      <ManageAccountsIcon
                        style={
                          location.pathname.includes(item.path)
                            ? { color: "#1ca2b7" }
                            : {}
                        }
                      />
                    ) : index === 2 ? (
                      <MailIcon
                        style={
                          location.pathname.includes(item.path)
                            ? { color: "#1ca2b7" }
                            : {}
                        }
                      />
                    ) : index === 3 ? (
                      <NotificationsIcon
                        style={
                          location.pathname.includes(item.path)
                            ? { color: "#1ca2b7" }
                            : {}
                        }
                      />
                    ) : (
                      <InboxIcon
                        style={
                          location.pathname.includes(item.path)
                            ? { color: "#1ca2b7" }
                            : {}
                        }
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    style={
                      location.pathname.includes(item.path)
                        ? { color: "#1ca2b7" }
                        : {}
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
}
