import axios, {AxiosInstance } from "axios";
import {
  clearStorage,
  get2FAToken,
  getAccessToken,
  getAuthBase64Key,
  getRefreshToken,
  setAccessToken,
} from "../services/storage/storage-service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ACCESS_DENIED, LOGIN } from "../constants/routes/routes-path";
import { Language, Platform } from "../enums/login.enum";
import { getCurrentTimezone } from "../services/utility/utility-service";
import { REFRESH } from "../constants/url";

const REFRESH_TOKEN_URL = REFRESH;

const authFetch: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
authFetch.interceptors.request.use(
  (request: any) => {
    const authToken = getAccessToken();
    const authKey = getAuthBase64Key();

    const mfaToken = get2FAToken();
    const username: string = "RCC_USR";
    const password: string = "RCC_PWD";

    const headers: {
      [name: string]: string | string[];
    } = {};
    if (!request.headers.has("Authorization")) {
      headers["Authorization"] = "Basic " + btoa(username + ":" + password);
      // If mfaToken is available then bind mfaToken in 'Authorization'
      if (mfaToken) {
        headers["Authorization"] = `Bearer ${mfaToken}`;
      }

      // If authToken is available then bind authToken in 'Authorization'
      if (authToken) {
        headers["Authorization"] = `Bearer ${authToken}`;
      }
    }

    headers["Accept-Language"] = Language.En;
    headers["Timezone"] = getCurrentTimezone();
    headers["Platform"] = Platform.Web;

    if (authKey) {
      request.headers["authorization"] = `Basic ${btoa(authKey)}`;
    }
    if (mfaToken) {
      request.headers["authorization"] = `Bearer ${mfaToken}`;
      // headers["Authorization"] = `Bearer ${mfaToken}`;
    }
    if (authToken) {
      // request.headers["Access-Token"] = authToken;
      request.headers["authorization"] = `Bearer ${authToken}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshQueue: ((token: string) => void)[] = [];

authFetch.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    let newToken: any;
    if (error.response && error.response?.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        // Call your refresh token API to get a new token
        const refreshToken = getRefreshToken();
        newToken = await callRefreshTokenAPI(refreshToken);

        if (newToken) {
          // Update the local storage with the new token
          setAccessToken(newToken);
          // Retry the queued requests with the new token
          refreshQueue.forEach((resolve) => resolve(newToken));
          refreshQueue = [];
          // Continue the original request
          return authFetch(error.config);
        } else {
          const navigate = useNavigate();
          navigate(LOGIN.fullUrl);
          // Handle token refresh failure, e.g., log out the user
        }
      } else {
        // If a token refresh is already in progress, queue the original request
        return new Promise((resolve) => {
          refreshQueue.push((token) => {
            // originalRequest.headers['Authorization'] = `Bearer ${token}`;
            // resolve(authFetch(originalRequest));
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            resolve(authFetch(originalRequest));
          });
        });
      }
    }
    if (
      error.response?.status === 401 ||
      error.response?.data === "Unauthorized" ||
      error.response?.data === "Blocked"
    ) {
      clearStorage();
      toast.error((error.response?.data as any).message, {
        theme: "colored",
      });

      const navigate = useNavigate();
      navigate(LOGIN.fullUrl);
    } else if (
      error.response?.status === 410 ||
      (error.response?.data as any).responseType === "GONE"
    ) {
    } else if (error.response?.status === 403) {
      const navigate = useNavigate();
      navigate(ACCESS_DENIED.fullUrl);
    } else {
      toast.error((error.response?.data as any).message, {
        theme: "colored",
      });
    }
    return Promise.reject(error);
  }
);

// Function to call the refresh token API
async function callRefreshTokenAPI(
  refreshToken: string | null
): Promise<string | null> {
  try {
    const response = await axios.post(REFRESH_TOKEN_URL, { refreshToken });
    return response.data.accessToken;
  } catch (error) {
    // Handle token refresh error, e.g., log out the user
    return null;
  }
}
export default authFetch;
