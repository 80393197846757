import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../../constants/routes/routes-path";
import "./forgot-password.scss";
import forgetHelper from "./helper";
import { Form, Formik } from "formik";
import { onForgot } from "./services/action";
import Schema from "../../../common/constants/schema";
import { Stack } from "@mui/material";
import InputTextField from "../../../components/form-controls/text";
import ButtonControl from "../../../components/form-controls/button/basic-button";

export interface IForgotPasswordLoginForm {
  email: string;
}

export default function ForgotPassword() {
  const { forgotInitialValue, dispatch } = forgetHelper();
  const navigation = useNavigate();
  const handleBack = () => {
    navigation(LOGIN.fullUrl);
  };

  return (
    <>
      <div className="forgot-password-form">
        {/* <Card> */}
        <Card
          variant="outlined"
          sx={{
            minWidth: "500px",
            maxWidth: "500px",
            border: "none",
            background: "transparent",
          }}
        >
          <Typography
            variant="h5"
            color="textSecondary"
            mb={1}
            style={{ textAlign: "start", marginBottom: "15px" }}
          >
            Forgot Password?
          </Typography>

          <Formik
            initialValues={forgotInitialValue}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(onForgot(values, setSubmitting, navigation));
            }}
            validationSchema={Schema.ForgotSchema}
          >
            {({ isValid }) => (
              <Form>
                <Stack spacing={2}>
                  <InputTextField
                    name="email"
                    label="Email Address"
                    type="email"
                  />

                  <button
                    type="submit"
                    disabled={!isValid}
                    className="action-btn"
                  >
                    Send Password Reset Link
                  </button>

                  <button onClick={handleBack} className="link-btn">
                    Back to Login
                  </button>
                </Stack>
              </Form>
            )}
          </Formik>
          {/* </CardContent> */}
        </Card>
        {/* </Card> */}
      </div>
    </>
  );
}
