import { setForgetDetails } from "./services/forgot.slice";
import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const forgetHelper = () => {
  const dispatch = UseAppDispatch();
  const forgotInitialValue = {
    email: "",
  };

  const goBackFunction = () => {
    dispatch(
      setForgetDetails({
        email:""
      })
    );
  };

  return {
    forgotInitialValue,
    dispatch,
    goBackFunction,
  };
};

export default forgetHelper;
