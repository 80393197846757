import React, { useState } from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { PasswordFieldProps } from "./type";
import { InputLabel } from "@mui/material";
import "./text.scss";
import { PATTERN } from "../../../constants/patterns";
const PasswordField: React.FC<any> = ({
  showlabel = true,
  label,
  required = true,
  otp = false,
  handleChange=()=>{},
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isError = Boolean(meta.touched && meta.error);

  return (
    <>
      {showlabel && (
        <InputLabel className="input-label">
          {label}
          {required ? "*" : null}
        </InputLabel>
      )}
      <TextField
        {...field}
        type={showPassword ? "text" : "password"}
        error={isError}
        style={{ width: "100%", marginTop: 5 }}
        helperText={meta.touched && meta.error}
        label={null}
        placeholder={label}
        onChange={(e) => {
          handleChange(e)
          if (otp) {
            if (PATTERN.otp.test(e.target.value) || !e.target.value) {
              setValue(e.target.value);
            }
          } else {
             setValue(e.target.value);
          }
         
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={toggleShowPassword} edge="end">
              {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          ),
          style: { borderRadius: "10px" },
        }}
      />
    </>
  );
};

export default PasswordField;
