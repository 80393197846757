import { Box } from "@mui/material";
import { styles } from "./styles";
import { ClearIcon } from "../../../constants/images";
import { Search as SearchIcon } from "@mui/icons-material";
import { ChangeEvent } from "react";
export const SearchBar: React.FC<any> = ({
  searchText = "",
  setSearch = () => {},
  placeholderText = "",
}) => {
  return (
    <>
      <Box sx={styles.search}>
        <Box
          component="input"
          placeholder={placeholderText}
          value={searchText}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => {
            setSearch(evt.target.value);
          }}
          sx={styles.input}
        />
        <SearchIcon sx={styles.icon} />
        {searchText?.length ? (
          <ClearIcon
            onClick={() => {
              setSearch("");
            }}
            sx={styles.icon}
          />
        ) : null}
      </Box>
    </>
  );
};
