import { Outlet, Navigate, useParams } from "react-router-dom";
import {
  LOGIN,
  ADMIN_PROFILE,
  CHANGE_PASSWORD,
  EDIT_PROFILE,
  UPDATE_PASSWORD,
} from "../constants/routes/routes-path";
import { useAppSelector } from "../hooks/useAppSelector";
import { getAccessToken } from "../services/storage/storage-service";
import { SIDE_BAR } from "../common/sidebar/helper";
import { useLocation } from "react-router-dom";
import PermissionNotFound from "../features/permission-not-found";
import PageContainer from "../common/headerWrapper";

const PrivateRoutes = () => {
  let token = getAccessToken();
  const location = useLocation();

  const { id } = useParams();

  const { permissionData } = useAppSelector(
    (state) => state.userPermissionInfo
  );

  const filteredKeys = Object.keys(permissionData).filter(
    //@ts-ignore
    (key) => permissionData[key]?.length > 0
  );

  let result = SIDE_BAR.MENU_LIST.filter((item) =>
    filteredKeys.includes(item.module)
  );

  result = result.map((item) => {
    //@ts-ignore
    const permissions = permissionData[item.module];
    const filteredItem: any = { module: item.module };
    Object.keys(item).forEach((key) => {
      if (permissions.includes(key)) {
        if (item.module === "SUB_ADMINS") {
          //@ts-ignore
          filteredItem["VIEW_DETAIL"] = item["VIEW_DETAIL"].replace(":id", id);
        }
        if (item.module === "ROLES") {
          //@ts-ignore
          filteredItem["VIEW_DETAIL"] = item["VIEW_DETAIL"].replace(":id", id);
        }
        if (item.module === "STATIC_CONTENT") {
          //@ts-ignore
          filteredItem["VIEW_DETAIL"] = item["VIEW_DETAIL"].replace(":id", id);
        }
        if (item.module === "NOTIFICATION") {
          //@ts-ignore
          filteredItem["VIEW_DETAIL"] = item["VIEW_DETAIL"].replace(":id", id);
        }
        //@ts-ignore
        if (typeof item[key] === "string" && item[key]?.includes(":")) {
          //@ts-ignore
          filteredItem[key] = item[key].replace(":id", id);
        } else {
          //@ts-ignore
          filteredItem[key] = item[key];
        }
      }
    });
    return filteredItem;
  });

  console.log(result, "result");

  // Define routes for filtering
  const routesToInclude = ["ADD", "EDIT", "VIEW", "VIEW_DETAIL"];

  // Extract routes from the result
  let filteredRoutes = result.flatMap((item) =>
    //@ts-ignore
    routesToInclude.map((route) => item[route])
  );

  // Add additional routes
  const additionalRoutes = [
    ADMIN_PROFILE.fullUrl,
    CHANGE_PASSWORD.fullUrl,
    EDIT_PROFILE.fullUrl,
    UPDATE_PASSWORD.fullUrl,
  ];
  filteredRoutes.push(...additionalRoutes);


  console.log(filteredRoutes,"filteredRoutes")

  if (permissionData.length === 0) {
    return token ? <Outlet /> : <Navigate to={LOGIN.fullUrl} />;
  } else if (!filteredRoutes.includes(location.pathname)) {
    return <PageContainer children={<PermissionNotFound />} />;
  } else {
    return token ? <Outlet /> : <Navigate to={LOGIN.fullUrl} />;
  }

  // return token ? <Outlet /> : <Navigate to={LOGIN.fullUrl} />;
};

export default PrivateRoutes;
