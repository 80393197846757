import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ADMIN_PROFILE } from "../../../constants/routes/routes-path";
import {
  ArrowForwardIosIcon,
  InfoIcon,
  uploadIcon,
} from "../../../constants/images";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import CommonSelect from "../../../components/form-controls/select";
import InputTextField from "../../../components/form-controls/text";
import { COLORS } from "../../../common/constants/colors";
import { Form, Formik } from "formik";
import { ChangeEvent, useState, useEffect } from "react";
import profileHelper from "./helper";
import { useAppSelector } from "../../../hooks/useAppSelector";
import Schema from "../../../common/constants/schema";
import { COUNTRIES } from "../../../common/constants/countries";
import { getRoles } from "../../sub-admin/services/action";
import { directUpdateProfile, updateProfile } from "../services/action";
import { IMAGE_EXT, IMAGE_FOLDER } from "../../../enums/uplaod_image.enum";
import { showAlert } from "../../../common/utils";
import "cropperjs/dist/cropper.css";
import ImageCropper from "./image-cropper";
import { Delete } from "@mui/icons-material";
import "./profile.scss";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
import FormButton from "../../../components/form-controls/button/form-button";
export default function EditAdminProfile() {
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState<any>();
  const { detail } = useAppSelector((state) => state.userInfo);
  const { data } = useAppSelector((state) => state.roles);
  const { initialValue, dispatch } = profileHelper(true, detail);
  const [FormInitialValue, setFormInitialValue] = useState<any>(initialValue);
  const [countryList, setCountryList] = useState<any>();
  const [roles, setRoles] = useState<any>();
  const [file, setFile] = useState<any>();
  const [extension, setExtension] = useState<any>("");
  const [chooseImage, setChooseImage] = useState<boolean>(false);
  const [cropImage, setCropImage] = useState<any>();
  const [initialImage, setInitialImage] = useState<any>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const updateInitialValue = () => {
    setFormInitialValue((prev: any) => ({
      ...prev,
      email: detail?.email || "",
      firstName: detail?.name?.first || "",
      lastName: detail?.name?.last || "",
      phone: detail?.phoneNumber || "",
      role: detail?.role?._id || "",
      countryCode: detail?.countryCode,
    }));
    if (detail?.avatar) {
      let ImagePath = detail?.avatar.split("?")?.[0].split("Accelerator")?.[1];
      setInitialImage(ImagePath || uploadIcon);
      setSelectedImage(detail?.avatar);
    }
  };

  useEffect(() => {
    dispatch(getRoles());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let countries = COUNTRIES.map((country) => {
      return {
        value: country.code,
        key: country.code + " " + country.name + " " + country.flag,
      };
    });
    setCountryList(countries);

    const rolelist = data.map((item) => {
      return {
        value: item._id,
        key: item.title,
      };
    });
    setRoles(rolelist);
  }, [data]);

  useEffect(() => {
    if (detail && roles?.length) {
      updateInitialValue();
    }
  }, [detail, roles]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChooseImage(true);
    const file: any = event.target.files?.[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (IMAGE_EXT.includes(fileExtension)) {
      setExtension(fileExtension);
      setFile(file);
      if (file) {
        setShowModal(true);
        setCropImage(URL.createObjectURL(file));
      }
    } else {
      showAlert(2, "Please upload image in given formates");
    }
  };
  const backToProfile = () => {
    navigate(ADMIN_PROFILE.fullUrl);
  };

  const onSubmit = async (values: any) => {
    let profilePayload: any = {
      avatar: initialImage || "",
      name: { first: values?.firstName, last: values?.lastName },
      phoneNumber: values?.phone || "",
      countryCode: values?.countryCode,
    };
    let imagePayload: any = {
      action: IMAGE_FOLDER.ADMIN,
      extension: extension,
    };
    if (chooseImage) {
      dispatch(
        updateProfile(profilePayload, imagePayload, file, backToProfile)
      );
    } else {
      dispatch(directUpdateProfile(profilePayload, backToProfile));
    }
  };
  const deleteImage = () => {
    setFile("");
    setSelectedImage("");
  };

  return (
    <>
      <Box className="detail-wrapper" sx={{ width: "100%" }}>
        <div className="detail-container">
          <BreadCrumb
            mainLink={ADMIN_PROFILE.fullUrl}
            mainLabel={"Admin Profile"}
            secondaryLabel={"Edit Profile"}
            showMultipleLink={true}
          />
          <button type="button" onClick={backToProfile} className="action-btn">
            Back
          </button>
        </div>
      </Box>
      <Box
        sx={{
          display: "grid",
          boxShadow: "none",
          padding: 5,
          borderRadius: 2,
          background: "#ffffff",
        }}
        className="edit-profile-wrapper"
      >
        <div className="sub-title"> Admin Profile Details</div>
        <Divider className="role-divider" sx={{ margin: "20px 0px" }} />
        <hr />
        <div className="upload-image">
          <input
            accept="image/*"
            id="image-input"
            type="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
            onClick={(event: any) => {
              event.target.value = null;
            }}
          />
          <div className="avatar-container">
            {/* <div className="image-upload"> */}
            <label className={"avatar-label"} htmlFor="image-input">
              <Avatar
                alt="Avatar"
                src={selectedImage || uploadIcon}
                className="avatar"
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  backgroundColor: "#199ab0;",
                  color: "#ffffff",
                  marginBottom: "10px",
                }}
              ></Avatar>
            </label>
            {/* </div> */}

            <div className="pop-title">
              <InfoIcon />
              <span>
                JPEG,PNG,JPG format are allowed with maximum size of 1 MB
              </span>
            </div>
            {selectedImage && (
              <IconButton onClick={deleteImage} className="cancel-btn">
                <Delete />
              </IconButton>
            )}
          </div>
          {/* )} */}
        </div>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={FormInitialValue}
            onSubmit={onSubmit}
            validationSchema={Schema.profileSchema}
          >
            {({ errors, touched, values, isValid, dirty }) => (
              <Form>
                <Grid container spacing={3} columnSpacing={2}>
                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <InputLabel className="input-label-title">
                      Phone Number
                    </InputLabel>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3} sm={3}>
                        <CommonSelect
                          name="countryCode"
                          label="Country Code"
                          options={countryList}
                          searchable={true}
                          multiple={false}
                          onSelectionChange={(selected, searchValue) => {}}
                          value={values?.countryCode}
                          style={{ width: "100%", gap: "20px" }}
                        />
                      </Grid>
                      <Grid item xs={9} sm={9}>
                        <InputTextField
                          name="phone"
                          placeholder="Phone number"
                          required={false}
                          showlabel={false}
                          variant="outlined"
                          numberOnly={true}
                          type="text"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="email"
                      placeholder="Email"
                      label="Email"
                      variant="outlined"
                      type="email"
                      fullWidth
                      disabled={true}
                    />
                  </Grid>

                  <Grid xs={12} sm={12} item>
                    <InputLabel style={{ color: COLORS.black, padding: 7 }}>
                      Role and Access*
                    </InputLabel>
                    <div>
                      <CommonSelect
                        name="role"
                        label="Role"
                        options={roles}
                        searchable={true}
                        multiple={false}
                        value={values?.role}
                        disabled={true}
                        onSelectionChange={(selected, searchValue) => {
                          // Handle the selected values and search text in your parent component
                        }}
                        style={{ width: "40%" }}
                      />
                    </div>
                  </Grid>

                  <Grid xs={12} sm={12} item>
                    <FormButton
                      cancelBtnClick={backToProfile}
                      actBtnDisabled={!isValid}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Box>
      <ImageCropper
        setCropImage={setCropImage}
        cropImage={cropImage}
        setShowModal={setShowModal}
        showModal={showModal}
        setSelectedImage={setSelectedImage}
        file={file}
        setFile={setFile}
      />
    </>
  );
}
