const styles = {
    wrapper:{
        marginTop:'1em'  
    },
    required:{
        color:'red'
    },

};

export default styles;