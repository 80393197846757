// import React, { useEffect, useState } from "react";
// import "./loader.scss";
// import LoadingBar from "react-top-loading-bar";
// import loaderEvent from "../events/loader.events";

// const Loader = () => {
//   const [progress, setProgress] = useState(0);
//   useEffect(() => {
//     const handleEvent = (eventData: any) => {
//       setProgress(eventData);
//     };

//     loaderEvent.on("showLoader", handleEvent());

//     return () => {
//       loaderEvent.off("showLoader", handleEvent);
//     };
//   }, []);
//   return (
//     <LoadingBar
//       color="#f11946"
//       progress={progress}
//       onLoaderFinished={() => setProgress(1)}
//     />
//   );
// };

// export default Loader;

import { Backdrop, Box, Typography, CircularProgress } from "@mui/material";
// import { LoaderImg, LoaderLogo } from "../../utils/common/constants/images";
import { useAppSelector } from "../../hooks/useAppSelector";
import "./loader.scss";

const Loader = () => {
  const { loading } = useAppSelector((state) => state.globalcontent);

  return (
    <Backdrop className="loaderContainer" open={loading}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6" style={{ marginBottom: "20px" }}>
          Loading...
        </Typography>
        <CircularProgress disableShrink />
      </Box>
    </Backdrop>
  );
};

export default Loader;
