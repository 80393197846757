import { Avatar, Box, Grid, Typography } from "@mui/material";
import styles from "./profile.styles";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useEffect, useState } from "react";
import moment from "moment";

export default function ProfileDetail() {
    const { detail } = useAppSelector((state) => state.userInfo);
    const [userDetail, setUserDetail] = useState<any>({});

    useEffect(() => {
        if (detail) {
            setUserDetail(detail);
        }
    }, [detail]);

    return (
        <Grid sx={styles.profileContainer} container alignItems="center" spacing={1}>
            <Grid item>
            <Box
                  sx={{
                    display: "inline-block",
                    border: "3px solid #1ca2b7",
                    borderRadius: "50%",
                  }}
                >
                <Avatar
                    alt="Avatar"
                    src={userDetail?.avatar || ""}
                    className=""
                    style={styles.avtar}
                />
                </Box>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1">
                    {userDetail?.name?.first} {userDetail?.name?.last}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {userDetail?.email}
                </Typography>
                <Typography style={{color:"#1ca2b7"}} variant="body2" color="textSecondary">
                    {userDetail?.role?.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Last Login: {moment(new Date(userDetail?.createdAt)).format("LL")}
                </Typography>
            </Grid>
        </Grid>
    );
}
