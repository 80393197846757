import Login from "../../features/onboarding/login/login";
import PageNotFound from "../../features/page-not-found";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AdminProfile from "../../features/admin/profile/profileWrapper";
import EditAdminProfile from "../../features/admin/profile/edit-profile";
import {
  ADD_FAQ,
  ADD_NOTIFICATION,
  ADD_ROLES,
  ADD_SUB_ADMIN,
  ADMIN_PROFILE,
  EDIT_FAQ,
  EDIT_PROFILE,
  EDIT_ROLES,
  EDIT_SUB_ADMIN,
  FORGOT_PASSWORD,
  GOOGLE_AUTHENTICATOR,
  GOOGLE_AUTHENTICATOR_CODE,
  GOOGLE_AUTHENTICATOR_DEVICE,
  GOOGLE_AUTHENTICATOR_SCAN,
  GOOGLE_AUTHENTICATOR_SETUP,
  GOOGLE_VERIFY_CODE,
  LINK_EXPIRED,
  LOGIN,
  NOTIFICATION,
  RESET_PASSWORD,
  ROLES,
  STATIC_CONTENT,
  SUB_ADMIN,
  UPDATE_PASSWORD,
  USER,
  USER_DETAIL,
  VERIFY_CODE,
  VIEW_FAQ,
  VIEW_NOTIFICATION,
  VIEW_ROLES,
  VIEW_SUB_ADMIN,
} from "./routes-path";
import ForgotPassword from "../../features/onboarding/forgot-password/forgot-password";
import ResetPassword from "../../features/onboarding/reset-password/reset-password";
import ExpiryPasswordResetLink from "../../features/onboarding/expiry-link/expiry-link";
import PrivateRoutes from "../../utils/PrivateRoutes";
import GoogleAuthenticator from "../../features/google-authenticator/google-authenticator";
import GoogleAuthenticatorSetup from "../../features/google-authenticator/google-authenticator-setup";
import GoogleAuthenticatorCode from "../../features/google-authenticator/google-authenticator-code";
import GoogleAuthenticatorScan from "../../features/google-authenticator/google-authenticator-scan";
import GoogleAuthenticatorDevice from "../../features/google-authenticator/google-authenticator-devices";
import SamplePage from "../../components/sample-page";
import VerificationCode from "../../features/onboarding/verification-code/verification-code";
import SubAdminList from "../../features/sub-admin/sub-admin-list";
import PublicRoutes from "../../utils/PublicRoutes";
import AddSubAdmin from "../../features/sub-admin/add-sub-admin";
import EditSubAdmin from "../../features/sub-admin/edit-sub-admin";
import PageContainer from "../../common/headerWrapper";
import RoleList from "../../features/roles/role-list";
import AddRole from "../../features/roles/add-role";
import ChangePassword from "../../features/update-password/update-password";
import NotificationList from "../../features/notification/notification-list";

import NotificationDetail from "../../features/notification/notification-detail";
import AddNotification from "../../features/notification/add-notification";
import AdminDetail from "../../features/sub-admin/view-sub-admin";
import LoginWrapper from "../../common/loginWrapper";
import StaticTab from "../../features/static-content/staticTab";
import AddFaq from "../../features/static-content/add-faq";
import UsersList from "../../features/users/users-list";
import UserDetail from "../../features/users/view-user";

export default function RoutesConfig() {
  return (
    <Router>
      <Routes>
        <Route element={<PublicRoutes />}>
          <>
            <Route
              path={LOGIN.fullUrl}
              // element={<Login />
              element={<LoginWrapper children={<Login />} />}
            ></Route>
          </>
        </Route>
        {/* <Route path={LOGIN.fullUrl} element={<Login />}></Route> */}

        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={SUB_ADMIN.fullUrl}
              // element={<SubAdminList />}
              element={<PageContainer children={<SubAdminList />} />}
            ></Route>
          </>
        </Route>

        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={ADD_SUB_ADMIN.fullUrl}
              // element={<AddSubAdmin />}
              element={<PageContainer children={<AddSubAdmin />} />}
            ></Route>
          </>
        </Route>

        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={EDIT_SUB_ADMIN.fullUrl}
              // element={<EditSubAdmin />}
              element={<PageContainer children={<EditSubAdmin />} />}
            ></Route>
          </>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={VIEW_SUB_ADMIN.fullUrl}
            element={<PageContainer children={<AdminDetail />} />}
          ></Route>
        </Route>

        {/* user */}
        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={USER.fullUrl}
              // element={<SubAdminList />}
              element={<PageContainer children={<UsersList />} />}
            ></Route>
          </>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={USER_DETAIL.fullUrl}
            element={<PageContainer children={<UserDetail />} />}
          ></Route>
        </Route>
        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={ROLES.fullUrl}
              element={<PageContainer children={<RoleList />} />}
            ></Route>
          </>
        </Route>

        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={ADD_ROLES.fullUrl}
              element={<PageContainer children={<AddRole />} />}
            ></Route>
          </>
        </Route>

        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={EDIT_ROLES.fullUrl}
              element={<PageContainer children={<AddRole />} />}
            ></Route>
          </>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={VIEW_ROLES.fullUrl}
            element={<PageContainer children={<AddRole view={true} />} />}
          ></Route>
        </Route>

        {/* static */}

        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={ADD_FAQ.fullUrl}
              element={<PageContainer children={<AddFaq />} />}
            ></Route>
          </>
        </Route>

        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={EDIT_FAQ.fullUrl}
              element={<PageContainer children={<AddFaq />} />}
            ></Route>
          </>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={VIEW_FAQ.fullUrl}
            element={<PageContainer children={<AddFaq view={true} />} />}
          ></Route>
        </Route>
        <Route element={<PrivateRoutes />}>
          <>
            <Route
              path={STATIC_CONTENT.fullUrl}
              element={<PageContainer children={<StaticTab />} />}
            ></Route>
          </>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={NOTIFICATION.fullUrl}
            element={<PageContainer children={<NotificationList />} />}
          ></Route>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={VIEW_NOTIFICATION.fullUrl}
            element={<PageContainer children={<NotificationDetail />} />}
          ></Route>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={ADD_NOTIFICATION.fullUrl}
            element={<PageContainer children={<AddNotification />} />}
          ></Route>
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route
            path={ADMIN_PROFILE.fullUrl}
            // element={<AdminProfile />}
            element={<PageContainer children={<AdminProfile />} />}
          ></Route>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={EDIT_PROFILE.fullUrl}
            // element={<EditAdminProfile />}
            element={<PageContainer children={<EditAdminProfile />} />}
          ></Route>
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route
            path={UPDATE_PASSWORD.fullUrl}
            // element={<EditAdminProfile />}
            element={<PageContainer children={<ChangePassword />} />}
          ></Route>
        </Route>

        <Route path="/sample" element={<SamplePage />}></Route>

        <Route
          path={FORGOT_PASSWORD.fullUrl}
          // element={<ForgotPassword />}
          element={<LoginWrapper children={<ForgotPassword />} />}
        ></Route>
        <Route
          path={`${RESET_PASSWORD.fullUrl}/:token`}
          // element={<ResetPassword />}
          element={<LoginWrapper children={<ResetPassword />} />}
        ></Route>
        <Route
          path={`${LINK_EXPIRED.fullUrl}`}
          element={<ExpiryPasswordResetLink />}
        ></Route>
        <Route
          path={VERIFY_CODE.fullUrl}
          // element={<VerificationCode />}
          element={<LoginWrapper children={<VerificationCode />} />}
        ></Route>
        <Route
          path={GOOGLE_AUTHENTICATOR_SCAN.path}
          element={<GoogleAuthenticatorScan />}
        ></Route>
        <Route
          path={GOOGLE_VERIFY_CODE.path}
          element={<GoogleAuthenticatorCode />}
        ></Route>

        <Route
          path={`${GOOGLE_AUTHENTICATOR.fullUrl}`}
          element={<GoogleAuthenticator />}
        >
          <Route path="" element={<GoogleAuthenticatorSetup />}></Route>

          <Route
            path={GOOGLE_AUTHENTICATOR_SETUP.path}
            element={<GoogleAuthenticatorSetup />}
          ></Route>
          <Route
            path={GOOGLE_AUTHENTICATOR_DEVICE.path}
            element={<GoogleAuthenticatorDevice />}
          ></Route>
          <Route
            path={GOOGLE_AUTHENTICATOR_SCAN.path}
            element={<GoogleAuthenticatorScan />}
          ></Route>
          <Route
            path={GOOGLE_AUTHENTICATOR_CODE.path}
            element={<GoogleAuthenticatorCode />}
          ></Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}
