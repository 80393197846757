import { UseAppDispatch } from "../../hooks/useAppDispatch";

const logoutHelper = () => {
  const dispatch = UseAppDispatch();
  const logoutInitialValue = {};

  const goBackFunction = () => {};

  return {
    logoutInitialValue,
    dispatch,
    goBackFunction,
  };
};

export default logoutHelper;
