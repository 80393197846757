import React from "react";
import { InputLabel, TextField } from "@mui/material";
import { useField } from "formik";
import "./text.scss";
import { PATTERN } from "../../../constants/patterns";
const InputTextField: React.FC<any> = ({
  name,
  label,
  placeholder,
  validate,
  disabled = false,
  required = true,
  showlabel = true,
  numberOnly = false,
  validLegth = false,
  length,
  multiline=false,
   rows=1,
  ...rest
}) => {
  const [field, meta, helpers] = useField({ name, validate });
    const { setValue } = helpers;

  return (
    <>
      {showlabel ? (
        <InputLabel className="input-label">
          {label}
          {required ? "*" : null}
        </InputLabel>
      ) : null}
      <TextField
        {...field}
        {...rest}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        disabled={disabled}
        variant="outlined"
        label={null}
        placeholder={label || placeholder}
        className="inputField"
        rows={rows}
        multiline={multiline}
        onChange={(e) => {
          if (numberOnly) {
            if (PATTERN.numberOnly.test(e.target.value) || !e.target.value) {
              setValue(e.target.value);
            }
          } else if (validLegth) {
            if (e.target.value.length <= 50) {
              setValue(e.target.value);
            }
          } else {
            setValue(e.target.value);
          }
        }}
        InputProps={{ style: { borderRadius: "10px" } }}
      />
    </>
  );
};

export default InputTextField;
