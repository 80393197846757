import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  TextField,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import { Formik, Form } from "formik";
import Schema from "../../../common/constants/schema";
import { COLORS } from "../../../common/constants/colors";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION } from "../../../constants/routes/routes-path";
import "./role.scss";
import Select from "@mui/material/Select";
import notificationHelper from "./helper";
import { addNotification } from "../services/action";
import { ArrowForwardIosIcon } from "../../../constants/images";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
import FormButton from "../../../components/form-controls/button/form-button";
import InputTextField from "../../../components/form-controls/text";

export default function AddNotification() {
  const [selectedPlatefrom, setSelectedPlatefrom] = useState<string[]>([]);
  const { initialValue, plateformList, dispatch } = notificationHelper();

  const navigation = useNavigate();
  const [platefroms, setPlatefroms] = useState<any>([]);

  const selectPlateform = (platformNames: any) => {
    setSelectedPlatefrom(
      typeof platformNames === "string"
        ? platformNames.split(",")
        : platformNames
    );
    if (platformNames.length === 0) {
      setPlatefroms((prevList: any) =>
        prevList.map((platform: any) => ({ ...platform, value: false }))
      );
    } else {
      setPlatefroms((prevList: any) =>
        prevList.map((platform: any) => {
          if (platformNames.includes(platform.name)) {
            return { ...platform, value: true };
          } else {
            return { ...platform, value: false };
          }
        })
      );
    }
  };

  useEffect(() => {
    setPlatefroms(plateformList);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onBack = () => {
    navigation(NOTIFICATION.fullUrl);
  };

  const onSubmit = async (values: any, send: boolean = false) => {
    let platefromValue: any = {};

    platefroms.forEach((plateform: any) => {
      platefromValue[plateform.key] = plateform.value;
    });

    let notificationPayload: any = {
      title: values.title,
      content: values.description,
      url: values?.url,
      platform: platefromValue,
      isSend: send,
    };

    dispatch(addNotification(notificationPayload, onBack));
  };

  return (
    <Box className="role-wrapper" sx={{ width: "100%" }}>
      <div className="detail-container">
        <BreadCrumb
          mainLink={NOTIFICATION.fullUrl}
          mainLabel={"Notification Management"}
          secondaryLabel={"Create Notification"}
          showMultipleLink={true}
        />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        onSubmit={(values) => onSubmit(values, false)}
        validationSchema={Schema.NotificationSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldTouched,
          isValid,
          dirty,
          values,
        }) => (
          <Form>
            <Box
              sx={{
                boxShadow: "none",
                padding: 5,
                borderRadius: 2,
                background: "#ffffff",
              }}
            >
              <Grid container spacing={3}>
                <Grid xs={12} sm={5} item>
                  {/* <InputLabel style={{ color: COLORS.black, padding: 7 }}>
                    Title*
                  </InputLabel>

                  <TextField
                    name="title"
                    placeholder="Title"
                    variant="outlined"
                    onBlur={handleBlur}
                    type="text"
                    fullWidth
                    onChange={(e) => {
                      setFieldTouched("title", true);
                      handleChange(e);
                    }}
                    error={touched.title && !!errors.title}
                    helperText={(touched.title && errors.title) as string}
                  /> */}
                  <InputTextField
                    name="title"
                    label="Title"
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid xs={12} sm={5} item>
                  {/* <InputLabel style={{ color: COLORS.black, padding: 7 }}>
                    URL
                  </InputLabel>

                  <TextField
                    name="url"
                    placeholder="URL"
                    variant="outlined"
                    onBlur={handleBlur}
                    type="text"
                    fullWidth
                    onChange={(e) => {
                      setFieldTouched("url", true);
                      handleChange(e);
                    }}
                    error={touched.url && !!errors.url}
                    helperText={(touched.url && errors.url) as string}
                  /> */}
                  <InputTextField
                    name="url"
                    label="URL"
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} sm={5} item>
                  <InputLabel style={{ color: COLORS.label, padding: 7 }}>
                    Recipient*
                  </InputLabel>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel
                      id="demo-multiple-checkbox"
                      style={{ color: "#767676", padding: "0px 0px" }}
                    >
                      Recipient
                    </InputLabel>

                    <Select
                      id="demo-multiple-checkbox"
                      name={"receipt"}
                      label={"Recipient"}
                      multiple
                      value={selectedPlatefrom}
                      style={{ borderRadius: "10px" }}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldTouched("receipt", true);
                        selectPlateform(e.target.value);
                      }}
                      renderValue={(selected) => selected.join(", ")}
                      fullWidth
                      error={!!errors.receipt}
                    >
                      {platefroms.map((plateform: any, index: number) => {
                        let { name, value } = plateform;
                        return (
                          <MenuItem key={index} value={name}>
                            <Checkbox
                              checked={value}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#1ca2b7", // color when checked
                                },
                              }}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {/* <Typography>{errors?.title}</Typography> */}
                </Grid>
                <Grid xs={12} sm={5} item>
                  {/* <InputLabel style={{ color: COLORS.black, padding: 7 }}>
                    Description*
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    onBlur={handleBlur}
                    name={"description"}
                    type="text"
                    placeholder="Description"
                    onChange={(e) => {
                      setFieldTouched("description", true);
                      handleChange(e);
                    }}
                    fullWidth
                    multiline
                    rows={4}
                    error={touched.description && !!errors.description}
                    helperText={
                      (touched.description && errors.description) as string
                    }
                  /> */}
                  <InputTextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    type="text"
                    multiline={true}
                    fullWidth
                    rows={4}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <FormButton
                    cancelBtnClick={onBack}
                    actBtnDisabled={!isValid || !dirty}
                    showThirdBtn={true}
                    thirdBtnLabel="Submit and Send"
                    thirdBtnDisabled={!isValid || !dirty}
                    thirdBtnClick={() => {
                      onSubmit(values, true);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
