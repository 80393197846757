import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Form, Formik } from "formik";
import { Box, Grid } from "@mui/material";
import changePasswordHelper from "./helper";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import Schema from "../../common/constants/schema";
import PasswordField from "../../components/form-controls/text-password";
import { updatePassword } from "./services/action";
import passwordStyles from "./style";
import FormButton from "../../components/form-controls/button/form-button";

export default function ChangePassword() {
  const { passwordInitialValue, dispatch } = changePasswordHelper();
  const navigation = useNavigate();
  const [lowercase, setLowercase] = useState(false);
  const [uppercase, setUppercase] = useState(false);
  const [number, setNumber] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [length, setLength] = useState(false);
  const classes = passwordStyles();

  const handlePassword = (e: any) => {
    const value = e.target.value;
    setLowercase(/[a-z]/.test(value));
    setUppercase(/[A-Z]/.test(value));
    setNumber(/\d/.test(value));
    setSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    setLength(value.length >= 8);
  };

  const onSubmit = async (values: any) => {
    let updatePassPayload: any = {
      currentPassword: values.oldPassword,
      newPassword: values.password,
    };

    dispatch(updatePassword(updatePassPayload, navigation));
  };



  const checkPassword = (type: boolean) => {
    if (type) {
      return (
        <RadioButtonChecked
          className={`${classes.icon} ${type ? classes.iconColor : ""}`}
        />
      );
    } else {
      return (
        <RadioButtonUnchecked
          className={`${classes.icon} ${type ? classes.iconColor : ""}`}
        />
      );
    }
  };

  const checkColor = (type: boolean) => {
    if (type) {
      return "green";
    } else {
      return "inherit";
    }
  };

  return (
    <>
      <Box className="detail-wrapper" sx={{ width: "100%" }}>
        <div className="detail-container">
          <Typography noWrap variant="h6">
            Change Password
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          display: "grid",
          boxShadow: "none",
          padding: 5,
          borderRadius: 2,
          background: "#ffffff",
        }}
        className="edit-profile-wrapper"
      >
        <div>
          <Formik
            validateOnMount={true}
            enableReinitialize={true}
            initialValues={passwordInitialValue}
            onSubmit={onSubmit}
            validationSchema={Schema.ChangePasswordSchema}
          >
            {({
              isValid,
              dirty,
            }) => (
              <Form>
                <Grid
                  container
                  spacing={3}
                  columnSpacing={2}
                  style={{ width: "100%" }}
                >
                  <Grid xs={12} sm={6} item>
                    <div style={{ width: "100%" }}>
                      <PasswordField
                        style={{ width: "100%" }}
                        name="oldPassword"
                        type="password"
                        label="Password"
                      ></PasswordField>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} item>
                    <div style={{ width: "50%" }}>
                      <PasswordField
                        className={classes.newPassword}
                        style={{ width: "100%" }}
                        name="password"
                        type="password"
                        label="New Password"
                        handleChange={handlePassword}
                      ></PasswordField>
                    </div>

                    <div className={classes.errorBox}>
                      <Typography
                        style={{
                          color: checkColor(lowercase),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(lowercase)}
                        One lowercase letter
                      </Typography>
                      <Typography
                        style={{
                          color: checkColor(uppercase),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(uppercase)}
                        One uppercase letter
                      </Typography>
                      <Typography
                        style={{
                          color: checkColor(number),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(number)}
                        One number
                      </Typography>
                      <Typography
                        style={{
                          color: checkColor(specialChar),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(specialChar)}
                        One special character i.e @,$,%,&
                      </Typography>
                      <Typography
                        style={{
                          color: checkColor(length),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(length)}8 character minimum"
                      </Typography>
                    </div>
                  </Grid>

                  <Grid xs={12} sm={6} item>
                    <PasswordField
                      name="confirmPassword"
                      // style={{width:"50%"}}
                      type="password"
                      label="Confirm Password"
                    ></PasswordField>
                  </Grid>

                  <Grid xs={12} sm={12} item>
                    <FormButton
                      showCancelBtn={false}
                      actBtnDisabled={!isValid || !dirty}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Box>
    </>
  );
}
