import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const profileHelper = (isEdit: any, detail: any) => {
  const dispatch = UseAppDispatch();
  let initialValue = {
    email:  "",
    firstName:  "",
    lastName:  "",
    phone: "",
    role: "",
    countryCode: "",
  };
 const passwordInitialValue = {
   password: "",
 };
  const goBackFunction = () => {};

  const updateValue = (detail: any) => {
    initialValue.email = detail.email;
  };
  return {
    initialValue,
    passwordInitialValue,
    dispatch,
    goBackFunction,
    updateValue,
  };
};

export default profileHelper;
