import { useEffect, useState } from "react";
import {
  Box,
  Typography,

  TextField,
  Divider,
  Grid,
  InputLabel,
} from "@mui/material";

import { Formik, Form } from "formik";

import Schema from "../../../common/constants/schema";
import { COLORS } from "../../../common/constants/colors";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { useNavigate, useParams } from "react-router-dom";
import { STATIC_CONTENT } from "../../../constants/routes/routes-path";
import "./role.scss";

import {
  addFaq,
  getFaqDetail,
  updateFaq,
} from "../services/action";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { setLoading } from "../../../redux/global.slice";
import faqHelper from "./helper";
import { ArrowForwardIosIcon } from "../../../constants/images";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
import FormButton from "../../../components/form-controls/button/form-button";
import InputTextField from "../../../components/form-controls/text";
export default function AddFaq({ view }: any) {
  const { detail } = useAppSelector((state) => state.faqDetail);
  let { faqId } = useParams();
  const { initialValue, dispatch } = faqHelper();
  const navigation = useNavigate();
  const { data } = useAppSelector((state) => state.roleSearch);
  
  const [FormInitialValue, setFormInitialValue] = useState<any>(initialValue);



  const updateInitialValue = () => {
    setFormInitialValue((prev: any) => ({
      ...prev,
      question: detail?.title || prev.question,
      answer: detail?.content || prev.answer,
    }));
  };


  const getFaqData = () => {
    dispatch(getFaqDetail(faqId));
  };

  useEffect(() => {

    if (detail && data && faqId) {
      dispatch(setLoading(false));
      updateInitialValue();
    }
  }, [data, detail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (faqId) {
      getFaqData();
    }
  }, [faqId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onBack = () => {
    navigation("/static-content?tab=3");
  };
  const onSubmit = async (values: any) => {


    let faqPayload: any = {
      title: values.question,
      content: values.answer,
    };
    if (faqId) {
      dispatch(updateFaq(faqId, faqPayload, onBack));
    } else {
      dispatch(addFaq(faqPayload, onBack));
    }

    //  handleOnSubmit(signupPayload);
  };


  const getTitle = () => {
    if (faqId && !view) {
      return "Edit FAQ";
    } else if (view) {
      return "View FAQ";
    } else {
      return "Add FAQ";
    }
  }

  const handleEditRole = () => {
      navigation(`/roles/edit-roles/${faqId}`);
  }
  return (
    <Box className="role-wrapper" sx={{ width: "100%" }}>
      <div className="detail-container">
        <BreadCrumb
          mainLink={STATIC_CONTENT.fullUrl}
          mainLabel={"Static Content Management"}
          secondaryLabel={getTitle()}
          showMultipleLink={true}
        />

        {view && (
          <button type="button" onClick={handleEditRole} className="action-btn">
            Edit
          </button>
        )}
      </div>
      <Formik
        validateOnMount={true}
        enableReinitialize={true}
        initialValues={FormInitialValue}
        onSubmit={onSubmit}
        validationSchema={Schema.FaqSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleBlur,
          setFieldTouched,
          isValid,
          dirty,
        }) => (
          <Form>
            <Box
              sx={{
                boxShadow: "none",
                padding: 5,
                borderRadius: 2,
                background: "#ffffff",
              }}
            >
              <Grid container spacing={3}>
                <Grid xs={12} sm={12} item>
                  <InputTextField
                    name="question"
                    label="Question"
                    variant="outlined"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} marginTop={"10px"}>
                <Grid xs={12} sm={12} item>
                  <InputTextField
                    name="answer"
                    label="Answer"
                    variant="outlined"
                    type="text"
                    multiline={true}
                    fullWidth
                    rows={4}
                  />
                </Grid>
              </Grid>
              {!view ? (
                <Grid xs={12} sm={12} item style={{ marginTop: "20px" }}>
                  <FormButton
                    cancelBtnClick={onBack}
                    actBtnDisabled={!isValid}
                  />
                </Grid>
              ) : null}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
