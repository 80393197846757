export const styles = {
  breadcumbWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "spaceBetween",
  },
  mainLinkFont: {
    color: "#000",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
  },
};

