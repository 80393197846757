import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { PROFILE_ACTION } from "../../enums/profile-actions.enum";
import ConfirmDialog from "../../shared/dialogs/confirm-dialog";
import { IPopupData } from "../../models/common-models";
import { POPUP_MESSAGES } from "../../constants/messages";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { onLogout } from "./services/action";
import logoutHelper from "./helper";
import { PROFILE_MENU, ProfileMenu } from "./type";
import { KeyboardArrowRightIcon, PersonIcon } from "../../constants/images";
import ProfileDetail from "../../features/admin/profile/profile-deatil";
import { getUserInfo, getUserPermission } from "../../features/admin/services/action";
import { useAppSelector } from "../../hooks/useAppSelector";
import LogoutIcon from "@mui/icons-material/Logout";
import "./header.scss";
import LockIcon from "@mui/icons-material/Lock";

import { ArrowDropDownIcon } from "@mui/x-date-pickers-pro";
function Header() {
  const { dispatch } = logoutHelper();
  const { detail } = useAppSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const childRef = useRef();
  const [, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userDetail, setUserDetail] = useState<any>({});

  const logoutDialog: IPopupData = {
    title: POPUP_MESSAGES.logout,
    message: POPUP_MESSAGES.logoutConfirmation,
    cancelButtonText: POPUP_MESSAGES.cancel,
    confirmButtonText: POPUP_MESSAGES.ok,
  };
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigateMenu = (menu: ProfileMenu) => {
    if (PROFILE_ACTION.LOGOUT === menu.titleCode) {
      openDialog();
    } else {
      navigate(menu.path);
    }
    setAnchorElUser(null);
  };

  const openDialog = () => {
    (childRef.current as any).openDialog();
  };

  const logout = () => {
    dispatch(onLogout(navigate));
  };

  useEffect(() => {
    dispatch(getUserInfo(""));
    dispatch(getUserPermission(""))
  }, [dispatch]);

  useEffect(() => {
    if (detail) {
      setUserDetail(detail);
    }
  }, [detail]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          borderBottom: "1px solid #e0e0e0",
        }}
        elevation={0}
      >
        <Toolbar disableGutters sx={{ px: 2 }}>
          <img
            alt=""
            style={{ width: "200px" }}
            src="../../../images/logo.svg"
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Box
                  sx={{
                    display: "inline-block",
                    border: "2px solid #1ca2b7",
                    borderRadius: "50%",
                  }}
                >
                  <Avatar alt="Remy Sharp" src={userDetail?.avatar || ""} />
                </Box>
                <span className="user-deatil">{userDetail?.name?.first}</span>
                <span className="user-deatil"> {userDetail?.name?.last}</span>
                <ArrowDropDownIcon />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: "45px", width: "auto" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                sx: {
                  "& .MuiList-padding": {
                    padding: 0,
                  },
                },
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem sx={{ p: 0, m: 0, width: "auto" }}>
                <ProfileDetail />
              </MenuItem>

              {PROFILE_MENU.map((menu, menuIndex) => (
                <MenuItem
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  key={`menu-${menuIndex}`}
                  onClick={() => navigateMenu(menu)}
                >
                  <div style={{ display: "flex", alignItems: "center" , color:"#767676" }}>
                    {menu.title === "View Profile" ? (
                      <PersonIcon sx={{ marginRight: 2 }} />
                    ) : menu.title === "Change Password" ? (
                      <LockIcon sx={{ marginRight: 2 }} />
                    ) : menu.title === "Logout" ? (
                      <LogoutIcon sx={{ marginRight: 2 }} />
                    ) : null}
                    <Typography textAlign="center">{menu?.title}</Typography>
                  </div>

                  <KeyboardArrowRightIcon />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <ConfirmDialog
        title={logoutDialog.title as string}
        message={logoutDialog.message}
        cancelButtonText={logoutDialog.cancelButtonText as string}
        confirmButtonText={logoutDialog.confirmButtonText as string}
        logoutUser={logout}
        ref={childRef}
      />
    </>
  );
}
export default Header;
