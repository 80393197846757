import { Button } from "@mui/material";
import { styles } from "./styles";

const FormButton: React.FC<any> = ({
  showCancelBtn = true,
  cancelBtnLabel = "Cancel",
  cancelBtnClick = () => {},
  showActionBtn = true,
  actionBtnLabel = "Submit",
  actionBtnClick = () => {},
  actBtnDisabled = false,
  showThirdBtn = false,
  thirdBtnLabel = "",
  thirdBtnClick = () => {},
  thirdBtnDisabled=false
}) => {
  return (
    <div style={styles.btnWrapper}>
      {showCancelBtn ? (
        <Button
          variant="outlined"
          onClick={cancelBtnClick}
          sx={styles.cancelBtn}
        >
          {cancelBtnLabel}
        </Button>
      ) : null}
      {showActionBtn ? (
        <Button
          onClick={actionBtnClick}
          type="submit"
          sx={styles.actionBtn}
          disabled={actBtnDisabled}
        >
          {actionBtnLabel}
        </Button>
      ) : null}
      {showThirdBtn ? (
        <Button
          onClick={thirdBtnClick}
          type="submit"
          sx={styles.actionBtn}
          disabled={thirdBtnDisabled}
        >
          {thirdBtnLabel}
        </Button>
      ) : null}
    </div>
  );
};

export default FormButton;
