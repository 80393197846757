import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import "./reset-password.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setAccessToken } from "../../../services/storage/storage-service";
import { Form, Formik } from "formik";
import resetHelper from "./helper";
import { onReset } from "./services/action";
import Schema from "../../../common/constants/schema";
import { Grid, Stack } from "@mui/material";
import PasswordField from "../../../components/form-controls/text-password";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { LOGIN } from "../../../constants/routes/routes-path";
import passwordStyles from "./style";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import FormButton from "../../../components/form-controls/button/form-button";

export default function ResetPassword() {
  const { resetInitialValue, dispatch } = resetHelper();
  const [lowercase, setLowercase] = useState(false);
  const [uppercase, setUppercase] = useState(false);
  const [number, setNumber] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [length, setLength] = useState(false);
  const classes = passwordStyles();
  const navigation = useNavigate();

  const params = useParams<{ token: string }>();

  useEffect(() => {
    let token = params.token ? params.token : "";
    setAccessToken(token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBack = () => {
    navigation(LOGIN.fullUrl);
  };
  const handlePassword = (e: any) => {
    const value = e.target.value;
    setLowercase(/[a-z]/.test(value));
    setUppercase(/[A-Z]/.test(value));
    setNumber(/\d/.test(value));
    setSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
    setLength(value.length >= 8);
  };

  const checkPassword = (type: boolean) => {
    if (type) {
      return (
        <RadioButtonChecked
          className={`${classes.icon} ${type ? classes.iconColor : ""}`}
        />
      );
    } else {
      return (
        <RadioButtonUnchecked
          className={`${classes.icon} ${type ? classes.iconColor : ""}`}
        />
      );
    }
  };

  const checkColor = (type: boolean) => {
    if (type) {
      return "#199ab0";
    } else {
      return "rgba(0, 0, 0, 0.6)";
    }
  };
  return (
    <>
      <div className="reset-password-form">
        <Card
          variant="outlined"
          sx={{
            minWidth: "500px",
            maxWidth: "500px",
            border: "none",
            background: "transparent",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              color="textSecondary"
              mb={1}
              style={{ textAlign: "start", marginBottom: "15px" }}
            >
              Reset Password
            </Typography>

            {/* <Formik
              validateOnMount={true}
              initialValues={resetInitialValue}
              onSubmit={(values, { setSubmitting }) => {
                const body = {
                  password: values.password,
                };
                dispatch(onReset(body, setSubmitting, navigation));
              }}
              validationSchema={Schema.ResetSchema}
            >
              {({ isValid }) => (
                <Form>
                  <Stack spacing={2}>
                    <PasswordField
                      name="password"
                      type="password"
                      label="Password"
                      handlePassword={handlePassword}
                    ></PasswordField>
                    <div className={classes.errorBox}>
                      <Typography
                        style={{
                          color: checkColor(lowercase),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(lowercase)}
                        One lowercase letter
                      </Typography>
                      <Typography
                        style={{
                          color: checkColor(uppercase),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(uppercase)}
                        One uppercase letter
                      </Typography>
                      <Typography
                        style={{
                          color: checkColor(number),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(number)}
                        One number
                      </Typography>
                      <Typography
                        style={{
                          color: checkColor(specialChar),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(specialChar)}
                        One special character i.e @,$,%,&
                      </Typography>
                      <Typography
                        style={{
                          color: checkColor(length),
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {checkPassword(length)}8 character minimum"
                      </Typography>
                    </div>

                    <br />

                    <PasswordField
                      name="confirmPassword"
                      type="password"
                      label="Confirm Password"
                    ></PasswordField>
                    <button
                      type="submit"
                      disabled={!isValid}
                      className="action-btn"
                    >
                      Send Password Reset Link
                    </button>

                    <button onClick={handleBack} className="link-btn">
                      Back to Login
                    </button>
                  </Stack>
                </Form>
              )}
            </Formik> */}
            <div>
              <Formik
                validateOnMount={true}
                initialValues={resetInitialValue}
                onSubmit={(values, { setSubmitting }) => {
                  const body = {
                    password: values.password,
                  };
                  dispatch(onReset(body, setSubmitting, navigation));
                }}
                validationSchema={Schema.ResetSchema}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <Grid
                      container
                      spacing={3}
                      columnSpacing={2}
                      style={{ width: "100%" }}
                    >
                      <Grid xs={12} sm={12} item>
                        <PasswordField
                          className={classes.newPassword}
                          style={{ width: "100%" }}
                          name="password"
                          type="password"
                          label="New Password"
                          handleChange={handlePassword}
                        ></PasswordField>

                        <div className={classes.errorBox}>
                          <Typography
                            style={{
                              color: checkColor(lowercase),
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {checkPassword(lowercase)}
                            One lowercase letter
                          </Typography>
                          <Typography
                            style={{
                              color: checkColor(uppercase),
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {checkPassword(uppercase)}
                            One uppercase letter
                          </Typography>
                          <Typography
                            style={{
                              color: checkColor(number),
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {checkPassword(number)}
                            One number
                          </Typography>
                          <Typography
                            style={{
                              color: checkColor(specialChar),
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {checkPassword(specialChar)}
                            One special character i.e @,$,%,&
                          </Typography>
                          <Typography
                            style={{
                              color: checkColor(length),
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {checkPassword(length)}8 character minimum"
                          </Typography>
                        </div>
                      </Grid>

                      <Grid xs={12} sm={12} item>
                        <PasswordField
                          name="confirmPassword"
                          type="password"
                          label="Confirm Password"
                        ></PasswordField>
                      </Grid>

                      <Grid xs={12} sm={12} item>
                        <button
                          type="submit"
                          disabled={!isValid}
                          className="action-btn"
                        >
                          Send Password Reset Link
                        </button>

                      <button onClick={handleBack} style={{marginTop:"20px"}} className="link-btn">
                          Back to Login
                        </button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
