import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { styles } from "./styles";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const BreadCrumb: React.FC<any> = ({
  showMultipleLink = false,
  mainLink,
  mainLabel,
  secondaryLabel,
}) => {
  const singleBreadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href={mainLink}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      style={styles.mainLinkFont}
    >
      {mainLabel}
    </Link>,
  ];

  const MultiBreadcrumbs = [
    <Link key="1" color="inherit" href={mainLink} style={styles.mainLinkFont}>
      {mainLabel}
    </Link>,
    <Link
      key="2"
      color="inherit"
      href={mainLink}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      style={styles.mainLinkFont}
    >
      {secondaryLabel}
    </Link>,
  ];

  return (
    <div style={styles.breadcumbWrapper}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {showMultipleLink ? MultiBreadcrumbs : singleBreadcrumbs}
      </Breadcrumbs>
    </div>
  );
};
export default BreadCrumb;
