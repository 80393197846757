export function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

export function setRefreshToken(token: string | null) {
  localStorage.setItem("refreshToken", token as string);
}
export function getAccessToken() {
  return localStorage.getItem("Access-Token");
}

export function setAccessToken(token: string | null) {
  localStorage.setItem("Access-Token", token as string);
}

export function clearStorage() {
  localStorage.clear();
}

export function get2FAToken() {
  return localStorage.getItem("2FA-TOKEN");
}

export function set2FAToken(token: string | null) {
  localStorage.setItem("2FA-TOKEN", token as string);
}

export function setQRUrl(qrUrl: string | null) {
  localStorage.setItem("qrUrl", qrUrl as string);
}

export function getQRUrl() {
  return localStorage.getItem("qrUrl");
}

export function getUserId() {
  return localStorage.getItem("userId");
}

export function setUserId(userId: string) {
  localStorage.setItem("userId", userId);
}

export function getAuthBase64Key() {
  const username = process.env.REACT_APP_BASIC_AUTH_USER;
  const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
  const authKey = `${username}:${password}`;
  return authKey;
}
