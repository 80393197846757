import { Grid } from "@mui/material";
import * as React from "react";
import "./loginwrapper.scss";
import logo from "../../assets/images/uploadIcon.svg";
// import { logo } from "../../constants/images";
interface Props {
  children: React.ReactNode;
}
export default function LoginWrapper({ children }: Props) {
  return (
    <div>
      <Grid container>
        <Grid xs={12} sm={5} item className="login-container">
          <div>
            <h3>Welcome to React Accelerator Admin Panel</h3>
            <p>
              Admin Accelerator offers the most convenient and reliable way to
              get around town
            </p>
          </div>
        </Grid>
        <Grid xs={12} sm={7} item>
          <div className="login-children">
            {/* <div className="title">
              <img src={logo} alt="" />
            </div> */}
            {children}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
