import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../../common/utils";
import { setLoading } from "../../../redux/global.slice";
import {
  getApiCall,
  patchApiCall,
  postApiCall,
} from "../../../common/constants/api";
import * as URLS from "../../../constants/url";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { allUsers } from "./slice";
import { allRoles } from "./roleSlice";
import { getDetail } from "./detailSlice";
import "../../admin/profile/profile.scss"

export const getUsers = (params: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.USER}`,
    params,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(allUsers(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};

export const directaddSubAdmin =
  (params: any, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    postApiCall(
      `${URLS.SUB_ADMIN}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

export const addSubAdmin =
  (params: any, imagePayload: any, file: any, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    getApiCall(
      `${URLS.PROFILE_IMAGE}?action=${imagePayload?.action}&extension=${imagePayload?.extension}`,
      "",
      (response: AxiosResponse<any>) => {
        const { statusCode, result } = response.data;
        if (statusCode === apiSuccessCode.success) {
          let imagePath = result?.path;
          let imageUploadUrl = result?.url;

          const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "fileType" },
            body: file,
          };
          fetch(imageUploadUrl, requestOptions).then((res) => {
            if (res) {
              params.avatar = imagePath || "";
              postApiCall(
                `${URLS.SUB_ADMIN}`,
                params,
                (response: AxiosResponse<any>) => {
                  const { statusCode} = response.data;
                  if (statusCode === apiSuccessCode.success) {
                    // showAlert(1, "Profile updated successfully");
                    if (callback) {
                      callback();
                    }
                  }
                  dispatch(setLoading(false));
                },
                (error: any) => {
                  dispatch(setLoading(false));
                  showAlert(2, error.message);
                }
              );
            }
          });
          // dispatch(userInfo(result));
          // showAlert(1, "Profile updated successfully");
          // if (callback) {
          //   callback();
          // }
        }
        // dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
export const userBlockUnblock =
  (params: any, unBlock: boolean, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    let API = "";
    if (unBlock) {
      API=URLS.USER_UNBLOCK
    } else {
      API = URLS.USER_BLOCK;
    }
      patchApiCall(
        `${API}/` + params.id,
        params,
        (response: AxiosResponse<any>) => {
          const { statusCode } = response.data;
          if (statusCode === apiSuccessCode.success) {
            if (callback) {
              callback();
            }
          }
          dispatch(setLoading(false));
        },
        (error: any) => {
          dispatch(setLoading(false));
          showAlert(2, error.message);
        }
      );
  };

export const updateSubAdmin =
  (subAdminId: any, params: any, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    patchApiCall(
      // `${URLS.SUB_ADMIN}`,
      `${URLS.SUB_ADMIN + "/" + subAdminId}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          // dispatch(allAdmins(result.data));
          // dispatch(goBac )
          if (callback) {
            callback();
          }
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

  export const updateSubAdminImg =
    (subAdminId:any,params: any, imagePayload: any, file: any, callback: any) =>
    (dispatch: Dispatch<any>) => {
      if (!navigator.onLine) {
        showAlert(2, "No internet connection!");
        return;
      }
      dispatch(setLoading(true));
      getApiCall(
        `${URLS.PROFILE_IMAGE}?action=${imagePayload?.action}&extension=${imagePayload?.extension}`,
        "",
        (response: AxiosResponse<any>) => {
          const { statusCode, result } = response.data;
          if (statusCode === apiSuccessCode.success) {
            let imagePath = result?.path;
            let imageUploadUrl = result?.url;

            const requestOptions = {
              method: "PUT",
              headers: { "Content-Type": "fileType" },
              body: file,
            };
            fetch(imageUploadUrl, requestOptions).then((res) => {
              if (res) {
                params.avatar = imagePath || "";
                patchApiCall(
                  `${URLS.SUB_ADMIN}/${subAdminId}`,
                  params,
                  (response: AxiosResponse<any>) => {
                    const { statusCode } = response.data;
                    if (statusCode === apiSuccessCode.success) {
                      // showAlert(1, "Profile updated successfully");
                      if (callback) {
                        callback();
                      }
                    }
                    dispatch(setLoading(false));
                  },
                  (error: any) => {
                    dispatch(setLoading(false));
                    showAlert(2, error.message);
                  }
                );
              }
            });
            // dispatch(userInfo(result));
            // showAlert(1, "Profile updated successfully");
            // if (callback) {
            //   callback();
            // }
          } else {
             dispatch(setLoading(false));
          }
         
        },
        (error: any) => {
          dispatch(setLoading(false));
          showAlert(2, error.message);
        }
      );
    };
export const getRoles = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.ROLE}?page=1&limit=100&isActive=true`,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(allRoles(result.data));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

export const getSubAdminDetail = (params: any) => (dispatch: Dispatch<any>) => {
  const body = "";
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.SUB_ADMIN + "/" + params}`,
    body,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(getDetail(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};


export const getUserDetail = (params: any) => (dispatch: Dispatch<any>) => {
  const body = "";
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.USER_DETAIL + "/" + params}`,
    body,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(getDetail(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};