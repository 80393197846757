import React from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./calender.scss"
const CalenderRange = ({onHandleChange,selectionRangeValue}:any) => {


  return (
    <DateRangePicker
      className="date-picker"
      ranges={[selectionRangeValue]}
      onChange={onHandleChange}
    />
  );
};

export default CalenderRange;
