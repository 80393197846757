import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  Field,
  FieldProps,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import { CommonSelectOptions, CommonSelectProps } from "./types";

const CommonSelect = <T,>({
  name,
  label,
  options,
  value,
  validate,
  searchable = false,
  multiple = false,
  onSelectionChange,
  style,
  disabled = false,
}: CommonSelectProps<T>) => {
  const [, meta] = useField({ name, validate });
  const { setFieldValue } = useFormikContext();
  const [searchValue, setSearchValue] = useState("");
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [selectedkey, setSelectedkey] = useState<any>([]);

  let filteredOptions: CommonSelectOptions[];
  if (options) {
    filteredOptions = options.filter(
      (option: CommonSelectOptions) =>
        option.value
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        option.key.toString().toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value.toString());
  };

  const handleSelectionChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selected = event.target.value as T[];
    console.log("selected", selected);
    if (!multiple && options?.length) {
      let selectedKey = options.find(
        (option: any) => option.value === selected
      );
      setSelectedkey(selectedKey);
    }

    setSelectedValues(selected);
    if (Array.isArray(selected)) {
      setFieldValue(name, JSON.stringify(selected)); // Remove escaped double quotes
    } else {
      setFieldValue(name, selected);
    }

    onSelectionChange && onSelectionChange(selected as string[], searchValue);
  };

  useEffect(() => {
    if (value && options?.length) {
      setSelectedValues(value);
      if (!multiple) {
        let selectedKey = options.find((option: any) => option.value === value);
        setSelectedkey(selectedKey);
      }
    }
  }, [value, options]); // eslint-disable-line react-hooks/exhaustive-deps

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl
          variant="outlined"
          error={meta.touched && !!meta.error}
          style={style}
        >
          {!value && <InputLabel>{label}</InputLabel>}
          <Select
            {...field}
            label={label}
            id={name}
            multiple={multiple}
            value={selectedValues ?? ""}
            onChange={handleSelectionChange as any}
            renderValue={(selected: any) =>
              multiple ? (selected as string[]).join(", ") : selectedkey?.key
            }
            style={{ borderRadius: "10px" }}
            disabled={disabled}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                  borderRadius: "10px",
                },
              },
            }}
          >
            {searchable && (
              <MenuItem
                value={""}
                onClickCapture={stopImmediatePropagation}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <TextField
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearchChange}
                  sx={{ width: "100%" }}
                />
              </MenuItem>
            )}

            {/* <MenuItem value="">{label}</MenuItem> */}
            {filteredOptions &&
              filteredOptions.map((option: CommonSelectOptions) => (
                <MenuItem key={option.key} value={option.value}>
                  {multiple ? (
                    <Checkbox
                      checked={selectedValues.includes(option.value as any)}
                    />
                  ) : (
                    ""
                  )}
                  <ListItemText primary={option.key ? option.key : ""} />
                </MenuItem>
              ))}
          </Select>
          <ErrorMessage name={name}>
            {(msg) => <FormHelperText>{msg}</FormHelperText>}
          </ErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default CommonSelect;
