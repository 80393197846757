export const GLOBAL_DATE_FILTER_DATA = [
  {
    key: "today",
    value: { fromDate: "", toDate: "" },
    name: "Today",
  },
  {
    key: "yesterday",
    value: { fromDate: "", toDate: "" },
    name: "Yesterday",
  },
  {
    key: "lastWeek",
    value: { fromDate: "", toDate: "" },
    name: "Last 7 days",
  },
  {
    key: "lastMonth",
    value: { fromDate: "", toDate: "" },
    name: "Last Month",
  },
  {
    key: "lastYear",
    value: { fromDate: "", toDate: "" },
    name: "Last 1 Year",
  },
  {
    key: "custom",
    value: { fromDate: "", toDate: "" },
    name: "Custom Selection",
  },
];

export const STATUS_DATA = [
  { name: "All", value: "all" },
  { name: "Activated", value: "active" },
  { name: "Deactivated", value: "inactive" },
];
