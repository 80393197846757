import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const styles = {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    backgroundColor: "#ffffff",
  };
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate("/");
  };
  return (
    <>
      <div className="page-not-found" style={styles}>
        <div className="col-sm-4 offset-sm-1">
          <img className="gif_icon" src="../../images/pageerror.gif" alt="" />
        </div>
        <div className="col-sm-6">
          <Typography variant="h1" color="textSecondary" mb={3}>
            404
          </Typography>
          <Typography variant="h3" color="textSecondary" mb={3}>
            Page not Found
          </Typography>
          <Typography variant="h5" color="textSecondary" mb={3}>
            Oops, We can't seem to find the page you're looking for....
          </Typography>
          <Button
            type="button"
            onClick={navigateTo}
            color="primary"
            variant="contained"
          >
            Back to Home
          </Button>
        </div>
      </div>
    </>
  );
}
