import { styles } from "./styles";

const Status = (props: any) => {
  const { status } = props;

  return (
    <>
      {status ? (
        <div style={styles.Activated}>Activated</div>
      ) : (
        <div style={styles.Deactivated}>Deactivated </div>
      )}
    </>
  );
};

export default Status;
