import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../../../common/utils";
import { setLoading } from "../../../../redux/global.slice";
import { apiSuccessCode } from "../../../../common/constants/axios.instance";
import { putApiCall } from "../../../../common/constants/api";
import * as URLS from "../../../../constants/url";
import { LOGIN } from "../../../../constants/routes/routes-path";
import { clearStorage} from "../../../../services/storage/storage-service";

export const onReset =
  (values: any, setSubmitting: any, navigate: any) =>
  (dispatch: Dispatch<any>) => {
    const { password } = values;
    const dataToSend = {
      password,
    };
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    putApiCall(
      `${URLS.RESET_PASSWORD}`,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;
        if (data?.statusCode === apiSuccessCode.success) {
          navigate(LOGIN.fullUrl);
          clearStorage();
        }
        dispatch(setLoading(false));
        setSubmitting(false);
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error?.data?.message);
      }
    );
  };
