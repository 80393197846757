import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const notificationHelper = () => {
  const dispatch = UseAppDispatch();
  const initialValue = {
    title: "",
    url: "",
    receipt:[],
    description: "",
  };

  const plateformList = [
    { name: "Android", value: false, key: "isAndroid" },
    { name: "IOS", value: false, key: "isIos" },
    { name: "Web", value: false, key: "isWeb" },
  ];

  const goBackFunction = () => {};

  return {
    initialValue,
    plateformList,
    dispatch,
    goBackFunction,
  };
};

export default notificationHelper;
