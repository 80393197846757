import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserDetail } from "../../../common/utils/model";
export const UserState: UserDetail = {
  detail: {},
};

const UserDataSlice = createSlice({
  name: "detail",
  initialState: UserState,
  reducers: {
    getDetail: (state: UserDetail, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
  },
});

export const { getDetail } = UserDataSlice.actions;

export default UserDataSlice.reducer;
