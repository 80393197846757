import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { showAlert } from "../../../common/utils";
import {
  setLoading,
  setPopUpLoading,
  setToken,
} from "../../../redux/global.slice";
import {
  getApiCall,
  patchApiCall,
  postApiCall,
  putApiCall,
} from "../../../common/constants/api";
import * as URLS from "../../../constants/url";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { userInfo } from "./userDetailSlice";
import { LOGIN } from "../../../constants/routes/routes-path";
import { clearStorage } from "../../../services/storage/storage-service";

import { userPermissionInfo } from "./userPermissionSlice";

export const getUserInfo = (params: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.PROFILE}`,
    params,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(userInfo(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};



export const getUserPermission = (params: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));
  getApiCall(
    `${URLS.ADMIN_PERMISSION}`,
    params,
    (response: AxiosResponse<any>) => {
      const { statusCode, result } = response.data;
      if (statusCode === apiSuccessCode.success) {

          //@ts-ignore
         result['USER']=['ADD', 'VIEW', 'EDIT', 'STATUS', 'DELETE'];
        dispatch(userPermissionInfo(result));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, dispatch(error.message));
    }
  );
};

export const updateProfile =
  (params: any, imagePayload: any, file: any, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    getApiCall(
      `${URLS.PROFILE_IMAGE}?action=${imagePayload?.action}&extension=${imagePayload?.extension}`,
      "",
      (response: AxiosResponse<any>) => {
        const { statusCode, result } = response.data;
        if (statusCode === apiSuccessCode.success) {
          let imagePath = result?.path;
          let imageUploadUrl = result?.url;

          const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "fileType" },
            body: file,
          };
          fetch(imageUploadUrl, requestOptions).then((res) => {
            if (res) {
              params.avatar = imagePath || "";
              patchApiCall(
                `${URLS.PROFILE}`,
                params,
                (response: AxiosResponse<any>) => {
                  const { statusCode, result } = response.data;
                  if (statusCode === apiSuccessCode.success) {
                    dispatch(userInfo(result));
                    showAlert(1, "Profile updated successfully");
                    if (callback) {
                      callback();
                    }
                  }
                  dispatch(setLoading(false));
                },
                (error: any) => {
                  dispatch(setLoading(false));
                  showAlert(2, error.message);
                }
              );
            }
          });
          // dispatch(userInfo(result));
          // showAlert(1, "Profile updated successfully");
          // if (callback) {
          //   callback();
          // }
        } else {
          dispatch(setLoading(false));
        }
        
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

export const directUpdateProfile =
  (params: any, callback: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
      patchApiCall(
        `${URLS.PROFILE}`,
        params,
        (response: AxiosResponse<any>) => {
          const { statusCode, result } = response.data;
          if (statusCode === apiSuccessCode.success) {
            dispatch(userInfo(result));
            showAlert(1, "Profile updated successfully");
            if (callback) {
              callback();
            }
          }
          dispatch(setLoading(false));
        },
        (error: any) => {
          dispatch(setLoading(false));
          showAlert(2, error.message);
        }
      );
  };

export const resetConfig =
  (userId: any, params: any, navigation: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setPopUpLoading(true));

    postApiCall(
      `${URLS.VERIFY_REST_PASSWORD}`,
      params,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          putApiCall(
            `${URLS.VERIFY_REST_MFA + "/" + userId}`,
            params,
            (response: AxiosResponse<any>) => {
              const { statusCode } = response.data;
              if (statusCode === apiSuccessCode.success) {
                clearStorage();
                dispatch(setToken(""));
                dispatch(setPopUpLoading(false));
                navigation(LOGIN.fullUrl);
                dispatch(setPopUpLoading(false));
              } else {
                dispatch(setPopUpLoading(false));
              }
            },
            (error: any) => {
              dispatch(setPopUpLoading(false));
              showAlert(2, error.message);
            }
          );
        } else {
          dispatch(setPopUpLoading(false));
        }
      },
      (error: any) => {
        dispatch(setPopUpLoading(false));
        showAlert(2, error.message);
      }
    );
  };
