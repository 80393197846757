import { IDatePicker } from "./type";
import moment from 'moment';
import styles from './style';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as DatePickerComponent } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography } from "@mui/material";
import { VALIDATION_MESSAGES } from "../../../../constants/messages";
import helperHandler from "./helper";
import { useEffect, useState } from "react";

function DatePicker({ disabled, readOnly, date, maxDate, minDate, disablePast, disableFuture, updateDate, required }: IDatePicker) {
  const { changeDate } = helperHandler();
  const [dateVal, setDate] = useState("");

  useEffect(() => {
    if (dateVal) {
      changeDate(dateVal, updateDate);
    }
    return () => {
      // Cleanup goes here
    };
  }, [date, dateVal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box style={styles.wrapper}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePickerComponent
          disabled={disabled}
          readOnly={readOnly}
          disableFuture={disableFuture}
          disablePast={disablePast}
          defaultValue={moment(date)}
          onChange={(newValue: any) => setDate(newValue)}
          minDate={moment(minDate)}
          maxDate={moment(maxDate)}
        />
      </LocalizationProvider>
      {required && !dateVal ? (
        <Typography component="p" color="red" sx={styles.required}>
          {VALIDATION_MESSAGES.required.field("Date")}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
}
export default DatePicker;