import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import "./style.scss";
// import { PLATEFORM } from "../../enums/plateform-type.enum";

const ShowImg: React.FC<any> = ({ name, img }) => {
  //   const [recipient, setRecipient] = useState<any>("");

  //   useEffect(() => {
  //     if (plateform) {
  //       const allowPlateform = Object.keys(plateform)
  //         .filter((key) => plateform[key])
  //         .map((key) => PLATEFORM[key]);

  //       if (allowPlateform.length > 0) {
  //         setRecipient(allowPlateform.join(", "));
  //       }
  //     }
  //   }, [plateform]);

  return (
    <div className="table-img">
      {/* {img} */}

      <Avatar
        alt="Avatar"
        src={img || ""}
        className="avatar"
        style={{
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: "#199ab0",
          color: "#ffffff",
          alignItems: "center",
        }}
      ></Avatar>
      <p>{name}</p>
    </div>
  );
};

export default ShowImg;
