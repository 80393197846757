import { Button } from "@mui/material";
import { Props } from "./type";

const ButtonControl: React.FC<Props> = ({ label, ...buttonProps }) => {
  return (
    <>
    <Button {...buttonProps}>{label}</Button>
  </>
  );
};

export default ButtonControl;
