import { Card, CardContent, Typography } from "@mui/material";
import { useState } from "react";
import { DEVICE_TYPE } from "../../enums/device-type.enum";
import "./google-authenticator.scss";

export default function GoogleAuthenticatorDevice() {
  const [icon, setIcon] = useState(DEVICE_TYPE.IOS);
  const onSelectedIcon = (icon: number) => {
    setIcon(icon);
  };
  return (
    <>
      <div className="google-authenticator-device">
        <Card variant="outlined" sx={{ minWidth: "500px", maxWidth: "500px" }}>
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              mb={2}
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              Admin Panel Plus
            </Typography>
            <div className="logo-line">
              <div className="left-line"></div>
              <img
                alt=""
                style={{ width: "50px" }}
                src="../../../images/google-authenticator.svg"
              />
              <div className="right-line"></div>
            </div>
            <Typography
              variant="body2"
              color="textSecondary"
              mb={3}
              style={{ textAlign: "center", fontWeight: "600" }}
            >
              Setup Google Authenticatior
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              mb={3}
              style={{ textAlign: "center" }}
            >
              Select your device
            </Typography>
            <div className="device-icon">
              <img
                alt="ios icon"
                src="../../../images/apple.png"
                style={{
                  opacity: icon === DEVICE_TYPE.IOS ? "1" : "0.6",
                }}
                onClick={() => onSelectedIcon(DEVICE_TYPE.IOS)}
              />
              <img
                alt="android icon"
                src="../../../images/android-logo.png"
                style={{
                  opacity: icon === DEVICE_TYPE.ANDROID ? "1" : "0.6",
                }}
                onClick={() => onSelectedIcon(DEVICE_TYPE.ANDROID)}
              />
              <img
                alt="window icon"
                src="../../../images/windows.png"
                style={{
                  opacity: icon === DEVICE_TYPE.WINDOWS ? "1" : "0.6",
                }}
                onClick={() => onSelectedIcon(DEVICE_TYPE.WINDOWS)}
              />
            </div>

            <hr />
            <Typography
              variant="body2"
              color="textSecondary"
              mb={3}
              style={{ textAlign: "center" }}
            >
              Install Google Authenticator
            </Typography>
            <div>
              <img
                alt=""
                style={{ width: "50px" }}
                src="../../../images/google-authenticator.svg"
              />
              <Typography
                variant="body2"
                color="textSecondary"
                mb={3}
                style={{ textAlign: "center" }}
              >
                Download{" "}
                <a
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Authenticatior from the App Store/Play Store
                </a>
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
