export const VALIDATION_CRITERIA = {
  emailMaxLength: 30,
  passwordMinLength: 8,
  passwordMaxLength: 40,
  nameMinLength: 2,
  nameMaxLength: 30,
  subTitleMinLength: 2,
  subTitleMaxLength: 150,
  titleMinLength: 2,
  titleMaxLength: 60,
  phoneMinLength: 8,
  phoneMaxLength: 14,
  locationMinLength: 2,
  locationMaxLength: 1000,
  priceMinLength: 1,
  priceMaxLength: 10,
  maxRange: 1000,
  codeMinLength: 6,
  otpMinLength: 4,
  otpMaxLength: 6,
  codeMaxLength: 6,
  faqQuestion: 150,
  faqAnswer: 1000,
  RoleDiscription:50
};
