export const styles = {
  btnWrapper: { display: "flex", gap: "15px" },
  cancelBtn: {
    borderColor: "#1ca2b7",
    color: "#1ca2b7",
    textTransform: "none",
    borderRadius: "10px",
    minWidth: "150px",
    width: "auto",
    padding: "10px 25px",
    cursor: "pointer",
    fontSize: "16px",
    "&:hover": {
      bgcolor: "#1ca2b7",
      color: "#ffffff",
      border: "#1ca2b7",
    },
    "&:disabled": {
      background: "rgba(0, 0, 0, 0.12)",
      color: "#767676",
    },
  },
  actionBtn: {
    bgcolor: "#1ca2b7",
    color: "#ffffff",
    borderRadius: "10px",
    textTransform: "none",

    cursor: "pointer",
    fontSize: "16px",
    "&:hover": {
      bgcolor: "#1ca2b7",
    },
    "&:disabled": {
      background: "rgba(0, 0, 0, 0.12)",
      color: "#767676",
    },
    minWidth: "150px",
    width: "auto",
    padding: "10px 25px",
  },
};
