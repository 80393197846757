import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ButtonControl from "../button/basic-button";
import styles from "./style";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import { useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";

import CalenderRange from "./calenderRange"
import FormButton from "../button/form-button";
const Menus = (props: any) => {
  const {
    handleClose,
    anchorEl,
    menus = [],
    label,
    selectMenu,
    value,
    onReset,
    onSubmit,
    showButton,
    isDropDown,
    showList,
    customMenu,
    onCancel,
    setCalenderValue,
    selectPlateform = () => {},
    platefroms = [],
    selectedPlatefrom = [],
    showPlateform = false,
    plateform,
    menuWidth,
  } = props;

  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();


  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  console.log(selectionRange,"selectionRange")
  const handleSelect = (ranges:any) => {
    console.log(ranges);

    setSelectionRange({
          startDate:ranges.selection.startDate,
          endDate: ranges.selection.endDate,
          key:'selection'
      });


      setFromDate(ranges.selection.startDate);
      setToDate(ranges.selection.endDate)
    
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      key={anchorEl ?? ""}
      PaperProps={{
        style: {
          width: customMenu ? "650px" : menuWidth || "auto", // Use the provided width or default to "auto"
          maxHeight: "80vh", // Adjust the max height if needed
        },
      }}
    >
      <div style={{ padding: "15px", width: "auto" }}>
        {label && (
          <div style={styles.headerContainer}>
            <Typography
              style={{
                fontSize: "larger",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Filter
            </Typography>

            <CloseIcon style={{ cursor: "pointer" }} onClick={onReset} />
          </div>
        )}

        <div style={styles.filterContainer}>
          <Typography>{label}</Typography>

          {isDropDown && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              label="Status"
              onChange={(event) => {}}
              style={{ width: "90%", margin: "10px 0px 0px 0px" }}
              key={value}
            >
              {menus.map((menu: any, index: number) => (
                <MenuItem
                  onClick={() => selectMenu(menu, index)}
                  value={menu.value}
                  key={menu.value}
                >
                  <Typography textAlign="left">{menu.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
        {showPlateform ? (
          <div>
            {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel
                id="demo-multiple-checkbox"
                style={{ color: "#767676", padding: "0px 0px" }}
              >
                Recipient
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                name={"receipt"}
                input={<OutlinedInput label={label} />}
                multiple
                value={selectedPlatefrom}
                onChange={(e) => {
                  selectPlateform(e.target.value);
                }}
                renderValue={(selected) => selected.join(", ")}
                fullWidth
              >
                {platefroms.map((plateform: any, index: number) => {
                  let { name, value } = plateform;
                  return (
                    <MenuItem key={index} value={name}>
                      <Checkbox
                        checked={value}
                        sx={{
                          "&.Mui-checked": {
                            color: "#1ca2b7",
                          },
                        }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        ) : null}
        {showList &&
          plateform &&
          menus.map((menu: any, index: number) => (
            <div onClick={() => selectMenu(menu, index)}>
              <div style={styles.container}>{menu.name}</div>
            </div>
          ))}

        {showList && customMenu && (
          <div>
            <div style={styles.datePickerContainer}>
              <CalenderRange
                onHandleChange={handleSelect}
                selectionRangeValue={selectionRange}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateRangeCalendar"]}>
                  <DateRangeCalendar
                    onChange={(newValue, value) => {
                      setFromDate(newValue[0]);
                      setToDate(newValue[1]);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider> */}
            </div>

            {/* <div style={styles.footerContainer}>
              <ButtonControl
                variant="contained"
                label="Apply Filter"
                color={"info"}
                type="submit"
                onClick={() => {
                  let from = new Date(fromDate);
                  let to = new Date(toDate);

                  setCalenderValue(from, to);
                }}
                sx={{
                  bgcolor: "#1ca2b7",
                  textTransform: "none",
                  "&:hover": {
                    bgcolor: "#1ca2b7",
                  },
                }}
              />
              <ButtonControl
                variant="outlined"
                label="Cancel"
                color={"info"}
                type="submit"
                onClick={onCancel}
                sx={{
                  borderColor: "#1ca2b7",
                  color: "#1ca2b7",
                  textTransform: "none",
                }}
              />
            </div> */}
            <FormButton
              cancelBtnClick={onCancel}
              cancelBtnLabel="Reset"
              actionBtnClick={() => {
                let from = new Date(fromDate);
                let to = new Date(toDate);

                setCalenderValue(from, to);
              }}
              actionBtnLabel={"Apply Filter"}
            />
          </div>
        )}
        {showList &&
          !customMenu &&
          menus.map((menu: any, index: number) => (
            <div onClick={() => selectMenu(menu, index)}>
              <div style={styles.container}>{menu.name}</div>
            </div>
          ))}
      </div>

      {showButton && (
        <div style={styles.footerContainer}>
          <FormButton
            cancelBtnClick={onReset}
            cancelBtnLabel="Reset"
            actionBtnClick={onSubmit}
          />
        </div>
      )}
      {/* </div> */}
    </Menu>
  );
};

export default Menus;
