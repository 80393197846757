import { combineReducers } from "@reduxjs/toolkit";
import globalSlice from "./global.slice";
import loginSlice from "../features/onboarding/login/services/login.slice";
import verifySlice from "../features/onboarding/verification-code/services/verify.slice";
import SubAdminModuleSlice from "../features/sub-admin/services/slice";
import roleSlice from "../features/sub-admin/services/roleSlice";
import detailSlice from "../features/sub-admin/services/detailSlice";
import RoleModuleSlice from "../features/roles/services/roleSlice";
import searchPermissionSlice from "../features/roles/services/searchPermissionSlice";
import RoleDetailSlice from "../features/roles/services/detailSlice";
import UserDetailSlice from "../features/admin/services/userDetailSlice";
import NotificationSlice from "../features/notification/services/listSlice";
import NotificationDetailSlice from "../features/notification/services/detailSlice";
import UserPermissionSlice from "../features/admin/services/userPermissionSlice"

import FaqModuleSlice from "../features/static-content/services/faqSlice";
import FaqDetailSlice from "../features/static-content/services/detailSlice";
import ContentDetailSlice from "../features/static-content/services/contentDetailSlice";
import UserModuleSlice from "../features/users/services/slice";
import UserDataSlice from "../features/users/services/detailSlice";



const rootReducer = combineReducers({
  login: loginSlice,
  globalcontent: globalSlice,
  verify: verifySlice,
  admins: SubAdminModuleSlice,
  roles: roleSlice,
  adminDetail: detailSlice,
  rolesModule: RoleModuleSlice,
  roleSearch: searchPermissionSlice,
  roleDetail: RoleDetailSlice,
  userInfo: UserDetailSlice,
  notification: NotificationSlice,
  notificationDetails: NotificationDetailSlice,
  userPermissionInfo: UserPermissionSlice,
  faqs: FaqModuleSlice,
  faqDetail: FaqDetailSlice,
  contentDetail: ContentDetailSlice,
  users: UserModuleSlice,
  userData:UserDataSlice,
});

export default rootReducer;
