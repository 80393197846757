import { useNavigate } from "react-router-dom";
import { SUB_ADMIN } from "../../../constants/routes/routes-path";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { Formik, Form } from "formik";
import Schema from "../../../common/constants/schema";
import { ChangeEvent, useEffect, useState } from "react";
import adminHelper from "./helper";
import { addSubAdmin, directaddSubAdmin, getRoles } from "../services/action";

import Grid from "@mui/material/Grid";
import { Avatar, Divider, IconButton, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { COUNTRIES } from "../../../common/constants/countries";
import InputTextField from "../../../components/form-controls/text";
import CommonSelect from "../../../components/form-controls/select";
import { Delete } from "@mui/icons-material";
import { IMAGE_EXT, IMAGE_FOLDER } from "../../../enums/uplaod_image.enum";
import { showAlert } from "../../../common/utils";
import {
  ArrowForwardIosIcon,
  InfoIcon,
  uploadIcon,
} from "../../../constants/images";
import ImageCropper from "../../admin/profile/image-cropper";
import "../profile.scss";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
import FormButton from "../../../components/form-controls/button/form-button";
export default function AddSubAdmin() {
  const { initialValue, dispatch } = adminHelper();
  const { data } = useAppSelector((state) => state.roles);
  const [roles, setRoles] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [chooseImage, setChooseImage] = useState<boolean>(false);
  const [cropImage, setCropImage] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [extension, setExtension] = useState<any>("");
  const navigation = useNavigate();
  //   const {  permissionData } = useAppSelector((state) => state.userPermissionInfo);

  //   useEffect(()=>{
  // if(permissionData !==undefined && permissionData.length>0){

  //   console.log(permissionData,"shashankpathak")
  // }

  //   },[permissionData])

  useEffect(() => {
    const rolelist = data.map((item) => {
      return {
        value: item._id,
        key: item.title,
      };
    });

    setRoles(rolelist);
  }, [data]);

  const [countryList, setCountryList] = useState<any>();

  useEffect(() => {
    let countries = COUNTRIES.map((country) => {
      return {
        value: country.code,
        key: country.code + " " + country.name,
      };
    });
    dispatch(getRoles());
    setCountryList(countries);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onBack = () => {
    navigation(SUB_ADMIN.fullUrl);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChooseImage(true);
    const file: any = event.target.files?.[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (IMAGE_EXT.includes(fileExtension)) {
      setExtension(fileExtension);
      setFile(file);
      if (file) {
        setShowModal(true);
        setCropImage(URL.createObjectURL(file));
      }
    } else {
      showAlert(2, "Please upload image in given formates");
    }
  };

  const deleteImage = () => {
    setFile("");
    setSelectedImage("");
  };

  const onSubmit = async (values: any) => {
    let profilePayload: any = {
      avatar: "",
      email: values.email,
      name: {
        first: values.firstName,
        last: values.lastName,
      },
      phoneNumber: values.phone.toString(),
      role: values?.role,
      countryCode: values.countryCode,
    };
    let imagePayload: any = {
      action: IMAGE_FOLDER.ADMIN,
      extension: extension,
    };
    if (chooseImage) {
      dispatch(addSubAdmin(profilePayload, imagePayload, file, onBack));
    } else {
      dispatch(directaddSubAdmin(profilePayload, onBack));
    }
  };
  return (
    <>
      <Box className="detail-wrapper" sx={{ width: "100%" }}>
        <div className="detail-container">
          <BreadCrumb
            mainLink={SUB_ADMIN.fullUrl}
            mainLabel={"Sub Admin Management"}
            secondaryLabel={"Add Sub Admin"}
            showMultipleLink={true}
          />
        </div>
        <Box
          sx={{
            display: "grid",
            boxShadow: "none",
            padding: 5,
            borderRadius: 2,
            background: "#ffffff",
          }}
        >
          <div className="sub-title">Sub Admin Details</div>
          <Divider className="role-divider" sx={{ margin: "20px 0px" }} />

          <hr />
          <div className="upload-image">
            <input
              accept="image/*"
              id="image-input"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
            <div className="avatar-container">
              <label className={"avatar-label"} htmlFor="image-input">
                <Avatar
                  alt="Avatar"
                  src={selectedImage || uploadIcon}
                  className="avatar"
                ></Avatar>
              </label>

              {selectedImage && (
                <IconButton onClick={deleteImage} className="cancel-btn">
                  <Delete />
                </IconButton>
              )}
            </div>
            <div className="pop-title">
              <InfoIcon />
              <span>
                JPEG,PNG,JPG format are allowed with maximum size of 1 MB
              </span>
            </div>
          </div>
          <Formik
            validateOnMount ={true}
            initialValues={initialValue}
            onSubmit={onSubmit}
            validationSchema={Schema.AdminSchema}
          >
            {({ errors, touched, isValid }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <InputLabel className="input-label-title">
                      Phone Number
                    </InputLabel>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={3} sm={3}>
                        <CommonSelect
                          name="countryCode"
                          label="Code"
                          options={countryList}
                          searchable={true}
                          multiple={false}
                          onSelectionChange={(selected, searchValue) => {}}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={9} sm={9}>
                        <InputTextField
                          name="phone"
                          variant="outlined"
                          type="text"
                          label="Phone number"
                          required={false}
                          showlabel={false}
                          numberOnly={true}
                          InputProps={{
                            style: { height: "56px" },
                          }}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={12} sm={5} item>
                    <InputTextField
                      name="email"
                      label="Email"
                      variant="outlined"
                      type="email"
                      fullWidth
                    />
                  </Grid>

                  <Grid xs={12} sm={12} item>
                    <InputLabel className="input-label-title">
                      Role and Access*
                    </InputLabel>
                    <div>
                      <CommonSelect
                        name="role"
                        label="Role"
                        options={roles}
                        searchable={true}
                        multiple={false}
                        onSelectionChange={(selected, searchValue) => {
                          // Handle the selected values and search text in your parent component
                        }}
                        style={{ width: "40%" }}
                      />
                    </div>
                  </Grid>

                  <Grid xs={12} sm={12} item>
                    <FormButton
                      cancelBtnClick={onBack}
                      actBtnDisabled={!isValid}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      <ImageCropper
        setCropImage={setCropImage}
        cropImage={cropImage}
        setShowModal={setShowModal}
        showModal={showModal}
        setSelectedImage={setSelectedImage}
        file={file}
        setFile={setFile}
      />
    </>
  );
}
