import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const adminHelper = (isEdit: any, detail: any) => {

  const dispatch = UseAppDispatch();
  
  let initialValue = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    role: "",
    countryCode: "",
  };

  const goBackFunction = () => {};

  const updateValue = (detail: any) => {
    initialValue.email = detail.email;
  };
  return {
    initialValue,
    dispatch,
    goBackFunction,
    updateValue,
  };
};

export default adminHelper;
