import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const adminHelper = () => {
  const dispatch = UseAppDispatch();
  const initialValue = {
      'email':'',
      'firstName':'',
      'lastName':'',
      'phone':'',
      'role':'',
      'countryCode':''
  };

  const goBackFunction = () => {
    
  };

  return {
    initialValue,
    dispatch,
    goBackFunction,
  };
};

export default adminHelper;
