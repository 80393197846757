// import React, { useEffect, useState } from "react";
// import "./loader.scss";
// import LoadingBar from "react-top-loading-bar";
// import loaderEvent from "../events/loader.events";

// const Loader = () => {
//   const [progress, setProgress] = useState(0);
//   useEffect(() => {
//     const handleEvent = (eventData: any) => {
//       setProgress(eventData);
//     };

//     loaderEvent.on("showLoader", handleEvent());

//     return () => {
//       loaderEvent.off("showLoader", handleEvent);
//     };
//   }, []);
//   return (
//     <LoadingBar
//       color="#f11946"
//       progress={progress}
//       onLoaderFinished={() => setProgress(1)}
//     />
//   );
// };

// export default Loader;
import { Box, CircularProgress } from "@mui/material";
// import { LoaderImg, LoaderLogo } from "../../utils/common/constants/images";
import { useAppSelector } from "../../hooks/useAppSelector";
import "./loader.scss";
import { useEffect } from "react";

const PopUpLoader = () => {
  const { loading } = useAppSelector((state) => state.globalcontent);

  useEffect(() => {}, [loading]);

  return (
    <Box
      className="loaderPopContainer"
   
    >
      <CircularProgress disableShrink />
    </Box>
  );
};

export default PopUpLoader;
