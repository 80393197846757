import {createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { NotificationResult } from "../../../common/utils/model";
export const notificationInitialState: NotificationResult = {
  data: [],
  page: 0,
  limit: 0,
  count: 0,
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitialState,
  reducers: {
    notification: (state: NotificationResult, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.count = action.payload.total;
    },
  },
});

export const { notification } = NotificationSlice.actions;

export default NotificationSlice.reducer;
