import { setVerifyDetails } from "./services/verify.slice";
import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const verifyHelper = () => {
  const dispatch = UseAppDispatch();
  const verifyInitialValue = {
    otp:"",
    authToken:"",
    refreshToken:""
  };

  const goBackFunction = () => {
    dispatch(
      setVerifyDetails({
        // otp: "",
        authToken:"",
        refreshToken:""
      })
    );
  };

  return {
    verifyInitialValue,
    dispatch,
    goBackFunction,
  };
};

export default verifyHelper;
