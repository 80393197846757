import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RoleResult } from "../../../common/utils/model";
export const roleInitialState: RoleResult = {
  data: [],
  page: 0,
  limit: 0,
  count: 0,
};



const RoleSlice = createSlice({
  name: "roles",
  initialState: roleInitialState,
  reducers: {
    allRoles: (state: RoleResult, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});


export const { allRoles } = RoleSlice.actions;

export default RoleSlice.reducer;
