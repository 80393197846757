import { Outlet, Navigate } from "react-router-dom";
import { SUB_ADMIN } from "../constants/routes/routes-path";
import { getAccessToken } from "../services/storage/storage-service";
//public route
const PublicRoutes = () => {
  let token = getAccessToken();
  return !token ? <Outlet /> : <Navigate to={SUB_ADMIN.fullUrl} />;
};

export default PublicRoutes;
