export const getInitials = (value: string) => {
  let initials;
  if (value) {
    const names = value.split(" ");
    initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
  }
  return initials;
};

export const FontFamily = (
  fontWeight: number,
  fontSize: number | string,
  lineHeight: number | string,
  fontStyle = "normal",
  fontFamily = `SofiaPro`
) => {
  let fontsize = "";
  let lineheight = "";

  if (typeof fontSize === "string") fontsize = fontSize;
  else fontsize = `${fontSize}px`;

  if (typeof lineHeight === "string") lineheight = lineHeight;
  else lineheight = `${lineHeight}px`;

  return `${fontStyle} ${fontWeight} ${fontsize}/${lineheight} ${fontFamily}`;
};

export const getImageSizeError = (size: number) => {
  return `Image size must not exceed ${size} mb.`;
};
export const getImageSizeErrorRange = (min: number, max: number, unit = "mb") => {
  return `Image size must be between ${min} ${unit} to ${max} ${unit}.`;
};

export const isValidImage = async ({ file, minHeight, maxHeight, minWidth, maxWidth }: any) => {
  const reader = new FileReader();

  return new Promise((resolve) => {
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = (evt: any) => {
        const fileHeight = evt.target.height;
        const fileWidth = evt.target.width;
        if (
          fileHeight < minHeight ||
          fileHeight > maxHeight ||
          fileWidth < minWidth ||
          fileWidth > maxWidth
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
    };
  });
};


export const capitalizeFirstLetter = (str: string) => {
  if (!str) {
    return "";
  }
  const arr = str.split(" ");
  arr.forEach((element, index) => {
    arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1);
  });
  const str2 = arr.join(" ");
  return str2;
};

export const generateRange = (to: number) => {
  const list = [];
  for (let i = 0; i <= to; i += 1) {
    list.push({ id: i, value: i });
    if (i > 50) {
      break;
    }
  }
  return list;
};
