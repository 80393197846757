import { COLORS } from "../../../common/constants/colors";
import { FontFamily } from "../../../common/utils";

const styles = {
  profileImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
  },
  profileContainer: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#d8eded",
    margin: "0px",
    padding: "10px",
  },
  avtar: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    backgroundColor: "#199ab0",
    color: "#ffffff",
  },
  textColor: {
    color: COLORS.darkGrey,
  },
  emailColor: {
    color: COLORS.black,
    fontWeight: "700",
  },
  accessColor: {
    color: COLORS.primaryBlue,
  },
  lastLoginColor: {
    color: COLORS.darkGrey,
    display: "flex",
    justifyContent: "end",
    padding: "10px",
  },
  next: {
    font: "normal normal 600 14px/18px SofiaPro",
    cursor: "pointer",
    marginLeft: "10px",
    color: COLORS.primaryBlue,
  },
  count: {
    font: FontFamily(600, 14, 14),
    color: "#000000",
  },
  disabled: {
    color: COLORS.actionPopupBorder,
    cursor: "auto",
  },
  pageBlock: {},
};

export default styles;
