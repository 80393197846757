import {createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RolesDetail } from "../../../common/utils";
export const RoleDetail: RolesDetail = {
  detail: {
    permissions: []
  },
};

const RoleDetailSlice = createSlice({
  name: "roleDetail",
  initialState: RoleDetail,
  reducers: {
    roleDetail: (state: RolesDetail, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
  },
});

export const { roleDetail } = RoleDetailSlice.actions;

export default RoleDetailSlice.reducer;
