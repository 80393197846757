
const helperHandler = () => {
    const changeDate = (dateValue: any, updateDate: any) => {
        let value = dateValue?._d;
        updateDate(value);
    }

    return {
        changeDate
    }

};

export default helperHandler;