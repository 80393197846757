import * as Yup from "yup";
import { VALIDATION_CRITERIA } from "../../constants/validation-criteria";
import { PATTERN } from "../../constants/patterns";
import { PATTERN_ERRORS } from "../../constants/error";
import moment from "moment";

/**
 * Sign in Form Validation Schema
 */
const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Please enter the required field")
      .email("Please enter a valid email address"),
    password: Yup.string().trim().required("Please enter the required field"),
  });

const AdminSchema = () =>
  Yup.object().shape({
    firstName: Yup.string().trim().required("Please enter the required field"),
    lastName: Yup.string().trim().required("Please enter the required field"),
    email: Yup.string()
      .trim()
      .required("Please enter the required field")
      .email("Please enter a valid email address"),
    role: Yup.string().trim().required("Please select the required field"),

    phone: Yup.number().when("countryCode", ([countryCode], schema) => {
      return countryCode
        ? schema.required("Please enter the required field")
        : schema.notRequired();
    }),
  });

  

const RoleSchema = () =>
  Yup.object().shape({
    title: Yup.string().trim().required("Please enter the required field"),
    discription: Yup.string().trim(),
    action: Yup.array(),
    // role: Yup.string().trim().required("Please select the required field"),
  });

const VerifySchema = () =>
  Yup.object().shape({
    otp: Yup.string()
      .trim()
      .required("Please enter the required field")
      .max(VALIDATION_CRITERIA.codeMinLength)
      .min(VALIDATION_CRITERIA.codeMinLength),
  });

const ForgotSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required("Please enter the required field")
      .email("Please enter a valid email address"),
  });

const ResetSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .required()
      .min(VALIDATION_CRITERIA.passwordMinLength)
      .max(VALIDATION_CRITERIA.passwordMaxLength)
      .matches(PATTERN.password, {
        message: PATTERN_ERRORS(PATTERN.password, "Password"),
      }),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
  });

const DateSchema = () => {
  return Yup.object({
    startDate: Yup.string()
      .test("startDate", "Start date is required", (value, context) => {
        if (!context.parent.startDate && context.parent.endDate) {
          return false;
        }
        return true;
      })
      .test(
        "endDate",
        "Start date should be lower or equal to end date",
        (value, context) => {
          if (context.parent.endDate && context.parent.startDate) {
            return moment(context.parent.endDate).isSameOrAfter(
              context.parent.startDate
            );
          }
          return true;
        }
      )
      .nullable(),
    endDate: Yup.string()
      .test("endDate", "End date is required", (value, context) => {
        if (!context.parent.endDate && context.parent.startDate) {
          return false;
        }
        return true;
      })
      .test(
        "endDate",
        "End date should be greater or equal to start date",
        (value, context) => {
          if (context.parent.endDate && context.parent.startDate) {
            return moment(context.parent.endDate).isSameOrAfter(
              context.parent.startDate
            );
          }
          return true;
        }
      )
      .nullable(),
  });
};
const profileSchema = () =>
  Yup.object().shape({
    firstName: Yup.string().trim().required("Please enter the required field"),
    lastName: Yup.string().trim().required("Please enter the required field"),
    // phone: Yup.string().trim().required("Please enter the required field"),
    // countryCode: Yup.string().required("Please enter the required field"),
    email: Yup.string()
      .trim()
      .required("Please enter the required field")
      .email("Please enter a valid email address"),
    // role: Yup.string().trim().required("Please select the required field"),
    phone: Yup.number().when("countryCode", ([countryCode], schema) => {
      return countryCode
        ? schema.required("Please enter the required field")
        : schema.notRequired();
    }),
  });

  const FaqSchema = () =>
    Yup.object().shape({
      question: Yup.string().trim().required("Please enter the required field"),
      answer: Yup.string().trim(),
    });

const ChangePasswordSchema = () =>
  Yup.object().shape({
    oldPassword: Yup.string().required(),
    password: Yup.string()
      .required()
      .min(VALIDATION_CRITERIA.passwordMinLength)
      .max(VALIDATION_CRITERIA.passwordMaxLength)
      .matches(PATTERN.password, {
        message: PATTERN_ERRORS(PATTERN.password, "Password"),
      }),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
  });

const ResetConfigSchema = () =>
  Yup.object().shape({
    password: Yup.string().required(),
  });
const NotificationSchema = () =>
  Yup.object().shape({
    title: Yup.string().trim().required("Please enter the required field"),
    // url: Yup.string().trim().required("Please enter the required field"),
    receipt: Yup.array()
      .of(Yup.string().required("Item is required"))
      .min(1, "At least one plateform is required"),
    description: Yup.string().required("Please enter the required field"),
  });

const Schema = {
  AdminSchema,
  LoginSchema,
  VerifySchema,
  ForgotSchema,
  ResetSchema,
  DateSchema,
  RoleSchema,
  profileSchema,
  ChangePasswordSchema,
  ResetConfigSchema,
  NotificationSchema,
  FaqSchema
};

export default Schema;
