import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { InfoIcon } from "../../../constants/images";
import { Form, Formik } from "formik";
import PasswordField from "../../../components/form-controls/text-password";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { Stack, Grid } from "@mui/material";
import { ConfirmPopUpProps } from "./type";
import Schema from "../../../common/constants/schema";
import profileHelper from "./helper";
import { resetConfig } from "../services/action";
import { useNavigate, } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useAppSelector";
import PopUpLoader from "../../../shared/loader/popUpLoader";
import "./profile.scss"
import FormButton from "../../../components/form-controls/button/form-button";
const ConfirmPopUp: React.FC<ConfirmPopUpProps> = ({ showModal = false, setShowModal }) => {
  const { passwordInitialValue, dispatch } = profileHelper(true, "");
   const { popUpLoading } = useAppSelector((state) => state.globalcontent);
   const { detail } = useAppSelector((state) => state.userInfo);
    const navigation = useNavigate();
  const closeDialog = () => {
    setShowModal(false);
  };

  const onSubmit = async (values: any) => {
    let userId = detail?._id;
    let passwordPayload: any = {
      currentPassword: values.password,
    };
  
        dispatch(resetConfig(userId,passwordPayload, navigation));
    };
  
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={showModal}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
        className="pop-up-container"
      >
        <DialogTitle className="pop-up-wapper">
          <DialogTitle className="pop-title">Verify Password</DialogTitle>
          <div className="pop-title">
            <InfoIcon style={{ color: "rgb(255, 165, 0)" }} />
            <span>
              To reset your authenticator you need to enter your password
            </span>
          </div>
        </DialogTitle>
        {popUpLoading ? (
          <PopUpLoader />
        ) : (
          <DialogContent>
            <div>
              <Formik
                initialValues={passwordInitialValue}
                onSubmit={onSubmit}
                validationSchema={Schema.ResetConfigSchema}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <Stack className="pass-form-wrapper" spacing={2}>
                      <PasswordField
                        name="password"
                        label="Password"
                        type="password"
                      ></PasswordField>
                    </Stack>
                    <Grid xs={12} sm={12} item style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
                  

                      <FormButton
                        cancelBtnClick={closeDialog}
                        actionBtnLabel="Verify"
                        actBtnDisabled={!isValid || !dirty}
                      />
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default ConfirmPopUp;
