import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LoginModel } from "../../../../common/utils";

const initialState: LoginModel = {
  mfaToken: "",
  qrCodeURL: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginDetails: (state: LoginModel, action: PayloadAction<LoginModel>) => {
      state.mfaToken = action.payload.mfaToken
      state.qrCodeURL = action.payload.qrCodeURL
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoginDetails } = loginSlice.actions;

export default loginSlice.reducer;
