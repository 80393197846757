import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SearchPermissionResult } from "../../../common/utils/model";
export const searchInitialState: SearchPermissionResult = {
  data: [],
};

const SearchPermissionSlice = createSlice({
  name: "searchPermission",
  initialState: searchInitialState,
  reducers: {
    search: (state: SearchPermissionResult, action: PayloadAction<any>) => {
      state.data = action.payload.data;
    },
  },
});

export const { search } = SearchPermissionSlice.actions;

export default SearchPermissionSlice.reducer;
