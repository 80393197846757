import { $axios } from "../constants/axios.instance";

/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = (token?: string) => {
  if (token) {
    $axios.defaults.headers.common["access-token"] = token;
  } else {
    $axios.defaults.headers.common["access-token"] = "";
    delete $axios.defaults.headers.common["access-token"];
  }
};

export const removeSession = () => {
  localStorage.clear();
  setAuthorizationToken("");
  window.location.reload();
};

/**
 * get user access token form localstorage
 */
export const getSetAccessToken = () => {
  const accessToken = localStorage.getItem("auth_token");
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }
  return accessToken;
};

/**
 * get user access token form localStorage
 */
export const setAccessToken = (accessToken: any) => {
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }
  return accessToken;
};

/**
 * get user id form localStorage
 */
export const getAccessToken = () => {
  return localStorage.getItem("auth_token");
};

/**
 * get mfaToken form localStorage
 */
export const getMFAtoken = () => {
  return localStorage.getItem("mfaToken");
};
