import axios from "axios";
import { Env } from "../../constants/env";
import {
  get2FAToken,
  getAccessToken,
  getAuthBase64Key,
  getRefreshToken,
  setAccessToken,
} from "../../services/storage/storage-service";
import { REFRESH } from "../../constants/url";
// import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../constants/routes/routes-path";

export const API_URL = Env.BASE_URL;
const REFRESH_TOKEN_URL = REFRESH;

export const apiErrorCode = {
  INVALID_TOKEN: 419,
  ACCOUNT_BLOCKED: 423,
  ACCESS_DENIED: 430,
  SESSION_EXPIRED: 440,
  INTERNAL_ERROR: 500,
  NOT_AUTHORIZED: 403,
  USER_BLOCKED: 401,
};

export const apiSuccessCode = {
  success: 200,
};

export const $axios = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    // platform: 3,
    // timezone: new Date().valueOf(),
  },
});

export const $axios1 = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

$axios.interceptors.request.use(
  (config: any) => {
    const authToken = getAccessToken();
    const authKey = getAuthBase64Key();
    const mfaToken = get2FAToken();

    if (authKey) {
      config.headers["authorization"] = `Basic ${btoa(authKey)}`;
    }
    if (mfaToken) {
      config.headers["authorization"] = `Bearer ${mfaToken}`;
      // headers["Authorization"] = `Bearer ${mfaToken}`;
    }
    if (authToken) {
      // request.headers["Access-Token"] = authToken;
      config.headers["authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
let isRefreshing = false;
let refreshQueue: ((token: string) => void)[] = [];

// $axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     let newToken: any;
//     if (
//       error.config.method === "post" ||
//       error.config.method === "put" ||
//       error.config.method === "patch" ||
//       error.config.method === "delete"
//     ) {
//       if (!isRefreshing) {
//         isRefreshing = true;
//         // Call your refresh token API to get a new token
//         const refreshToken = getRefreshToken();
//         newToken = await callRefreshTokenAPI(refreshToken);

//         if (newToken) {
//           // Update the local storage with the new token
//           setAccessToken(newToken);
//           // Retry the queued requests with the new token
//           refreshQueue.forEach((resolve) => resolve(newToken));
//           refreshQueue = [];
//           // Continue the original request
//           return $axios(error.config);
//         } else {
//           // const navigate = useNavigate();
//           // navigate(LOGIN.fullUrl);
//           window.location.href = LOGIN.fullUrl;
//           // Handle token refresh failure, e.g., log out the user
//         }
//       } else {
//         // If a token refresh is already in progress, queue the original request
//         return new Promise((resolve) => {
//           refreshQueue.push((token) => {
//             // originalRequest.headers['Authorization'] = `Bearer ${token}`;
//             // resolve(authFetch(originalRequest));
//             originalRequest.headers["Authorization"] = `Bearer ${token}`;
//             resolve($axios(originalRequest));
//           });
//         });
//       }
//       // showAlert(5, error?.response?.data?.message);
//     }
//     return Promise.reject(error);
//   }
// );


$axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const status = error.response.status;

    // Check if error is due to unauthorized access (token expired)
    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Call your refresh token API to get a new token
          const refreshToken = getRefreshToken();
          const newToken = await callRefreshTokenAPI(refreshToken);

          if (newToken) {
            // Update the local storage with the new token
            setAccessToken(newToken);
            // Retry the queued requests with the new token
            refreshQueue.forEach((resolve) => resolve(newToken));
            refreshQueue = [];
            // Continue the original request
            return $axios(error.config);
          } else {
            // Handle token refresh failure, e.g., log out the user
            window.location.href = LOGIN.fullUrl;
          }
        } catch (refreshError) {
          // Handle token refresh error, e.g., log out the user
          window.location.href = LOGIN.fullUrl;
        } finally {
          isRefreshing = false;
        }
      } else {
        // If a token refresh is already in progress, queue the original request
        return new Promise((resolve) => {
          refreshQueue.push((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve($axios(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

// Function to call the refresh token API
async function callRefreshTokenAPI(
  refreshToken: string | null
): Promise<string | null> {
  try {
    const response = await axios.post(REFRESH_TOKEN_URL, { refreshToken });
    return response.data.accessToken;
  } catch (error) {
    // Handle token refresh error, e.g., log out the user
    return null;
  }
}
