export enum NextStep {
    None = '0',
    Verify = '1',
    Setup = '2',
  }
  
  export enum UserType {
    Admin = '1',
    Driver = '2',
    Customer = '3',
  }
  
  export enum Platform {
    Ios = '1',
    Android = '2',
    Web = '3',
  }
  
  export enum MFAPlatform {
    GoogleAuth = '1',
    MicrosoftAuth = '2',
  }
  
  export enum Language {
    En = 'EN',
    Ar = 'AR',
    Fr = 'FR',
  }
  