import "./App.css";
import RoutesConfig from "./constants/routes/routes";
import { ToastContainer } from "react-toastify";
import Loader from "./shared/loader/loader";
import { getAccessToken } from "./services/storage/storage-service";
import { useEffect } from "react";
import { UseAppDispatch } from "./hooks/useAppDispatch";
import { setToken } from "./redux/global.slice";
import "./common/common.scss";
import "./common/common-table.scss";

function App() {
  const accessToken = getAccessToken();
  const dispatch = UseAppDispatch();
  useEffect(() => {
    if (accessToken) {
      dispatch(setToken(accessToken));
    }
  });
  return (
    <>
      <Loader />
      <ToastContainer />
      <RoutesConfig />
    </>
  );
}

export default App;
