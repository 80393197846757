import { UseAppDispatch } from "../../../hooks/useAppDispatch";
const faqHelper = () => {
  const dispatch = UseAppDispatch();
  const initialValue = {
    question: "",
    answer: "",
  };



  const goBackFunction = () => {};

  return {
    initialValue,
    dispatch,
    goBackFunction,
  };
};

export default faqHelper;
