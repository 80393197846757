import React, { useImperativeHandle } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./dialog.scss";
import ButtonControl from "../../components/form-controls/button/basic-button";
import FormButton from "../../components/form-controls/button/form-button";
const ConfirmDialog = React.forwardRef(
  (
    props: {
      title: string;
      message: string;
      confirmButtonText: string;
      cancelButtonText: string;
      logoutUser?: any;
    },
    ref: any
  ) => {
    const [open, setOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
      openDialog,
    }));

    const openDialog = () => {
      setOpen(true);
    };

    const closeDialog = () => {
      setOpen(false);
    };

    const logoutUser = () => {
      setOpen(false);
      props.logoutUser(true);
    };

    return (
      <div>
        <Dialog
          open={!!open}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth={true}
          className="pop-up-wrapper"
        >
          <DialogTitle className="pop-up-title">{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText className="pop-up-msg">
              {props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="pop-up-btn">
            <FormButton
              cancelBtnClick={closeDialog}
              cancelBtnLabel={props.cancelButtonText}
              actionBtnLabel={props.confirmButtonText}
              actionBtnClick={logoutUser}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default ConfirmDialog;
