import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppSelector } from "../../../hooks/useAppSelector";
import PopUpLoader from "../../../shared/loader/popUpLoader";
import { Cropper } from "react-cropper";
import { Button } from "@mui/material";
import "./profile.scss";

const ImageCropper: React.FC<any> = ({
  showModal = false,
  setShowModal,
  setCropImage,
  cropImage = "",
  setSelectedImage,
  setFile,
}) => {
  const { popUpLoading } = useAppSelector((state) => state.globalcontent);
  const cropperRef = useRef<any>(null);
  const closeDialog = () => {
    setShowModal(false);
  };

  const handleCrop = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current?.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();
      setSelectedImage(croppedCanvas.toDataURL());
      croppedCanvas.toBlob((blob: Blob | null) => {
        if (blob) {
          setFile(blob);
        }
      });

      setShowModal(false);
    }
  };

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle className="pop-up-wapper">
          <DialogTitle className="pop-title">Crop Image</DialogTitle>
        </DialogTitle>
        {popUpLoading ? (
          <PopUpLoader />
        ) : (
          <DialogContent>
            <div className="image-crop-wrapper">
              <Cropper
                className="cropper"
                ref={cropperRef}
                src={cropImage}
                style={{ height: 300, width: "80%", background: "white", marginBottom:"10px" }}
                aspectRatio={1 / 1}
                guides={true}
                cropBoxResizable={true}
                zoomTo={0.5}
                initialAspectRatio={1}
                viewMode={1}
                minCropBoxHeight={150}
                minCropBoxWidth={150}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
              />
              <button type="submit" onClick={handleCrop} className="action-btn btn-small">
                Crop
              </button>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default ImageCropper;
