import {createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { NotificationDetails, } from "../../../common/utils";
export const NotificationDetail: NotificationDetails = {
  detail: {},
};

const NotificationDetailSlice = createSlice({
  name: "notificationDetails",
  initialState: NotificationDetail,
  reducers: {
    notificationDetail: (
      state: NotificationDetails,
      action: PayloadAction<any>
    ) => {
      state.detail = action.payload;
    },
  },
});

export const { notificationDetail } = NotificationDetailSlice.actions;

export default NotificationDetailSlice.reducer;
