import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { styles } from "./styles";
import TableComponent from "../../../components/tableList";
import { TABLES } from "../../../constants/table-columns";
import {
  deleteUserNotification,
  getNotification,
  sendUserNotification,
} from "../services/action";
import { useAppSelector } from "../../../hooks/useAppSelector";
import TuneIcon from "@mui/icons-material/Tune";
import moment from "moment";
import Menus from "../../../components/form-controls/menu";
import { GLOBAL_DATE_FILTER_DATA } from "../../../constants/filter.data";
import { DateRangeIcon } from "@mui/x-date-pickers-pro";
import { useNavigate } from "react-router-dom";
import { ADD_NOTIFICATION, NOTIFICATION } from "../../../constants/routes/routes-path";
import ConfirmDialog from "../../../shared/dialogs/confirm-dialog";
import { Notification } from "../../../interface/notification.interface";
import notificationHelper from "../add-notification/helper";
import { ClearIcon, InfoIcon } from "../../../constants/images";
import "../../../common/common-table.scss";
import { SearchBar } from "../../../components/form-controls/searchBar";
import BreadCrumb from "../../../components/form-controls/breadCrumb";

export default function NotificationList() {
  const navigation = useNavigate();
  const deleteRef = useRef();

  function createData(
    description: string,
    createdAt: string,
    id: string,
    isSend: boolean,
    platform: any,
    title: string,
    _id: string
  ) {
    return {
      description,
      createdAt,
      id,
      isSend,
      platform,
      title,
      _id,
    };
  }

  const defaultDimensions = { width: "400px", height: "270px" };
  const dateDimensions = { width: "160px", height: "250px" };

  const filterData = GLOBAL_DATE_FILTER_DATA;
  const { plateformList, dispatch } = notificationHelper();
  const queryParamData = {};
  const sortData = {};
  const [notifications, setList] = useState<Notification[]>();
  const { data, count } = useAppSelector((state) => state.notification);
  const [searchText, setSearch] = useState("");
  const [toDate, setToDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>();
  const [selectedFilter, setFilter] = useState("all");
  const [isDate, setIsDate] = useState(false);
  const [dimensions, setDimensions] = useState(defaultDimensions);
  const [isCustomMenu, setCustomMenu] = useState(false);
  const [selectedDateType, setDateType] = useState<string>();
  const [selectedNotification, setSelectedNotification] = useState<any>();
  const [platefroms, setPlatefroms] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedPlatefrom, setSelectedPlatefrom] = useState<string[]>([]);

  const { permissionData } = useAppSelector(
    (state) => state.userPermissionInfo
  );

  useEffect(() => {
    let from = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
    let to = moment(new Date()).format("YYYY-MM-DD");

    let dateType = filterData.find((data) => data.key === "lastWeek");
    setDateType(dateType?.name);
    setFromDate(from);
    setToDate(to);
    fetchNotification();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRefresh = () => {
    fetchNotification();
  };

  useEffect(() => {
    setPlatefroms(plateformList);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchNotification = (reset: boolean = false) => {
    let obj: any = {
      limit: rowsPerPage,
      page: page,
      search: "",
      toDate: "",
      fromDate: "",
    };
    console.log("platefroms", platefroms);
    platefroms
      .filter((item: any) => item.value)
      .forEach((item: any) => {
        obj[item.key] = true;
      });
    if (selectedFilter !== "all" && !reset) {
      obj["isActive"] = selectedFilter === "active" ? true : false;
    }

    if (searchText && searchText.trim() !== "") {
      obj["search"] = searchText.toLowerCase();
    }
    if (fromDate && toDate) {
      obj["fromDate"] = fromDate;
      obj["toDate"] = toDate;
    }
    const queryParams = {
      ...queryParamData,
      ...obj,
      ...sortData,
    };

    function objToQueryString(obj: any) {
      var k = Object.keys(obj);
      var s = "?";
      for (var i = 0; i < k.length; i++) {
        s += k[i] + "=" + obj[k[i]];
        if (i !== k.length - 1) s += "&";
      }
      return s;
    }

    const params = objToQueryString(queryParams);
    setAnchorEl(null);

    if (obj["fromDate"] !== "" && obj["toDate"] !== "") {
      dispatch(getNotification(params));
    }
  };

  useEffect(() => {
    fetchNotification();
  }, [fromDate, toDate, rowsPerPage, page, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      let notification = data.map((item: any) => {
        let createdDate = moment(new Date(item?.createdAt)).format("LLL");
        let data = createData(
          item.description,
          createdDate,
          item.id,
          item.isSend,
          item.platform,
          item.title,
          item._id
        );
        return data;
      });
      setList(notification);
    }
  }, [data]);

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsDate(false);
    setDimensions(defaultDimensions);
    setAnchorEl(event.currentTarget);
    setCustomMenu(false);
  };
  const onDate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsDate(true);
    setCustomMenu(false);
    setAnchorEl(event.currentTarget);
    setDimensions(dateDimensions);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleView = (id: string) => {
    navigation("/notification/view/" + id);
  };
  const selectPlateform = (platformNames: any) => {
    setSelectedPlatefrom(
      typeof platformNames === "string"
        ? platformNames.split(",")
        : platformNames
    );
    if (platformNames.length === 0) {
      setPlatefroms((prevList: any) =>
        prevList.map((platform: any) => ({ ...platform, value: false }))
      );
    } else {
      setPlatefroms((prevList: any) =>
        prevList.map((platform: any) => {
          if (platformNames?.includes(platform.name)) {
            return { ...platform, value: true };
          } else {
            return { ...platform, value: false };
          }
        })
      );
    }
  };

  const selectMenu = (menu: any, index: number) => {
    if (!isDate) {
      setFilter(menu.value);
    } else if (menu?.key === "custom") {
      const dimensions = { width: "800px", height: "700px" };
      setDimensions(dimensions);
      setCustomMenu(true);
    } else {
      let filterDateType = filterData[index]["key"];

      let fromDate = new Date();
      let toDate = new Date();
      let from = "";
      let to = "";

      // Today
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[0]["key"]) {
        from = moment(fromDate).format("YYYY-MM-DD");
        to = moment(fromDate).format("YYYY-MM-DD");
        setFromDate(from);
        setToDate(to);

        setDateType(GLOBAL_DATE_FILTER_DATA[0]["name"]);
      }
      // yesterday
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[1]["key"]) {
        from = moment(fromDate).subtract(1, "days").format("YYYY-MM-DD");
        to = moment(toDate).subtract(1, "days").format("YYYY-MM-DD");
        setDateType(GLOBAL_DATE_FILTER_DATA[1]["name"]);
        setFromDate(from);
        setToDate(to);
      }

      // last week
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[2]["key"]) {
        from = moment(fromDate).subtract(7, "days").format("YYYY-MM-DD");
        to = moment(toDate).format("YYYY-MM-DD");
        setDateType(GLOBAL_DATE_FILTER_DATA[2]["name"]);
        setFromDate(from);
        setToDate(to);
      }
      // last month
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[3]["key"]) {
        from = moment(fromDate)
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");

        to = moment(toDate)
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");

        setFromDate(from);
        setToDate(to);
        setDateType(GLOBAL_DATE_FILTER_DATA[3]["name"]);
      }

      // last year
      if (filterDateType === GLOBAL_DATE_FILTER_DATA[4]["key"]) {
        let date = new Date();
        // first day of year
        from = moment(new Date(date.getFullYear() - 1, 0, 1)).format(
          "YYYY-MM-DD"
        );

        // last day of year
        to = moment(new Date(date.getFullYear() - 1, 11, 31)).format(
          "YYYY-MM-DD"
        );
        // from = new Date()
        setDateType(GLOBAL_DATE_FILTER_DATA[4]["name"]);
        setFromDate(from);
        setToDate(to);
      }
    }
  };

  const onReset = () => {
    setFilter("all");
    setPlatefroms(plateformList);
    setSelectedPlatefrom([]);

    fetchNotification(true);
  };

  const onSubmit = () => {
    fetchNotification();
  };

  const onCreate = () => {
    navigation(ADD_NOTIFICATION.fullUrl);
  };

  const onApplyFilter = () => {};

  const onCancel = () => {
    setAnchorEl(null);
  };

  const setCalenderValue = (from: any, to: any) => {
    let fromDate = moment(new Date(from)).format("YYYY-MM-DD");
    let toDate = moment(new Date(to)).format("YYYY-MM-DD");
    setFromDate(fromDate);
    setToDate(toDate);

    let fromDateDisplay = moment(new Date(from)).format("ll");
    let toDateDisplay = moment(new Date(to)).format("ll");

    setDateType(fromDateDisplay + "-" + toDateDisplay);
  };

  const openDeleteDialog = () => {
    (deleteRef.current as any).openDialog();
  };

  const deleteNotification = (confirm: boolean) => {
    if (confirm && selectedNotification?._id) {
      dispatch(
        deleteUserNotification(selectedNotification?._id, "", onRefresh)
      );
    }
  };

  const sendNotification = (notification: any) => {
    let sendPayload = {
      id: notification?._id,
    };
    dispatch(sendUserNotification(sendPayload, onRefresh));
  };

  const actionClick = (action: any, notification: any) => {
    setSelectedNotification(notification);
    if (action === "send") {
      sendNotification(notification);
    } else if (action === "delete") {
      openDeleteDialog();
    } else if (action === "view") {
      navigation("/notification/view/" + notification?._id);
    }
  };
  return (
    //     <Box>
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <Typography sx={styles.heading}>Notification Management</Typography>
    //       <div
    //         style={{
    //           marginLeft: "auto",
    //           display: "flex",
    //           gap: "20px",
    //           height: "55px",
    //           cursor: "pointer",
    //         }}
    //       >
    //         <Box
    //           sx={styles.search}
    //           onClick={(event: any) => {
    //             onDate(event);
    //           }}
    //         >
    //           <Box
    //             component="input"
    //             placeholder={selectedDateType}
    //             readOnly
    //             sx={styles.input}
    //           />
    //           <DateRangeIcon sx={styles.icon} />
    //         </Box>

    //         <ButtonControl
    //   label="Create Notification"
    //   onClick={onCreate}
    //   color={"info"}
    //   variant="contained"
    //   sx={{
    //     bgcolor: '#1ca2b7',
    //     textTransform: 'none',
    //     '&:hover': {
    //       bgcolor: '#1ca2b7'
    //     }
    //   }}
    // />
    //       </div>
    //     </div>

    //     <Box sx={styles.innerContainer}>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "space-between",
    //           marginBottom: 2,
    //         }}
    //       >
    //         <div
    //           style={{
    //             marginTop: "35px",
    //             display: "flex",
    //             alignItems: "center",
    //             marginLeft: "20px",
    //             fontSize: "13px",
    //           }}
    //         >
    //           <InfoIcon style={{ marginRight: "8px" }} />
    //           The 7-day data will be displayed by default. To view more data, use
    //           the date filter.
    //         </div>

    //         <Box sx={styles.searchAndFilter}>
    //           <Box sx={styles.search}>
    //             <Box
    //               component="input"
    //               placeholder="Search by Title, Notification"
    //               onChange={(evt: ChangeEvent<HTMLInputElement>) => {
    //                 setSearch(evt.target.value);
    //               }}
    //               sx={styles.input}
    //             />
    //             <SearchIcon sx={styles.icon} />
    //           </Box>

    //           <Box sx={styles.buttonStyle}>
    //             <Box sx={styles.dot} />
    //             <IconButton
    //               disableRipple
    //               id="icon-button"
    //               onClick={handleClickFilter}
    //               aria-describedby={"simple-popover"}
    //             >
    //               <TuneIcon sx={styles.iconColor} />
    //             </IconButton>
    //           </Box>
    //         </Box>
    //       </Box>
    //       <Menus
    //         selectPlateform={selectPlateform}
    //         platefroms={platefroms}
    //         selectedPlatefrom={selectedPlatefrom}
    //         value={selectedFilter}
    //         onClose={() => {}}
    //         anchorOrigin={() => {}}
    //         id={"simple-popover"}
    //         key={anchorEl ?? ""}
    //         anchorEl={anchorEl}
    //         handleClose={handleClose}
    //         selectMenu={selectMenu}
    //         onReset={onReset}
    //                   onSubmit={onSubmit}
    //         onApplyFilter={onApplyFilter}
    //         onCancel={onCancel}
    //         setCalenderValue={setCalenderValue}
    //         label={!isDate ? "Recepient" : ""}
    //         showButton={!isDate ? true : false}
    //         isDropDown={false}
    //         showList={isDate ? true : false}
    //         customMenu={isCustomMenu}
    //         dimensions={dimensions}
    //         selectedDateType={selectedDateType}
    //         menuWidth="440px"
    //         menus={isDate ? filterData : []}
    //         showPlateform={!isDate ? true : false}
    //       />

    //       {notifications && (
    //         <TableComponent
    //           dataSource={notifications}
    //           tableColumns={TABLES.NOTIFICATION}
    //           handleView={handleView}
    //           actionClick={actionClick}
    //           onRowsChange={(value: number) => {
    //             setRowsPerPage(value);
    //           }}
    //           onPageChange={(value: number) => {
    //             setPage(value);
    //           }}
    //           total={count}
    //           showEdit={false}
    //           showStatus={false}
    //           showDelete={true}
    //           showSend={true}
    //           showView={true}
    //         />
    //       )}
    //       <ConfirmDialog
    //         title={"Delete Notification"}
    //         message={"Are you sure you want to delete notification?"}
    //         cancelButtonText={"Cancel"}
    //         confirmButtonText={"Confirm"}
    //         logoutUser={deleteNotification}
    //         ref={deleteRef}
    //       />
    //     </Box>
    //   </Box>
    <div className="table-wrapper">
      <div className="heading-wrapper">
        <BreadCrumb
          showMultipleLink={false}
          mainLink={NOTIFICATION.fullUrl}
          mainLabel={"Notification Management"}
        />
        <div className="table-heading-btn">
          <Box
            sx={styles.search}
            onClick={(event: any) => {
              onDate(event);
            }}
          >
            <Box
              component="input"
              placeholder={selectedDateType}
              readOnly
              sx={styles.input}
            />
            <DateRangeIcon sx={styles.icon} />
          </Box>

          {
            //@ts-ignore
            permissionData["NOTIFICATION"]?.includes("ADD") ? (
              <button className="action-click" onClick={onCreate}>
                Create
              </button>
            ) : (
              ""
            )
          }
        </div>
      </div>
      <div className="table-content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // marginLeft: "20px",
            // fontSize: "13px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              // marginTop: "35px",
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              fontSize: "13px",
            }}
          >
            <InfoIcon style={{ marginRight: "8px" }} />
            The 7-day data will be displayed by default. To view more data, use
            the date filter.
          </div>
          <Box sx={styles.searchAndFilter}>
            <SearchBar
              setSearch={setSearch}
              searchText={searchText}
              placeholderText="Search by Title, Notification"
            />

            <Box sx={styles.buttonStyle}>
              {selectedFilter !== "all" ? <Box sx={styles.dot} /> : null}
              <IconButton
                disableRipple
                id="icon-button"
                onClick={handleClickFilter}
                aria-describedby={"simple-popover"}
              >
                <TuneIcon sx={styles.iconColor} />
              </IconButton>
              <Menus
                selectPlateform={selectPlateform}
                platefroms={platefroms}
                selectedPlatefrom={selectedPlatefrom}
                value={selectedFilter}
                onClose={() => {}}
                anchorOrigin={() => {}}
                id={"simple-popover"}
                key={anchorEl ?? ""}
                anchorEl={anchorEl}
                handleClose={handleClose}
                selectMenu={selectMenu}
                onReset={onReset}
                onSubmit={onSubmit}
                onApplyFilter={onApplyFilter}
                onCancel={onCancel}
                setCalenderValue={setCalenderValue}
                label={!isDate ? "Recepient" : ""}
                showButton={!isDate ? true : false}
                isDropDown={false}
                showList={isDate ? true : false}
                customMenu={isCustomMenu}
                dimensions={dimensions}
                selectedDateType={selectedDateType}
                menuWidth="440px"
                menus={isDate ? filterData : []}
                showPlateform={!isDate ? true : false}
              />
            </Box>
          </Box>
        </div>

        {notifications && (
          <TableComponent
            dataSource={notifications}
            tableColumns={TABLES.NOTIFICATION}
            handleView={handleView}
            actionClick={actionClick}
            onRowsChange={(value: number) => {
              setRowsPerPage(value);
            }}
            onPageChange={(value: number) => {
              setPage(value);
            }}
            total={count}
            showEdit={false}
            showStatus={false}
            showDelete={
              //@ts-ignore
              permissionData["NOTIFICATION"]?.includes("DELETE")
            }
            showSend={true}
            showView={
              //@ts-ignore
              permissionData["NOTIFICATION"]?.includes("VIEW")
            }
          />
        )}
        <ConfirmDialog
          title={"Delete Notification"}
          message={"Are you sure you want to delete notification?"}
          cancelButtonText={"Cancel"}
          confirmButtonText={"Confirm"}
          logoutUser={deleteNotification}
          ref={deleteRef}
        />
      </div>
    </div>
  );
}
