import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Checkbox,
  TextField,
  Divider,
  Grid,
  InputLabel,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Formik, Form } from "formik";
import roleHelper from "./helper";
import Schema from "../../../common/constants/schema";
import { COLORS } from "../../../common/constants/colors";
import ButtonControl from "../../../components/form-controls/button/basic-button";
import { useNavigate, useParams } from "react-router-dom";
import { ROLES } from "../../../constants/routes/routes-path";
import "./role.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  addRoles,
  getRoleDetail,
  getSearchPermssion,
  updateRole,
} from "../services/action";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { setLoading } from "../../../redux/global.slice";
import { ArrowForwardIosIcon } from "../../../constants/images";
import BreadCrumb from "../../../components/form-controls/breadCrumb";
import FormButton from "../../../components/form-controls/button/form-button";
import InputTextField from "../../../components/form-controls/text";
import { VALIDATION_CRITERIA } from "../../../constants/validation-criteria";
export default function AddRole({ view }: any) {
  const { detail } = useAppSelector((state) => state.roleDetail);
  let { id } = useParams();
  const { initialValue, permissionActions, dispatch } = roleHelper(
    id ? true : false,
    detail
  );
  const navigation = useNavigate();
  const { data } = useAppSelector((state) => state.roleSearch);
  const [permissions, setPermissions] = useState<Array<any>>([]);
  const [acessAll, setAcessAll] = useState<Array<any>>([]);
  const [FormInitialValue, setFormInitialValue] = useState<any>(initialValue);

  const getPermission = () => {
    const searchlist: any[] = data.map((item: any) => {
      const actions: any[] = item.access[0].actions;
      let accessValues: any[] = permissionActions;
      accessValues = accessValues.map((access, index) => {
        return {
          ...access,
          show: actions
            .map((action) => action.toLowerCase())
            .includes(access.name),
        };
      });
      return {
        ...item,
        accessValues: accessValues,
      };
    });
    setPermissions(searchlist);
    setAcessAll(permissionActions);
  };

  const updateInitialValue = () => {
    setFormInitialValue((prev: any) => ({
      ...prev,
      title: detail?.title || prev.title,
      description: detail?.description || prev.description,
    }));
  };

  const updateActionValue = useCallback(() => {
    if (detail && detail?.permissions?.length) {
      setPermissions((prevPermissions) => {
        if (!detail || !detail.permissions) {
          return prevPermissions; // If detail or permissions are undefined, return previous permissions
        }
        return prevPermissions.map((initialAction) => {
          const detailAction = detail.permissions.find(
            (action: any) => action.title === initialAction.title
          );
          if (!detailAction) {
            return initialAction; // If no matching action found in detail, return initial action
          }
          return {
            ...initialAction,
            accessValues: initialAction.accessValues.map((access: any) => ({
              ...access,
              value: detailAction.actions
                .map((action: string) => action.toLowerCase())
                .includes(access.name.toLowerCase()),
            })),
          };
        });
      });
    }

    checkInitialSelectAll();
  }, [permissions, detail]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkInitialSelectAll = useCallback(() => {
    const actions: any = permissions?.[0]?.["accessValues"];
    if (actions?.length) {
      actions.forEach((action: any) => {
        let accessKey = action?.name;
        const allSelected = permissions.every((permission) =>
          permission.accessValues.some(
            (access: any) =>
              access.name === accessKey && (access.show ? access.value : true)
          )
        );
        setAcessAll((prevAcess: any) => {
          return prevAcess.map((access: any) => {
            if (access.name === accessKey) {
              return {
                ...access,
                selectAll: allSelected,
              };
            } else {
              return access;
            }
          });
        });
      });
    }
    dispatch(setLoading(false));
  }, [permissions]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRoleData = () => {
    dispatch(getRoleDetail(id));
  };

  useEffect(() => {
    if (data?.length) {
      getPermission();
    }
    if (detail && data && id) {
      dispatch(setLoading(true));
      updateInitialValue();
      updateActionValue();
    }
  }, [data, detail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getSearchPermssion());
    if (id) {
      getRoleData();
    }
    console.log("view111", view);
  }, [view]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, accessKey: String) => {
      if (permissions.length) {
        const anyOtherSelectAllTrueExists = acessAll.some(
          (access: any) => access.selectAll && access.name !== "view"
        );
        const selectAllPermission = permissions.map((permission) => {
          let accessValues = permission.accessValues;
          // console.log("accessValues", accessValues);
          const anyOtherActionTrue = accessValues.some(
            (access: any) => access.value && access.name !== "view"
          );
          accessValues = accessValues.map((access: any) => {
            //  console.log("access", access);
            if (access.name === "view" && accessKey !== "view" && access.show) {
              return {
                ...access,
                value: true,
                selectAll: true,
              };
            } else if (
              access.name === "view" &&
              accessKey === "view" &&
              (anyOtherSelectAllTrueExists || anyOtherActionTrue)
            ) {
              return {
                ...access,
                value: true,
                selectAll: true,
              };
            } else if (access.name === accessKey && access.show) {
              return {
                ...access,
                value: event.target.checked,
                selectAll: event.target.checked,
              };
            } else {
              return access;
            }
          });

          return {
            ...permission,
            accessValues: accessValues,
          };
        });
        setAcessAll((prevAcess: any) => {
          return prevAcess.map((access: any) => {
            if (access.name === "view" && accessKey !== "view") {
              return {
                ...access,
                value: true,
                selectAll: true,
              };
            } else if (
              access.name === "view" &&
              accessKey === "view" &&
              anyOtherSelectAllTrueExists
            ) {
              return {
                ...access,
                value: true,
                selectAll: true,
              };
            } else if (access.name === accessKey) {
              return {
                ...access,
                value: event.target.checked,
                selectAll: event.target.checked,
              };
            } else {
              return access;
            }
          });
        });
        setPermissions(selectAllPermission);
      }
    },
    [permissions, acessAll]
  );

  const handleRowCheckboxClick = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      permissionIndex: number,
      accessIndex: number,
      accessKey: String
    ) => {
      if (permissions.length) {
        const newPermissions = [...permissions]; //
        let accessValues = newPermissions[permissionIndex].accessValues;
        accessValues[accessIndex] = {
          ...accessValues[accessIndex],
          value: event.target.checked,
        };
        let anyTrue = accessValues.some((item: any) => item.value && item.show);
        if (anyTrue) {
          accessValues.forEach((item: any) => {
            if (item.name === "view") {
              item.value = true;
            }
          });
        }
        const checkAllSelected = newPermissions.every((permission) =>
          permission.accessValues.some(
            (access: any) =>
              access.name === accessKey && (access.show ? access.value : true)
          )
        );
        const CheckViewAllSelected = newPermissions.every((permission) =>
          permission.accessValues.some(
            (access: any) =>
              access.name === "view" && (access.show ? access.value : true)
          )
        );
        setPermissions(newPermissions);
        setAcessAll((prevAcess: any) => {
          return prevAcess.map((access: any) => {
            if (access.name === accessKey) {
              return {
                ...access,
                selectAll: checkAllSelected,
              };
            } else if (access.name === "view") {
              return {
                ...access,
                selectAll: CheckViewAllSelected,
              };
            } else {
              return access;
            }
          });
        });
      }
    },
    [permissions]
  );

  const onBack = () => {
    navigation(ROLES.fullUrl);
  };
  const onSubmit = async (values: any) => {
    let ActionPermission = [...permissions];

    let permissionData = ActionPermission.map((permission) => {
      let permissionList = { ...permission };
      permissionList.actions = [];
      permissionList.accessValues.forEach((access: any) => {
        if (access.value && access.show) {
          permissionList.actions.push(access.name.toUpperCase());
        }
      });
      if (permissionList.access) {
        delete permissionList.access;
      }
      if (permissionList.accessValues) {
        delete permissionList.accessValues;
      }
      return permissionList;
    });

    let rolePayload: any = {
      title: values.title,
      description: values.description,
      permissions: permissionData,
    };
    if (id) {
      dispatch(updateRole(id, rolePayload, onBack));
    } else {
      dispatch(addRoles(rolePayload, onBack));
    }

    //  handleOnSubmit(signupPayload);
  };

  const getName = (name: string) => {
    if (name.length) {
      name =
        name === "status"
          ? "Activate/Deactivate"
          : name.substring(0, 1).toUpperCase() + name.substring(1);
    }
    return name;
  };

  const getTitle = () => {
    if (id && !view) {
      return "Edit Role";
    } else if (view) {
      return "View Role";
    } else {
      return "Add Role";
    }
  };

  const handleEditRole = () => {
    navigation(`/roles/edit-roles/${id}`);
  };
  return (
    <Box className="role-wrapper" sx={{ width: "100%" }}>
      <div className="detail-container">
        <BreadCrumb
          showMultipleLink={true}
          mainLink={ROLES.fullUrl}
          mainLabel={"Roles Management"}
          secondaryLabel={getTitle()}
        />

        {view && (
          <button type="button" onClick={handleEditRole} className="action-btn">
            Edit
          </button>
        )}
      </div>
      <Formik
        validateOnMount={true}
        enableReinitialize={true}
        initialValues={FormInitialValue}
        onSubmit={onSubmit}
        validationSchema={Schema.RoleSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleBlur,
          setFieldTouched,
          isValid,
          dirty,
        }) => (
          <Form>
            <Box
              sx={{
                boxShadow: "none",
                padding: 5,
                borderRadius: 2,
                background: "#ffffff",
              }}
            >
              <Typography variant="h6">Access Details</Typography>
              <Divider className="role-divider" />
              <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                {id && detail?.id ? (
                  <Grid xs={12} sm={5} item>
                    {/* <TextField
                      className="input-field"
                      name="id"
                      label="Role ID"
                      value={detail?.id}
                      disabled={true}
                      variant="outlined"
                      type="text"
                      fullWidth
                      error={touched.title && !!errors.title}
                    /> */}
                    <InputTextField
                      name="id"
                      label="Role ID"
                      variant="outlined"
                      value={detail?.id}
                      type="text"
                      fullWidth
                      disabled={true}
                    />
                  </Grid>
                ) : null}

                <Grid xs={12} sm={5} item>
                  <InputTextField
                    name="title"
                    label=" Role Name"
                    variant="outlined"
                    type="text"
                    fullWidth
                    disabled={view}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12} sm={5} item>
                  <InputTextField
                    name="description"
                    label="Description"
                    required={false}
                    variant="outlined"
                    type="text"
                    validLegth={false}
                    length={VALIDATION_CRITERIA.RoleDiscription}
                    fullWidth
                    disabled={view}
                  />
                  <Typography style={{ textAlign: "right" }}>
                    {values.description.length}/
                    {VALIDATION_CRITERIA.RoleDiscription}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                boxShadow: "none",
                padding: 5,
                borderRadius: 2,
                background: "#ffffff",
                marginTop: "20px",
              }}
            >
              <Typography variant="h6">Access Details</Typography>
              <Divider className="role-divider" />
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="role-dis">
                        <InfoIcon />
                        <p>
                          At least one permission must be given while creating a
                          role.
                        </p>
                      </TableCell>
                      {acessAll.length
                        ? acessAll.map((access: any, indexHeader: number) => {
                            return (
                              <TableCell key={`header-${indexHeader}`}>
                                <Checkbox
                                  key={`header-checkbox-${indexHeader}`}
                                  checked={access.selectAll}
                                  disabled={!!view}
                                  onChange={(e) =>
                                    handleSelectAllClick(e, access.name)
                                  }
                                  inputProps={{
                                    "aria-label": "Select all rows",
                                  }}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#1ca2b7", // color when checked
                                    },
                                  }}
                                />
                                <span className="span-button">Select All</span>
                              </TableCell>
                            );
                          })
                        : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissions.length
                      ? permissions.map((permission, permissionIndex) => {
                          const { title, accessValues } = permission;
                          return (
                            <TableRow key={`permission-${permissionIndex}`}>
                              <TableCell
                                key={`permissionCell-${permissionIndex}`}
                              >
                                {title}
                              </TableCell>
                              {accessValues.length
                                ? accessValues.map(
                                    (access: any, accessIndex: number) => {
                                      if (access.show) {
                                        return (
                                          <TableCell
                                            key={`access-${accessIndex}`}
                                          >
                                            <Checkbox
                                              key={`accessCheckBox-${accessIndex}`}
                                              checked={access.value}
                                              disabled={!!view}
                                              onChange={(event) =>
                                                handleRowCheckboxClick(
                                                  event,
                                                  permissionIndex,
                                                  accessIndex,
                                                  access.name
                                                )
                                              }
                                              inputProps={{
                                                "aria-label": `Select row ${access.value}`,
                                              }}
                                              sx={{
                                                "&.Mui-checked": {
                                                  color: "#1ca2b7", // color when checked
                                                },
                                              }}
                                            />
                                            {getName(access.name)}
                                          </TableCell>
                                        );
                                      } else {
                                        return (
                                          <>
                                            <TableCell
                                              key={`access-${accessIndex}`}
                                            >
                                              {" "}
                                            </TableCell>
                                          </>
                                        );
                                      }
                                    }
                                  )
                                : null}
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
              {!view ? (
                <Grid xs={12} sm={12} item style={{ marginTop: "20px" }}>
                  <FormButton
                    cancelBtnClick={onBack}
                    actBtnDisabled={!isValid}
                  />
                </Grid>
              ) : null}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
