import { COLORS } from "../../../common/constants/colors";
import { FontFamily } from "../../../common/utils";

export const styles = {
  heading: {
    font: "normal normal 700 20px/25px SofiaPro",
    padding: 1,
    marginLeft: 5,
  },
  innerContainer: {
    borderRadius: "6px",
    height: "100%",
    marginTop: "15px",
    minHeight: "65vh",
    backgroundColor: COLORS.white,
    boxShadow: "0px 1px 0px #dadbe4",
  },
  downloadContainer: {
    background: "#3795D0",
    border: "1px solid #F2F2F4",
    borderRadius: "4px",
    textTransform: "capitalize",
    padding: "14px",
    margin: "0 10px",
    cursor: "pointer",
    color: "#7A8198",
    "&:hover": { background: "#3795D0" },
  },
  dot: {
    height: "16px",
    width: "16px",
    backgroundColor: "#1ca2b7",
    borderRadius: "50%",
    marginBottom: "45px",
    marginLeft: "45px",
    position: "absolute",
  },
  searchAndFilter: {
    display: "flex",
    justifyContent: "flex-end",
    height: "40px",
    padding: "0px 20px",
    alignItems: "center",
  },
  search: {
    display: "flex",
    alignItems: "center",
    padding: "9px 10px",
    background: COLORS.white,
    borderRadius: "8px",
    width: "330px",
    position: "relative",
    justifyContent: "space-between",
    border: "1px solid #e0e0e0",
  },
  searchPadding: {
    // padding: "25px 10px",
  },
  input: {
    padding: "0",
    border: "none",
    outline: "none",
    background: COLORS.white,
    font: FontFamily(500, 14, 34),
    color: COLORS.black,
    boxSizing: "border-box",
    transition: "all 0.4s ease-out",
    width: "100%",
  },
  icon: { width: "24px", height: "24px", color: "#606060" },
  iconStyle: { marginRight: "5px" },
  buttonStyle: {
    marginLeft: "10px",
    height: "50px",
    width: "50px",
    color: COLORS.primaryBlue,
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  iconColor: { color: "#1ca2b7" },
};
