import Card from "@mui/material/Card";
import "./expiry-link.scss";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Link } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../../constants/routes/routes-path";

export default function ExpiryPasswordResetLink() {
  return (
    <>
      <div className="expiry-link">
        <Card variant="outlined" sx={{ maxWidth: "60vw", textAlign: "center" }}>
          <CardContent>
            <NotificationsActiveIcon
              style={{ fontSize: "4rem", color: "red", marginBottom: "1rem" }}
            />
            <Typography
              variant="h5"
              color="textSecondary"
              mb={3}
              style={{ textAlign: "center" }}
            >
              Your reset passowrd link has been expired try it using{" "}
              <Link to={FORGOT_PASSWORD.fullUrl}>Forgot Password</Link> or
              Contact Admin Contact us at {" "} <a href="mailTo:admin@yopmail.com">admin@yopmail.com</a>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
