import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/useAppSelector";
import { SIDE_BAR } from "../common/sidebar/helper";

export default function PermissionNotFound() {
  const styles = {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    backgroundColor: "#ffffff",
  };
  const navigate = useNavigate();

  const { permissionData } = useAppSelector((state) => state.userPermissionInfo);

  //@ts-ignore
    const filteredKeys = Object.keys(permissionData).filter((key:any) => permissionData[key]?.length > 0);
  
    //@ts-ignore
   const result = SIDE_BAR.MENU_LIST.filter(item => filteredKeys.includes(item.module));

  const navigateTo = () => {
    if(result!==undefined){
      navigate(result[0].path);
    } else {
      navigate("/");
    }

  };
  return (
    <>
      <div className="page-not-found" style={styles}>
        <div className="col-sm-4 offset-sm-1">
          <img className="gif_icon" src="../../images/pageerror.gif" alt="" />
        </div>
        <div className="col-sm-6">
          <Typography variant="h1" color="textSecondary" mb={3}>
            401
          </Typography>
          <Typography variant="h3" color="textSecondary" mb={3}>
            Not Authorized 
          </Typography>
          <Typography variant="h5" color="textSecondary" mb={3}>
            Oops, We can't seem you dont have permission to view this page....
          </Typography>
          <Button
            type="button"
            onClick={navigateTo}
            color="primary"
            variant="contained"
          >
            Back to Home
          </Button>
        </div>
      </div>
    </>
  );
}
